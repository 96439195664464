import React, {useCallback, useEffect, useMemo, useState} from 'react';
import PropTypes from 'prop-types';
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Alert from '@mui/material/Alert';
import assign from 'lodash/assign';
import Box from '@mui/material/Box';
import ButtonFHG from '../fhg/components/ButtonFHG';
import {DatePicker} from '@mui/x-date-pickers';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Fade from '@mui/material/Fade';
import Form from '../fhg/components/edit/Form';
import {grey} from '@mui/material/colors';
import {lighten, TextField} from '@mui/material';
import Loading from '../fhg/components/Loading';
import {makeStyles} from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';
import moment from 'moment/moment';
import PhoneNumberFieldFHG from '../fhg/components/edit/PhoneNumberFieldFHG';
import ProgressButton from '../fhg//components/ProgressButton';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextFieldFHG from './TextFieldFHG';
import TypographyFHG from '../fhg/components/Typography';
import useMutationFHG from '../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import usePromptFHG from '../fhg/hooks/usePromptFHG';
import {
   DATA_TYPES_QUERY,
   getUserCacheQueries,
   USER_ANALYTICS_QUERY,
   USER_BY_ID_QUERY,
   USER_UPDATE
} from '../data/QueriesGL';
import {
   DATA_TYPES,
   DATE_FORMAT_KEYBOARD,
   DATE_FORMAT_OUPUT,
   DATETIME_FORMAT_KEYBOARD,
   FLYOUT_EL_WIDTH,
   SUB_FREE,
   SUB_PREM
} from '../Constants';

const useStyles = makeStyles({name: 'UserEditStyles'})((theme) => ({
   accordionStyle: {
      fontSize: '0.875rem'
   },
   accordionItemStyle: {
      fontSize: '0.75rem',
      margin: theme.spacing(0, 0, 1, 0)
   },
   accordionItemStyle1: {
      fontSize: '0.75rem',
      margin: theme.spacing(1, 0, 0, 0)
   },
   buttonStyle: {
      backgroundColor: theme.palette.primary.main,
      margin: theme.spacing(1),
      '&:hover': {
         backgroundColor: lighten(theme.palette.primary.main, 0.15),
      },
      width: '120px'
   },
   calStyle: {
      fontSize: '0.75rem',
      margin: theme.spacing(-1.8,0, 0, 0),
      padding: theme.spacing(1, 0.5, 0.5, 0.5)
   },
   elementStyle0: {
      border: 'thin solid lightgray',
      borderRadius: '5px',
      fontSize: '0.875rem',
      margin: theme.spacing(0.75,0, 1.25, 2),
      paddingLeft: '8px',
      width: FLYOUT_EL_WIDTH
   },
   elementStyle1: {
      fontSize: '0.875rem',
      margin: theme.spacing(0.75,0, 1.25, 2),
      paddingLeft: '8px',
      width: FLYOUT_EL_WIDTH
   },
   elementStyle: {
      fontSize: '0.75rem'
   },
   labelStyle0: {
      fontWeight: "bold",
      fontSize: '0.75rem',
      padding: theme.spacing(1, 0, 0, 0),
      width: theme.spacing(13.5)
   },
   labelStyle: {
      backgroundColor: "white",
      color: "gray",
      fontSize: '0.75rem',
      margin: theme.spacing(0,0, -0.25, 1.75),
      padding: theme.spacing(0, 0.5, 0, 0.5),
      width: theme.spacing(13.5),
      zIndex: "20",
   },
   labelStyle2: {
      backgroundColor: "white",
      color: "gray",
      fontSize: '0.75rem',
      margin: theme.spacing(0,0, -0.25, 1.75),
      padding: theme.spacing(0, 0.5, 0, 0.5),
      width: theme.spacing(10),
      zIndex: "20",
   },
   infoRootStyle: {
      // maxHeight: `calc(100% - ${theme.spacing(5)}px)`,
      '& > *': {
         marginRight: theme.spacing(1),
      },
      overflow: 'auto',
      marginBottom: theme.spacing(1),
   },
   infoInnerStyle: {
      padding: theme.spacing(0, 2),
   },
   formStyle: {
      display: 'flex',
      flexDirection: 'column',
      overflowY: 'scroll',
      width: '100%',
      height: '100%',
   },
   fadeIn: {
      opacity: 0,
      marginTop: 'auto',
      marginBottom: 'auto',
   },
   deleteButtonStyle: {
      '&:not(.Mui-disabled)': {
         color: grey[600]
      },
      '&:hover': {
         color: theme.palette.error.main,
      },
   },
   leftPad: {
      paddingLeft: theme.spacing(2),
   },
   readOnlyFullStyle: {
      border: 'darkgray thin solid',
      borderRadius: '4px',
      fontSize: '0.875rem',
      height: theme.spacing(4.5),
      margin: theme.spacing(-1.5, 0, 1.5, -1),
      padding: theme.spacing(1, 0, 0, 1),
      width: theme.spacing(45)
   },
   selectStyle: {
      fontSize: '0.75rem',
      margin: theme.spacing(1, 0, 1, 0),
      width: FLYOUT_EL_WIDTH + 8
   }
}));

export default function UserEdit(props) {
   const {classes} = useStyles();
   const userId = props?.id;
   const [defaultValues, setDefaultValues] = useState({});
   const [dateValue, setDateValue] = useState(null);
   const [editValues, setEditValues] = useState({});
   const [isChanged, setIsChanged] = useState(false);
   const [isSaving, setIsSaving] = useState(false);
   const [messageText, setMessageText] = useState('');
   const [openAlert, setOpenAlert] = useState(false);
   const variables = {filter: {userId: {"eq": userId}}};
   const [territoryData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.TERRITORIES}}}}, 'data_type.type');
   const [userData] = useQueryFHG(USER_BY_ID_QUERY, {variables: {id: userId}});
   const [analyticsData] = useQueryFHG(USER_ANALYTICS_QUERY, {variables});
   const [userUpdate, {data, error}] = useMutationFHG(USER_UPDATE);

   const user = useMemo(() => userData?.profile || {}, [userData]);
   const analytics = useMemo(() => analyticsData?.analytics || {}, [analyticsData]);
   const territories = useMemo(() => {
      let result = [];
      if (territoryData && territoryData.data && territoryData.data.items) {
         let data = territoryData.data.items;
         data?.forEach(x => {
            if (x.isValid) {
               result.push({label: x.name, value: x.id});
            }
         });
      }
      return result;
   }, [territoryData]);

   useMemo(() => {
      if (userData && userData.profile && Object.keys(userData.profile).length > 0) {
         let date = userData.profile.subscriptionStart ? moment.utc(userData.profile.subscriptionStart).format(DATE_FORMAT_KEYBOARD) : null;
         setDateValue(date);
         setDefaultValues(assign({}, userData.profile, {subscriptionStart: date}));
      }
   }, [userData, setDefaultValues]);

   const handleClose = useCallback(() => {
      props?.onClose();
   }, [props]);

   useEffect(() => {
      if (data && !error) {
         handleClose();
      }
   }, [data, error, handleClose]);

   useEffect(() => {
      if (error) {
         setMessageText(error.message);
         setOpenAlert(true);
      }
   }, [error]);

   const handleChange = useCallback((e) => {
      const { target: { name, value } } = e;
      setEditValues(editValues => ({...editValues, [name]: value}));
      setIsChanged(true);
   }, [setEditValues, setIsChanged]);

   const handleCountryChange = useCallback((event, id) => {
      let cId = id?.props.value
      setEditValues(editValues => ({...editValues, countryId: cId}));
      setIsChanged(true);
   }, [setIsChanged]);

   const handleDateChange = useCallback((date) => {
      setDateValue(date);
      setIsChanged(true);
   }, [setDateValue, setIsChanged]);

   const handleSelectChange = useCallback((event, value) => {
      setEditValues(editValues => ({...editValues, subscriptionType: value?.props?.value}));
      setIsChanged(true);
   }, [setEditValues, setIsChanged]);

   usePromptFHG({when: isChanged});

   const handleSubmit = async () => {
      if (isChanged) {
         try {
            setIsSaving(true);
            let currentItem = {
               ...defaultValues,
               ...editValues
            };

            if (currentItem['subscriptionType'] === SUB_PREM && dateValue === null) {
               setMessageText('Subscription start date is required for premium users');
               setOpenAlert(true);
               return;
            }

            delete currentItem['countryName'];
            delete currentItem['createdDate'];
            delete currentItem['profilePictureUrl'];
            delete currentItem['__typename'];
            currentItem['id'] = userId;
            if (currentItem['subscriptionType'] === SUB_PREM) {
               currentItem['subscriptionStart'] = moment.utc(dateValue).format(DATE_FORMAT_OUPUT);
            } else {
               currentItem['subscriptionStart'] = null;
            }
            await userUpdate({
               variables: {updateUserProfileInput: {...currentItem}},
               refetchQueries: getUserCacheQueries()
            });
            setIsChanged(false);
         } catch (e) {
            //Intentionally left blank
         } finally {
            setIsSaving(false);
         }
      } else {
         handleClose();
      }
   };

   const handleAlertClose = useCallback(() => {
      setOpenAlert(false);
   }, [setOpenAlert]);

   const getAlert = useCallback(() => {
      let result = undefined;
      if (openAlert) {
         result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
      }
      return result;
   }, [messageText, openAlert, handleAlertClose]);

   const getLocalDatetime = useCallback((date) => {
      var offset = moment().utcOffset();
      return moment.utc(date).utcOffset(offset).format(DATETIME_FORMAT_KEYBOARD);
   }, []);
   const isReady = !!user && !!territories;

   return (
      <Fade in={isReady}>
         <Form onSubmit={handleSubmit}>
            <Loading isLoading={!isReady} />
            <Stack
               name={'User Edit Root'}
               className={classes.formStyle}
               direction={'column'}
               spacing={2}
            >
               <TypographyFHG id="user.edit.title"  className={classes.leftPad} variant="h6" />
               {getAlert()}
               <Box
                  sx={{
                     '&': {overflow: 'auto', mb: 1, px: 2},
                     '& > :not(style)': {my: 1},
                  }}
               >
                  <Stack direction="column" display="flex" style={{margin: '0 0 0 8px'}}>
                     <TypographyFHG className={classes.labelStyle2} id="users.id.label" />
                     <TypographyFHG className={classes.readOnlyFullStyle}>{defaultValues?.id}</TypographyFHG>
                  </Stack>
                  <TextFieldFHG
                    key={'username'+defaultValues.id}
                    name={'username'}
                    required
                    labelKey={'user.username.label'}
                    onChange={handleChange}
                    defaultValue={defaultValues.username}
                    value={editValues?.username}
                    InputProps={{style: {fontSize: '0.75rem'}}}
                    InputLabelProps={{shrink: true}}
                  />
                  <TextFieldFHG
                    key={'email'+defaultValues.id}
                    name={'email'}
                    labelKey={'user.email.label'}
                    onChange={handleChange}
                    defaultValue={defaultValues.email}
                    value={editValues?.email}
                    InputProps={{style: {fontSize: '0.75rem'}}}
                    InputLabelProps={{shrink: true}}
                  />
                  <Stack direction="row" display={'flex'} alignItems={'flex-start'}>
                     <TextFieldFHG
                       key={'givenName'+defaultValues.id}
                       name={'givenName'}
                       labelKey={'user.givenName.label'}
                       onChange={handleChange}
                       defaultValue={defaultValues.givenName}
                       value={editValues?.givenName}
                       style={{width: '170px'}}
                       InputProps={{style: {fontSize: '0.75rem'}}}
                       InputLabelProps={{shrink: true}}
                     />
                     <TextFieldFHG
                       key={'familyName'+defaultValues.id}
                       name={'familyName'}
                       labelKey={'user.familyName.label'}
                       onChange={handleChange}
                       defaultValue={defaultValues.familyName}
                       value={editValues?.familyName}
                       style={{marginLeft: '12px', width: '190px'}}
                       InputProps={{style: {fontSize: '0.75rem'}}}
                       InputLabelProps={{shrink: true}}
                     />
                  </Stack>

                  <Accordion>
                     <AccordionSummary
                       expandIcon={<ExpandMoreIcon />}
                       aria-controls="panel1a-content"
                       id="panel1a-header"
                     >
                        <TypographyFHG id="user.edit.analytics" className={classes.accordionStyle} />
                     </AccordionSummary>
                     <AccordionDetails>
                        <Stack display={"flex"} direction="row" justify="flex-start">
                           <TypographyFHG className={classes.labelStyle0} id="user.deviceType.label" />
                           <TypographyFHG className={analytics?.deviceType ? classes.elementStyle0 : classes.elementStyle1}>{analytics?.deviceType}</TypographyFHG>
                        </Stack>
                        <Stack display={"flex"} direction="row" justify="flex-start">
                           <TypographyFHG className={classes.labelStyle0} id="user.appVersion.label" />
                           <TypographyFHG className={analytics?.appVersion ? classes.elementStyle0 : classes.elementStyle1}>{analytics?.appVersion}</TypographyFHG>
                        </Stack>
                        <Stack display={"flex"} direction="row" justify="flex-start">
                           <TypographyFHG className={classes.labelStyle0} id="user.lastAccessed.label" />
                           <TypographyFHG className={analytics?.lastAccessed ? classes.elementStyle0 : classes.elementStyle1}>{analytics.lastAccessed ? getLocalDatetime(analytics.lastAccessed) : null}</TypographyFHG>
                        </Stack>
                     </AccordionDetails>
                  </Accordion>
                  <TextFieldFHG
                    key={'territoryId'+defaultValues.id}
                    name={'territoryId'}
                    select
                    className={classes.selectStyle}
                    labelKey={'user.country.label'}
                    onChange={handleCountryChange}
                    defaultValue={defaultValues.countryId || ''}
                    value={editValues?.countryId}
                    InputLabelProps={{shrink: true}}
                    inputProps={{style: {fontSize: '0.75rem'}}}
                  >
                     {territories.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                           <span style={{fontSize: '0.75rem'}}>{option.label}</span>
                        </MenuItem>
                     ))}
                  </TextFieldFHG>
                  <TextFieldFHG
                    key={'city'+defaultValues.id}
                    name={'city'}
                    className={classes.accordionItemStyle}
                    labelKey={'user.city.label'}
                    onChange={handleChange}
                    defaultValue={defaultValues.city}
                    value={editValues?.city}
                    InputLabelProps={{shrink: true}}
                    InputProps={{style: {fontSize: '0.75rem'}}}
                  />
                  <Stack direction="row" display={'flex'} alignItems={'flex-start'}>
                     <TextFieldFHG
                       key={'state'+defaultValues.id}
                       name={'state'}
                       className={classes.accordionItemStyle1}
                       labelKey={'user.state.label'}
                       onChange={handleChange}
                       defaultValue={defaultValues.state}
                       value={editValues?.state}
                       style={{width: '205px'}}
                       InputLabelProps={{shrink: true}}
                       InputProps={{style: {fontSize: '0.75rem'}}}
                     />
                     <TextFieldFHG
                       key={'zipcode'+defaultValues.id}
                       name={'zipcode'}
                       className={classes.accordionItemStyle1}
                       labelKey={'user.zipcode.label'}
                       onChange={handleChange}
                       defaultValue={defaultValues.zipcode}
                       value={editValues?.zipcode}
                       style={{marginLeft: '12px', width: '140px'}}
                       InputLabelProps={{shrink: true}}
                       InputProps={{style: {fontSize: '0.75rem'}}}
                     />
                  </Stack>
                  <PhoneNumberFieldFHG
                    name={'phone'}
                    labelKey={'user.phone.label'}
                    onChange={handleChange}
                    defaultValue={defaultValues.phone}
                    value={editValues?.phone}
                    size={"small"}
                    style={{width: '205px'}}
                    InputLabelProps={{shrink: true}}
                  />
                  <Stack direction="column" display="flex" style={{margin: '0 0 0 -4px'}}>
                     <TypographyFHG className={classes.labelStyle}> Subscription Start</TypographyFHG>
                     <DatePicker
                       className={classes.calStyle}
                       name={'subscriptionStart'}
                       key={'subscriptionStart'}
                       onChange={handleDateChange}
                       inputProps={{style: {padding: '8px', fontSize: '0.825rem'}}}
                       renderInput={(props) => (
                         <TextField {...props}  />
                       )}
                       style={{height: '28px', width: 210}}
                       value={dateValue}
                     />
                  </Stack>
                  <Select
                    key={'subscriptionType'+defaultValues.id}
                    name={'subscriptionType'}
                    fullWidth
                    defaultValue={defaultValues && defaultValues.subscriptionType ? defaultValues.subscriptionType : SUB_FREE}
                    className={classes.elementStyle}
                    inputProps={{style: {fontSize: '0.75rem'}}}
                    onChange={handleSelectChange}
                    sx={{height: 36}}
                  >
                     <MenuItem value={SUB_FREE} style={{fontSize: '0.75rem'}}>{SUB_FREE}</MenuItem>
                     <MenuItem value={SUB_PREM} style={{fontSize: '0.75rem'}}>{SUB_PREM}</MenuItem>
                  </Select>
               </Box>
               <Stack className={classes.leftPad} direction="row" display={'flex'} alignItems={'flex-start'} sx={{marginLeft: '24px'}}>
                  <ProgressButton
                    className={classes.buttonStyle}
                    isProgress={isSaving}
                    type={'submit'}
                    size="small"
                    labelKey='save.label'
                    disabled={isSaving}
                    variant="contained"
                  />
                  <ButtonFHG
                    className={classes.buttonStyle}
                    size="small"
                    labelKey={'cancel.button'}
                    disabled={isSaving}
                    onClick={handleClose}
                    variant="contained"
                  />
               </Stack>
            </Stack>
         </Form>
      </Fade>
   );
}

UserEdit.propTypes = {
   id: PropTypes.any,
   onClose: PropTypes.func
}
