import {useEffect} from 'react';
import {useSetRecoilState, atom} from 'recoil';

export const userGroupsState = atom({
   key: 'userGroupsStateKey',
   default: {username: '', groups: [], signOut: undefined, sub: undefined},
});

export default function AuthenticatedUser({authState, children}) {
   const setUserGroups = useSetRecoilState(userGroupsState);

   useEffect(() => {
      if (authState) {
         const groups = authState?.user?.signInUserSession?.idToken?.payload?.['cognito:groups'] ?? [];
         const sub = authState?.user?.attributes?.sub;
         setUserGroups({username: authState?.user?.username, groups, signOut: authState.signOut, sub});
      }
   }, [authState, setUserGroups]);

   if (authState) {
      return children;
   } else {
      return null;
   }
}
