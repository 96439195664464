import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import assign from 'lodash/assign';
import {Autocomplete, Box, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, lighten} from '@mui/material';
import ButtonFHG from '../fhg/components/ButtonFHG';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import find from 'lodash/find';
import Form from '../fhg/components/edit/Form';
import {formatMessage, toNumber} from '../fhg/utils/Utils';
import HelpIcon from '@mui/icons-material/HelpOutline';
import Loading from '../fhg/components/Loading';
import {makeStyles} from 'tss-react/mui';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TextFieldFHG from './TextFieldFHG';
import Tooltip from '@mui/material/Tooltip';
import TypographyFHG from '../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useMutationFHG from '../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import {
  DATA_TYPES_QUERY, getSuggestRefetchQueries,
  SUGGEST_BY_ID_QUERY, SUGGEST_APPROVE, SUGGEST_UPDATE
} from '../data/QueriesGL';
import {APPROVAL_ACCEPT, APPROVAL_REJECT, DATA_TYPES, FLYOUT_EL_WIDTH, NO_BARCODE, NO_BARCODE_LIST} from '../Constants';
import ProgressButton from "../fhg/components/ProgressButton";

const useStyles = makeStyles({name: 'SuggestBottleAddStyles'})((theme) => ({
  alertStyle: {
    marginBottom: theme.spacing(1)
  },
  buttonStyle1: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    width: '100px'
  },
  buttonStyle2: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    width: '80px'
  },
  buttonWrapper: {
    marginLeft: theme.spacing(2),
  },
  dialogButtonStyle: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    margin: theme.spacing(1, 0.5),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    width: '60px'
  },
  dialogButtonWrapper: {
    marginRight: theme.spacing(1.5),
  },
  dialogCloseButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  dialogHeaderStyle: {
    padding: theme.spacing(1, 1, 0, 1)
  },
  '& .MuiDialogTitle-root': {
    padding: theme.spacing(0)
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    width: '100%',
    height: '100%',
  },
  elementStyle0: {
    border: 'thin solid lightgray',
    borderRadius: '5px',
    height: '32px',
    fontSize: '0.75rem',
    margin: theme.spacing(0,0, 1, 2),
    padding: theme.spacing(1,0, 0, 1),
    width: FLYOUT_EL_WIDTH
  },
  elementStyle: {
    fontSize: '0.75rem',
    margin: theme.spacing(0.5,0, 1.25, 2),
    width: FLYOUT_EL_WIDTH
  },
  icon: {
    margin: theme.spacing(1.5, 0.65, 0, 0.65)
  },
  selectStyle: {
    fontSize: '0.75rem',
    margin: theme.spacing(1, 0, 2, 2),
    width: FLYOUT_EL_WIDTH
  },
  titleStyle: {
    margin: theme.spacing(0,0, 1, 2)
  }
}));

export default function SuggestBottleAdd(props) {
  const {classes} = useStyles();
  const bottleId = props?.id;
  const userId = props?.userId;
  const intl = useIntl();
  const [approvalType, setApprovalType] = useState('');
  const [bottleSize, setBottleSize] = useState('');
  const [country, setCountry] = useState('');
  const [whiskeyType, setWhiskeyType] = useState('');
  const [decisionMade, setDecisionMade] = useState(false);
  const [isChanged, setIsChanged] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [noUpc, setNoUpc] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [suggestValues, setSuggestValues] = useState({});
  const [suggestSaved, setSuggestSaved] = useState(false);
  const [approveCalled, setApproveCalled] = useState(false);
  const [decisionComplete, setDecisionComplete] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [openRejectDialog, setOpenRejectDialog] = useState(false);
  const [bottleSizeData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.BOTTLE_SIZE}}}}, 'data_type.type');
  const [countryData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.COUNTRY_OF_ORIGIN}}}}, 'data_type.type');
  const [whiskeyTypeData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.WHISKEY_TYPE}}}}, 'data_type.type');
  const [suggestData] = useQueryFHG(SUGGEST_BY_ID_QUERY, {variables: {id: bottleId, userId: userId}}, 'suggest.type');
  const [suggestApprove, {data: approveData, error: approveError}] = useMutationFHG(SUGGEST_APPROVE);
  const [suggestUpdate, {data: updateData, error: updateError}] = useMutationFHG(SUGGEST_UPDATE);

  useMemo(() => {
    if (!isDefaultSet && suggestData && suggestData.bottle1 && Object.keys(suggestData.bottle1).length > 0) {
      let barcode = suggestData.bottle1.barcodeUPC;
      if (NO_BARCODE_LIST.includes(suggestData.bottle1.barcodeUPC)) {
        barcode =  NO_BARCODE;
        setNoUpc(true);
      }
      let defaultItem = {
        id: suggestData.bottle1.id,
        barcodeUPC: barcode,
        bottleSize: suggestData.bottle1.bottleSize,
        countryOfOrigin: suggestData.bottle1?.countryOfOrigin || 'United States',
        description: suggestData.bottle1.description,
        distillery: suggestData.bottle1.distillery,
        proof: suggestData.bottle1.proof,
        series: suggestData.bottle1.series,
        yearBottled: suggestData.bottle1.yearBottled,
        whiskeyType: suggestData.bottle1.whiskeyType,
        username: suggestData.bottle1.user?.username,
        email: suggestData.bottle1.user?.email
      }
      setBottleSize(suggestData.bottle1.bottleSize);
      setCountry(suggestData.bottle1?.countryOfOrigin);
      setWhiskeyType(suggestData.bottle1.whiskeyType);
      setSuggestValues(assign({}, defaultItem));
      setIsDefaultSet(true);
    }
  }, [suggestData, isDefaultSet, setBottleSize, setSuggestValues, setIsDefaultSet]);

  const handleClose = useCallback(() => {
    props?.onClose();
  }, [props]);

  const handleSubmit = useCallback(async (approvalType) => {
    try {
      setIsSaving(true);
      let bottleInput;
      let currentItem = {
        ...suggestValues
      };
      if (isChanged || noUpc) {
        // If any of the suggested data is changed, first update the suggested bottle.
        let bottleYear = currentItem && currentItem.yearBottled ? toNumber(currentItem.yearBottled, true) : null;
        bottleYear = bottleYear > 0 ? bottleYear : null;
        bottleInput = {
          id: currentItem.id,
          userId: userId,
          barcodeUPC: currentItem?.barcodeUPC,
          bottleSize: bottleSize,
          countryOfOrigin: country,
          description: currentItem?.description,
          distillery: currentItem?.distillery,
          proof: currentItem && currentItem.proof ? toNumber(currentItem.proof, true) : null,
          series: currentItem?.series,
          yearBottled: bottleYear,
          whiskeyType: whiskeyType
        };
        await suggestUpdate({
          variables: {input: bottleInput}
        });
      } else {
        setSuggestSaved(true);
      }

      setIsChanged(false);
    } catch (e) {
      console.log('Error', e?.message);
    } finally {
      setIsSaving(false);
    }
  }, [bottleSize, country, whiskeyType, noUpc, suggestValues, suggestUpdate, isChanged, setIsSaving, userId]);

  useEffect(() => {
    if (updateData && updateError === undefined) {
      setSuggestSaved(true);
    }
  }, [updateData, updateError, setSuggestSaved]);

  useEffect(() => {
    if (updateError && updateError.length > 0 && updateError[0].message) {
      setMessageText(updateError[0].message);
      setOpenAlert(true);
    }
  }, [updateError, setMessageText, setOpenAlert]);

  useEffect(() => {
    if (!suggestSaved && !submitted && approvalType) {
      if (approvalType === APPROVAL_REJECT) {
        setOpenRejectDialog(true);
      } else {
        setDecisionMade(true);
      }
    }
  }, [approvalType, setDecisionMade, submitted, suggestSaved]);

  useEffect(() => {
    if (!suggestSaved && !submitted && decisionMade) {
      handleSubmit(approvalType).then(r => {});
      setSubmitted(true);
    }
  }, [approvalType, decisionMade, handleSubmit, setSubmitted, submitted, suggestSaved]);

  useEffect(() => {
    async function approveSuggestBottle() {
      const input = {
        suggestBottleId: bottleId,
        userId: userId,
        approval: approvalType,
        reasonMessage: suggestValues?.reasonMessage
      };
      return await suggestApprove({
        variables: {input},
        refetchQueries: getSuggestRefetchQueries()
      });
    }
    if (suggestSaved && !decisionComplete && !approveCalled) {
      approveSuggestBottle().then(r => {
        setApproveCalled(true);
      });
    }
  }, [approveCalled, approvalType, bottleId, decisionComplete, userId, handleClose, setApproveCalled, suggestApprove, suggestValues, suggestSaved]);

  useEffect(() => {
    if (approveData && approveData.approvedBottle && approveError === undefined) {
      setDecisionComplete(true);
    }
  }, [approveData, approveError, setDecisionComplete]);

  useEffect(() => {
    if (approveError && approveError.message) {
      let msg = approveError.message === 'Response not successful: Received status code 400' ? 'UPC code already in use.' : approveError.message;
      setMessageText(msg);
      setOpenAlert(true);
    }
  }, [approveError, setMessageText, setOpenAlert]);

  useEffect(() => {
    if (decisionComplete) {
      handleClose();
    }
  }, [handleClose, decisionComplete]);

  const bottleSizes = useMemo(() => {
    let result = [];
    if (bottleSizeData && bottleSizeData.data && bottleSizeData.data.items) {
      let data = bottleSizeData.data.items;
      data?.forEach(x => {
        if (x.isValid) {
          result.push({label: x.name, value: x.name});
        }
      });
    }
    return result;
  }, [bottleSizeData]);
  const countries = useMemo(() => {
    let result = [];
    if (countryData && countryData.data && countryData.data.items) {
      let data = countryData.data.items;
      data?.forEach(x => {
        if (x.isValid) {
          result.push({label: x.name, value: x.name});
        }
      });
    }
    return result;
  }, [countryData]);
  const whiskeyTypes = useMemo(() => {
    let result = [];
    if (whiskeyTypeData && whiskeyTypeData.data && whiskeyTypeData.data.items) {
      let data = whiskeyTypeData.data.items;
      data?.forEach(x => {
        if (x.isValid) {
          result.push({label: x.name, value: x.name});
        }
      });
    }
    return result;
  }, [whiskeyTypeData]);

   const getBottleSize = useCallback(() => {
    let result = '';
    if (bottleSizes?.length > 0) {
      result = find(bottleSizes, x => x.value === bottleSize);
    }
    return result;
  }, [bottleSize, bottleSizes]);

  const getCountry = useCallback(() => {
    let result = '';
    if (countries?.length > 0) {
      result = find(countries, x => x.value === country);
    }
    return result;
  }, [country, countries]);

  const getWhiskeyType = useCallback(() => {
    let result = '';
    if (whiskeyTypes?.length > 0) {
      result = find(whiskeyTypes, x => x.value === whiskeyType);
    }
    return result;
  }, [whiskeyType, whiskeyTypes]);

  const handleAlertClose = useCallback(() => {
    setMessageText('');
    setOpenAlert(false);
  }, [setOpenAlert, setMessageText]);

  const handleCloseDialog = useCallback(() => {
    setOpenRejectDialog(false);
  }, [setOpenRejectDialog]);

  const handleRejection = useCallback(() => {
    setDecisionMade(true);
    setOpenRejectDialog(false);
  }, [setOpenRejectDialog]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert className={classes.alertStyle} severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
    }
    return result;
  }, [messageText, openAlert, handleAlertClose]);

  const getOptionSelect = useCallback((option, value) => {
    return option.value === value.value
  }, []);

  const handleChange = useCallback((e) => {
    const { target: { name, value } } = e;
    setSuggestValues(suggestValues => ({...suggestValues, [name]: value}));
    setIsChanged(true);
  }, [setSuggestValues, setIsChanged]);

  const getRejectDialog = useCallback(() => {
    let result = undefined;
    if (openRejectDialog) {
      result = (
        <Dialog open={openRejectDialog} onClose={handleCloseDialog}>
          <DialogTitle disableTypography>
            <Box display="flex" alignItems="center">
              <Box flexGrow={1} ><TypographyFHG id="suggest.reject.title" className={classes.dialogHeaderStyle} /></Box>
              <Box>
                <IconButton className={classes.closeButton} onClick={handleCloseDialog}>
                  <CloseIcon />
                </IconButton>
              </Box>
            </Box>
          </DialogTitle>
          <Divider />
          <DialogContent>
            <TextFieldFHG
              key={'reasonMessage'}
              name={'reasonMessage'}
              autoFocus
              className={classes.elementStyle}
              label={formatMessage(intl, 'suggest.reject.reason.label')}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              onChange={handleChange}
              required
              value={suggestValues?.reasonMessage}
            />
          </DialogContent>
          <DialogActions>
            <Stack className={classes.dialogButtonWrapper} direction="row" display={'flex'} alignItems={'flex-start'}>
              <ButtonFHG className={classes.dialogButtonStyle} variant='outlined' color='primary'
                         size='small' labelKey='save.button' onClick={() => handleRejection()} />
              <ButtonFHG className={classes.dialogButtonStyle} variant='outlined' size={'small'} labelKey={'cancel.button'}
                         onClick={() => handleCloseDialog()} />
            </Stack>
          </DialogActions>
        </Dialog>
      );
    }
    return result;
  }, [classes, handleChange, handleCloseDialog, handleRejection, intl, openRejectDialog, suggestValues]);

  const handleBottleSizeChange = useCallback((event, value) => {
    setBottleSize(value?.value);
    setIsChanged(true);
  }, [setBottleSize, setIsChanged]);

  const handleCountryChange = useCallback((event, value) => {
    setCountry(value?.value);
    setIsChanged(true);
  }, [setCountry, setIsChanged]);

  const handleWhiskeyTypeChange = useCallback((event, value) => {
    setWhiskeyType(value?.value);
    setIsChanged(true);
  }, [setWhiskeyType, setIsChanged]);

  const handleAccept = useCallback(() => {
    setApprovalType(APPROVAL_ACCEPT);
  }, [setApprovalType]);

  const handleReject = useCallback(() => {
    setApprovalType(APPROVAL_REJECT);
  }, [setApprovalType]);

  const isReady = true;  //!!bottle;

  return (
    <Fade in={isReady} mountOnEnter>
      <Form>
        <Loading isLoading={!isReady} />
        <Stack className={classes.formStyle}>
          <TypographyFHG id={props?.titleKey}  className={classes.titleStyle} />
          {getAlert()}
          {getRejectDialog()}
          <TypographyFHG className={suggestValues?.username ? classes.elementStyle0 : null}>{suggestValues?.username}</TypographyFHG>
          <TypographyFHG className={suggestValues?.email ? classes.elementStyle0 : null}>{suggestValues?.email}</TypographyFHG>
            <TextFieldFHG
              key={'barcodeUPC'+suggestValues.id}
              name={'barcodeUPC'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              label={formatMessage(intl, 'bottle.add.upc.label')}
              onChange={handleChange}
              required
              size={'small'}
              value={suggestValues?.barcodeUPC}
            />
            <TextFieldFHG
              key={'distillery'+suggestValues.id}
              name={'distillery'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              label={formatMessage(intl, 'bottle.add.brand.label')}
              onChange={handleChange}
              required
              size={'small'}
              value={suggestValues?.distillery}
            />
            <TextFieldFHG
              key={'series'+suggestValues.id}
              name={'series'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              label={formatMessage(intl, 'bottle.add.label.label')}
              onChange={handleChange}
              required
              size={'small'}
              value={suggestValues?.series}
            />
            <Autocomplete
              name={'bottleSize'}
              key={'bottleSize1'+suggestValues.id}
              className={classes.selectStyle}
              fullWidth
              getOptionSelected={(option, value) => getOptionSelect(option, value)}
              options={bottleSizes}
              onChange={handleBottleSizeChange}
              renderOption={(props, option) => {
                const {label} = option;
                return (<span {...props} style={{fontSize: '0.75rem'}}>{label}</span>);
              }}
              renderInput={(params) => (
                <TextField{...params}
                  InputProps={{...params.InputProps, style: { fontSize: '0.825rem'}}}
                  label={formatMessage(intl, 'suggest.add.bottleSize.label')}
                  required
                  variant="outlined"/>
              )}
              size="small"
              value={getBottleSize()}
            />
            <Autocomplete
              name={'whiskeyType'}
              key={'whiskeyType1'+suggestValues.id}
              className={classes.selectStyle}
              fullWidth
              getOptionSelected={(option, value) => getOptionSelect(option, value)}
              inputProps={{style: {fontSize: '0.75rem'}}}
              options={whiskeyTypes}
              onChange={handleWhiskeyTypeChange}
              renderOption={(props, option) => {
                const {label} = option;
                return (<span {...props} style={{fontSize: '0.75rem'}}>{label}</span>);
              }}
              renderInput={(params) => (
                <TextField{...params}
                          InputProps={{...params.InputProps, style: { fontSize: '0.825rem'}}}
                          label={formatMessage(intl, 'suggest.add.type.label')}
                          required
                          variant="outlined"/>
              )}
              size="small"
              value={getWhiskeyType()}
            />
            <TextFieldFHG
              key={'proof'+suggestValues.id}
              name={'proof'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              label={formatMessage(intl, 'bottle.add.proof.label')}
              onChange={handleChange}
              size={'small'}
              type="number"
              required
              value={suggestValues?.proof}
            />
          <Autocomplete
            name={'countryOfOrigin'}
            key={'countryOfOrigin'+suggestValues.id}
            className={classes.selectStyle}
            fullWidth
            getOptionSelected={(option, value) => getOptionSelect(option, value)}
            inputProps={{style: {fontSize: '0.75rem'}}}
            options={countries}
            onChange={handleCountryChange}
            renderOption={(props, option) => {
              const {label} = option;
              return (<span {...props} style={{fontSize: '0.75rem'}}>{label}</span>);
            }}
            renderInput={(params) => (
              <TextField {...params}
                        InputProps={{...params.InputProps, style: {fontSize: '0.825rem'}}}
                        label={formatMessage(intl, 'suggest.add.country.label')}
                        required
                        variant="outlined"/>
            )}
            size="small"
            value={getCountry()}
          />
          <TextFieldFHG
              key={'yearBottled'+suggestValues.id}
              name={'yearBottled'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              label={formatMessage(intl, 'bottle.add.year.label')}
              onChange={handleChange}
              size={'small'}
              type="number"
              value={suggestValues?.yearBottled}
            />
            <TextFieldFHG
              key={'description'+suggestValues.id}
              name={'description'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              columns={3}
              label={formatMessage(intl, 'bottle.add.desc.label')}
              onChange={handleChange}
              multiline
              rows="2"
              size={'small'}
              value={suggestValues?.description}
            />
            <Stack className={classes.buttonWrapper} direction="row" display={'flex'} alignItems={'flex-start'}>
              <ProgressButton
                className={classes.buttonStyle1}
                isProgress={isSaving}
                disabled={isSaving}
                labelKey='accept.button'
                size="small"
                onClick={handleAccept}
                variant="contained"
              />
              <Tooltip title={formatMessage(intl, 'suggest.accept.tooltip')}><HelpIcon className={classes.icon} color="primary" /></Tooltip>
              <ButtonFHG
                className={classes.buttonStyle1}
                disabled={isSaving}
                labelKey='reject.button'
                onClick={handleReject}
                size="small"
                variant="contained"
              />
              <Tooltip title={formatMessage(intl, 'suggest.reject.tooltip')}><HelpIcon className={classes.icon} color="primary" /></Tooltip>
              <ButtonFHG
                className={classes.buttonStyle2}
                disabled={isSaving}
                labelKey="cancel.button"
                onClick={handleClose}
                size="small"
                variant="contained"
              />
            </Stack>
        </Stack>
      </Form>
    </Fade>
  );
}
