import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import assign from 'lodash/assign';
import ButtonFHG from '../fhg/components/ButtonFHG';
import {cacheUpdate} from '../fhg/utils/DataUtil';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import Fade from '@mui/material/Fade';
import Form from '../fhg/components/edit/Form';
import {formatMessage, toNumber} from '../fhg/utils/Utils';
import {FormControlLabel, lighten} from '@mui/material';
import get from 'lodash/get';
import Loading from '../fhg/components/Loading';
import {makeStyles} from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';
import ProgressButton from '../fhg/components/ProgressButton';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextFieldFHG from './TextFieldFHG';
import TypographyFHG from '../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useMutationFHG from '../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import {
  getUserBottleCacheQueries,
  getUserBottleRefetchQueries,
  USER_BOTTLE_BY_ID_QUERY, USER_BOTTLE_UPDATE
} from '../data/QueriesGL';
import {DATE_DB_FORMAT, DATE_FORMAT_KEYBOARD, FLYOUT_EL_WIDTH, USER_BOTTLE_STATUS} from '../Constants';
import moment from "moment";
import DatePickerFHG from "../fhg/components/edit/DatePickerFHG";

const useStyles = makeStyles({name: 'UserBottleEditStyles'})((theme) => ({
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(0, 1, 1, 2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    width: '100px'
  },
  calStyle: {
    margin: theme.spacing(0, 0, 0.5, 2)
  },
  checkStyle: {
    margin: theme.spacing(0,0, 0, 1.5),
    padding: 0
  },
  dividerStyle: {
    backgroundColor: 'black',
    margin: theme.spacing(0,0, 1.5, 2)
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    marginTop: theme.spacing(0),
    width: '100%',
    height: '100%'
  },
  elementStyle: {
    margin: theme.spacing(0,0, 0, 2),
    width: FLYOUT_EL_WIDTH - 8
  },
  elementStyle0: {
    border: 'thin solid lightgray',
    borderRadius: '5px',
    color: 'gray',
    fontSize: '0.75rem',
    margin: theme.spacing(0,0, 0, 2),
    padding: theme.spacing(0.5),
    width: FLYOUT_EL_WIDTH - 8
  },
  elementStyle1: {
    margin: theme.spacing(-0.5,0, 0.5, 2),
    width: 172
  },
  elementStyle2: {
    margin: theme.spacing(0,0, 0, 1),
    width: 200
  },
  labelStyle: {
    fontSize: '0.75rem',
    fontWeight: 'bold',
    margin: theme.spacing(0,0, 0, 2),
    paddingTop: theme.spacing(1),
    width: theme.spacing(5)
  },
  '.MuiCheckbox-root': {
    padding: theme.spacing(0)
  },
  '.MuiFormControlLabel-label': {
    fontSize: '0.75rem'
  },
  '.MuiSelect-outlined': {
    fontSize: '0.75rem'
  },
  dateLabelStyle1: {
    backgroundColor: 'white',
    color: 'gray',
    flex: 'flex',
    fontSize: '0.75rem',
    margin: theme.spacing(-1, 0,-2, 4),
    opacity: 100,
    padding: theme.spacing(0, 0.5, 0.5, 0.5),
    width: '92px',
    zIndex: 20
  },
  selectLabelStyle1: {
    backgroundColor: 'white',
    color: 'gray',
    flex: 'flex',
    fontSize: '0.75rem',
    margin: theme.spacing(-1, 0,-1.25, 4),
    opacity: 100,
    padding: theme.spacing(0, 0.5, 0.5, 0.5),
    width: '60px',
    zIndex: 20
  },
  selectLabelStyle2: {
    backgroundColor: 'white',
    color: 'gray',
    flex: 'flex',
    fontSize: '0.75rem',
    margin: theme.spacing(-1, 0,-1.25, 4),
    opacity: 100,
    padding: theme.spacing(0, 0.5),
    width: '42px',
    zIndex: 20
  },
  selectStyle: {
    margin: theme.spacing(0, 0, 0, 2),
    width: FLYOUT_EL_WIDTH - 8
  },
  stackStyle: {
    margin: theme.spacing(0)
  },
  titleStyle: {
    margin: theme.spacing(0,0, 0, 2)
  }
}));

export default function UserBottleEdit(props) {
  const {classes} = useStyles();
  const {id: userBottleId, sell, userId} = props;
  const intl = useIntl();
  const [bottleUpdated, setBottleUpdated] = useState(false);
  const [defaultValues, setDefaultValues] = useState({});
  const [editValues, setEditValues] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [willingToSell, setWillingToSell] = useState(sell);
  const [userBottleData] = useQueryFHG(USER_BOTTLE_BY_ID_QUERY, {variables: {id: userBottleId, userId: userId}}, 'user.bottle.type');
  const [userBottleUpdate, {data: updateData, error: updateError}] = useMutationFHG(USER_BOTTLE_UPDATE);
  const bottle = useMemo(() => userBottleData?.data.genericBottle || {}, [userBottleData]);

  useMemo(() => {
    if (!isDefaultSet && userBottleData && userBottleData.data && Object.keys(userBottleData.data).length > 0) {
      setDefaultValues(assign({}, userBottleData.data, {purchaseDate: userBottleData.data.purchaseDate ? userBottleData.data.purchaseDate : ''}));
      setIsDefaultSet(true);
    }
  }, [isDefaultSet, userBottleData, setDefaultValues]);

  useEffect(() => {
    if (updateData && updateError === undefined) {
      setBottleUpdated(true);
    }
  }, [updateData, updateError, setBottleUpdated]);

  useEffect(() => {
    if (updateError && updateError.message) {
      setMessageText(updateError.message);
      setOpenAlert(true);
    }
  }, [updateError]);

  const getValue = useCallback((path, defaultValue = '') => {
    const editValue = get(editValues, path);
    return editValue !== undefined ? editValue : get(defaultValues, path) || defaultValue;
  }, [defaultValues, editValues]);

  const handleClose = useCallback(() => {
    props?.onClose();
  }, [props]);

  useEffect(() => {
    if (bottleUpdated) {
      handleClose();
    }
  }, [bottleUpdated, handleClose]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        setIsSaving(true);
        let currentItem = {
          ...defaultValues,
          ...editValues
        };

        let updateInput = {
          id: userBottleId,
          userId: userId,
          genericBottleId: currentItem.genericBottleId,
          specialDesignation: currentItem.specialDesignation,
          storageLocation: currentItem.storageLocation,
          purchaseStore: currentItem.purchaseStore,
          purchasePriceValue: currentItem.purchasePriceValue ? toNumber(currentItem.purchasePriceValue) : null,
          purchasePriceCurrency: currentItem.purchasePriceCurrency,
          purchaseDate: currentItem.purchaseDate ? moment(currentItem.purchaseDate).format(DATE_DB_FORMAT) : null,
          status: currentItem.status,
          willingToSell: willingToSell,
        };
//console.log(updateInput);
        await userBottleUpdate({
          variables: {updateInput},
          refetchQueries: getUserBottleRefetchQueries(),
          update: cacheUpdate(getUserBottleCacheQueries(userBottleId), userBottleId)
        });
      } catch (e) {
        //Intentionally left blank
      } finally {
        setIsSaving(false);
      }
      setIsChanged(false);
    }
  }, [defaultValues, editValues, isChanged, setIsChanged, setIsSaving, userBottleId, userBottleUpdate, userId, willingToSell]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
    }
    return result;
  }, [messageText, openAlert, handleAlertClose]);

  const handleElementChange = useCallback((e) => {
    const {target: {name, value}} = e;
    setEditValues(editValues => ({...editValues, [name]: value}));
    setIsChanged(true);
  }, [setEditValues, setIsChanged]);

  const handleDateChange = useCallback((date) => {
    setEditValues(editValues => ({...editValues, purchaseDate: date}));
    setIsChanged(true);
  }, [setEditValues, setIsChanged]);

  const handleSelectChange = useCallback((e) => {
    const {target: {name, value}} = e;
    setEditValues(editValues => ({...editValues, [name]: value}));
    setIsChanged(true);
  }, [setEditValues, setIsChanged]);

  const handleSellToggle = () => {
    setWillingToSell(value => !value);
    setIsChanged(true);
  }

  const isReady = !!bottle;

  return (
    <Fade in={isReady} mountOnEnter>
      <Form onSubmit={handleSubmit}>
        <Loading isLoading={!isReady} />
        <Stack
          name={'User Bottle Edit Root'}
          className={classes.formStyle}
          direction={'column'}
          spacing={2}
        >
          <TypographyFHG id="user.bottle.edit.title"  className={classes.titleStyle} variant="h6" />
          {getAlert()}
          <Stack
            sx={{
              '&': {overflow: 'auto', mb: 1, px: 2},
              '& > :not(style)': {my: 1},
            }}
          >
            <TypographyFHG className={getValue('user.username') ? classes.elementStyle0 : null} style={{margin: '0 0 0 16px'}} variant={'subtitle1'}>{getValue('user.username')}</TypographyFHG>
            <TypographyFHG className={classes.elementStyle0} style={{margin: '4px 0 0 16px'}}>{getValue('user.email')}</TypographyFHG>
            <Divider className={classes.dividerStyle} />
            <Stack direction="row" display="flex" style={{margin: '0 0 0 8px'}}>
              <TypographyFHG className={classes.labelStyle} InputProps={{fontSize: '0.75 rem'}}>UPC</TypographyFHG>
              <TypographyFHG className={classes.elementStyle0}>{getValue('genericBottle.barcodeUPC')}</TypographyFHG>
            </Stack>
            <Stack direction="row" display="flex" style={{margin: '4px 0 0 8px'}}>
              <TypographyFHG className={classes.labelStyle}>Brand</TypographyFHG>
              <TypographyFHG className={classes.elementStyle0}>{getValue('genericBottle.distillery')}</TypographyFHG>
            </Stack>
            <Stack direction="row" display="flex" style={{margin: '4px 0 0 8px'}}>
              <TypographyFHG className={classes.labelStyle}>Label</TypographyFHG>
              <TypographyFHG className={classes.elementStyle0}>{getValue('genericBottle.series')}</TypographyFHG>
            </Stack>
            <Stack direction="row" display="flex" style={{margin: '4px 0 0 8px'}}>
              <TypographyFHG className={classes.labelStyle}>Type</TypographyFHG>
              <TypographyFHG className={classes.elementStyle0}>{getValue('genericBottle.whiskeyType')}</TypographyFHG>
            </Stack>
            <Stack direction="row" display="flex"style={{margin: '4px 0 0 8px'}}>
              <TypographyFHG className={classes.labelStyle}>Size</TypographyFHG>
              <TypographyFHG className={classes.elementStyle0}>{getValue('genericBottle.bottleSize')}</TypographyFHG>
            </Stack>
             <Stack direction="row" display="flex" style={{margin: '4px 0 0 8px'}}>
              <TypographyFHG className={classes.labelStyle}>Proof</TypographyFHG>
              <TypographyFHG className={classes.elementStyle0}>{getValue('genericBottle.proof')}</TypographyFHG>
            </Stack>
            <Divider className={classes.dividerStyle} />
            <Stack direction="column" display={'flex'} >
              <TypographyFHG id="bottle.purchaseDate.label" className={classes.dateLabelStyle1} color="action" variant="subtitle2" />
              <DatePickerFHG
                className={classes.calStyle}
                name={'purchaseDate'}
                key={'purchaseDate'}
                format={DATE_FORMAT_KEYBOARD}
                inputProps={{style: {fontSize: '0.75rem', paddingTop: '16px'}}}
                style={{height: 36, width: 220}}
                size={'small'}
                value={editValues.purchaseDate ? editValues.purchaseDate : defaultValues.purchaseDate}
                onChange={handleDateChange}
              />
            </Stack>
            <TextFieldFHG
              key={'specialDesignation'+defaultValues.id}
              name={'specialDesignation'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              label={formatMessage(intl, 'bottle.specialDesignation.label')}
              onChange={handleElementChange}
              size={'small'}
              defaultValue={defaultValues.specialDesignation}
              value={editValues?.specialDesignation}
            />
            <TextFieldFHG
              key={'purchaseStore'+defaultValues.id}
              name={'purchaseStore'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              label={formatMessage(intl, 'bottle.purchaseStore.label')}
              onChange={handleElementChange}
              size={'small'}
              defaultValue={defaultValues?.purchaseStore}
              value={editValues?.purchaseStore}
            />
            <Stack direction="row" display="flex" style={{margin: '4px 0 0 0'}}>
              <Stack direction="column" display={'flex'} >
                <TypographyFHG id="bottle.purchasePriceCurrency.label" className={classes.selectLabelStyle1} color="action" variant="subtitle2" />
                <Select
                  key={'purchasePriceCurrency'+defaultValues.id}
                  name={'purchasePriceCurrency'}
                  defaultValue={defaultValues.purchasePriceCurrency ? defaultValues.purchasePriceCurrency : 'USD'}
                  placeholder={formatMessage(intl, 'bottle.purchasePriceCurrency.label')}
                  className={classes.elementStyle1}
                  onChange={handleSelectChange}
                  sx={{height: 34, fontSize: '0.75rem', paddingTop: 0.5}}
                >
                  <MenuItem key="currency-value-usd" sx={{fontSize: '0.75rem'}} value="USD">USD</MenuItem>);
                </Select>
              </Stack>
              <TextFieldFHG
                key={'purchasePriceValue'+defaultValues.id}
                name={'purchasePriceValue'}
                className={classes.elementStyle2}
                InputLabelProps={{shrink: true}}
                inputProps={{style: {fontSize: '0.75rem'}}}
                label={formatMessage(intl, 'bottle.purchasePriceValue.label')}
                onChange={handleElementChange}
                size={'small'}
                defaultValue={defaultValues.purchasePriceValue}
                value={editValues.purchasePriceValue}
              />
            </Stack>
            <TextFieldFHG
              key={'storageLocation'+defaultValues.id}
              name={'storageLocation'}
              className={classes.elementStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.75rem'}}}
              label={formatMessage(intl, 'bottle.storageLocation.label')}
              onChange={handleElementChange}
              size={'small'}
              defaultValue={defaultValues.storageLocation}
              value={editValues?.storageLocation}
            />
            <Stack direction="column" display={'flex'} style={{margin: '4px 0 0 0'}}>
              <TypographyFHG id="bottle.status.label" className={classes.selectLabelStyle2} color="action" variant="subtitle2" />
              <Select
                key={'status'+defaultValues.id}
                name={'status'}
                defaultValue={defaultValues.status ? defaultValues.status : USER_BOTTLE_STATUS[0].value}
                placeholder={formatMessage(intl, 'bottle.status.label')}
                className={classes.selectStyle}
                onChange={handleSelectChange}
                sx={{height: 34, fontSize: '0.75rem', paddingTop: 0.5}}
              >{USER_BOTTLE_STATUS.map(x => {
                return (<MenuItem key={`user-bottle-status-${x.key}`} sx={{fontSize: '0.75rem'}} value={x.key}>{x.value}</MenuItem>);
              })}
              </Select>
            </Stack>
            <FormControlLabel className={classes.checkStyle}
                              componentsProps={{typography:{sx:{fontSize: '0.825rem'}}}}
                              control={<Checkbox name="willingToSell" onChange={handleSellToggle} checked={willingToSell} size="small" />}
                              label="Willing to sell?" />
            <Stack direction="row" display={'flex'} alignItems={'flex-start'}>
              <ProgressButton
                className={classes.buttonStyle}
                isProgress={isSaving}
                type={'submit'}
                size="small"
                labelKey='save.label'
                disabled={isSaving}
                variant="contained"
              />
              <ButtonFHG
                className={classes.buttonStyle}
                disabled={isSaving}
                labelKey="cancel.button"
                onClick={handleClose}
                size="small"
                variant="contained"
              />
            </Stack>
          </Stack>
        </Stack>
      </Form>
    </Fade>
  );
}
