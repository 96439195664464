import React, {useCallback, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import ButtonFHG from '../fhg/components/ButtonFHG';
import Fade from '@mui/material/Fade';
import Form from '../fhg/components/edit/Form';
import {formatMessage} from '../fhg/utils/Utils';
import {lighten} from '@mui/material';
import List from '@mui/material/List';
import ListItemText from '@mui/material/ListItemText';
import {makeStyles} from 'tss-react/mui';
import Stack from '@mui/material/Stack';
import TextFieldFHG from './TextFieldFHG';
import TypographyFHG from '../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useMutationFHG from '../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import {
  getTemplateRefetchQueries, NOTIFICATION_LIST_QUERY,
  NOTIFICATION_MESSAGE_QUERY,
  NOTIFICATION_TEMPLATE_UPDATE
} from '../data/QueriesGL';
import {
  APPROVAL_ACCEPT, APPROVAL_REJECT,
  USER_BOTTLE_TEMPLATE_APPROVAL,
  USER_BOTTLE_TEMPLATE_REJECT,
} from '../Constants';
import find from "lodash/find";

const useStyles = makeStyles({name: 'NotificationTemplateStyles'})((theme) => ({
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1, 0),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    width: '90px'
  },
  buttonWrapper: {
    marginLeft: theme.spacing(2),
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    marginLeft: theme.spacing(2),
  },
  elementStyle0: {
    margin: theme.spacing(0.75,0, 0, 2),
    width: theme.spacing(100)
  },
  elementStyle1: {
    margin: theme.spacing(3,0, 0, 2),
    width: theme.spacing(100)
  },
  labelStyle: {
    backgroundColor: "white",
    color: "gray",
    fontSize: '0.75rem',
    margin: theme.spacing(0.5,0, 0, 2.5),
    padding: theme.spacing(0, 0.5, 0, 0.5),
    width: theme.spacing(18),
  },
  placeStyle: {
    marginTop: theme.spacing(2),
  },
  item: {
    borderBottom: 'darkgray thin solid'
  },
  list0: {
    border: 'darkgray thin solid',
    borderRadius: '4px',
    margin: theme.spacing(0.5,0, 0, 3),
    padding: theme.spacing(0.75, 1),
    width: theme.spacing(20)
  },
  list1: {
    border: 'darkgray thin solid',
    borderRadius: '4px',
    margin: theme.spacing(1,0, 0, 3),
    padding: theme.spacing(0.75, 1),
    width: theme.spacing(20)
  },
  titleStyle: {
    fontSize: '2.0rem',
    margin: theme.spacing(0, 2, 1, 0)
  }
}));

export default function NotificationTemplate() {
  const {classes} = useStyles();
  const intl = useIntl();
  const [loading, setLoading] = useState(true);
  const [messageText, setMessageText] = useState('');
  const [accept, setAccept] = useState({});
  const [acceptContent, setAcceptContent] = useState('');
  const [acceptFound, setAcceptFound] = useState(false);
  const [acceptId, setAcceptId] = useState('');
  const [reject, setReject] = useState({});
  const [rejectContent, setRejectContent] = useState('');
  const [rejectFound, setRejectFound] = useState(false);
  const [rejectId, setRejectId] = useState('');
  const [isAcceptChanged, setIsAcceptChanged] = useState(false);
  const [isRejectChanged, setIsRejectChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [templateData] = useQueryFHG(NOTIFICATION_LIST_QUERY, {variables: {}}, 'notification.type', loading);
  const [acceptData] = useQueryFHG(NOTIFICATION_MESSAGE_QUERY, {variables: {filter: {id: {"eq": acceptId}}}}, 'notification.type');
  const [rejectData] = useQueryFHG(NOTIFICATION_MESSAGE_QUERY, {variables: {filter: {id: {"eq": rejectId}}}}, 'notification.type');
  const [acceptUpdate] = useMutationFHG(NOTIFICATION_TEMPLATE_UPDATE);
  const [rejectUpdate] = useMutationFHG(NOTIFICATION_TEMPLATE_UPDATE);

  useMemo(() => {
    if (loading && templateData && templateData.templates && templateData.templates.items) {
      const a = find(templateData.templates.items, x => x.templateType === USER_BOTTLE_TEMPLATE_APPROVAL)
      const r = find(templateData.templates.items, x => x.templateType === USER_BOTTLE_TEMPLATE_REJECT)
      setAcceptId(a.id)
      setRejectId(r.id)
      setLoading(false);
    }
  }, [loading, templateData, setAcceptId, setRejectId, setLoading]);

  useMemo(() => {
    if (!acceptFound && acceptData && acceptData.template) {
      setAccept(acceptData.template);
      setAcceptContent(acceptData.template.content);
      setAcceptFound(true);
    }
  }, [acceptData, acceptFound, setAccept, setAcceptContent, setAcceptFound]);

  useMemo(() => {
    if (!rejectFound && rejectData && rejectData.template) {
      setReject(rejectData.template);
      setRejectContent(rejectData.template.content);
      setRejectFound(true);
    }
  }, [rejectData, rejectFound, setReject, setRejectContent, setRejectFound]);

  const handleAlertClose = useCallback(() => {
    setMessageText('');
    setOpenAlert(false);
  }, [setOpenAlert, setMessageText]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
    }
    return result;
  }, [messageText, openAlert, handleAlertClose]);

  const getList = useCallback((kind) => {
    let result;
    if (kind === APPROVAL_ACCEPT) {
      result = accept && accept.placeholders && (
        <Stack direction={'column'} display={'flex'} >
          <TypographyFHG className={classes.labelStyle}> Accept Placeholders</TypographyFHG>
          <List className={classes.list0}>
            {accept.placeholders.map(a => <ListItemText className={classes.item} key={a} primary={a} />)}
          </List>
        </Stack>
      );
    } else {
      result = reject && reject.placeholders && (
        <Stack className={classes.placeStyle} direction={'column'} display={'flex'} >
          <TypographyFHG className={classes.labelStyle}> Reject Placeholders</TypographyFHG>
          <List className={classes.list1}>
            {reject.placeholders.map(a => <ListItemText className={classes.item} key={a} primary={a} />)}
          </List>
        </Stack>
      );
    }
    return result;
  }, [classes, accept, reject]);

  const handleAcceptChange = useCallback((e) => {
    const {target: {value}} = e;
    setAcceptContent(value);
    setIsAcceptChanged(true);
  }, [setAcceptContent, setIsAcceptChanged]);

  const handleRejectChange = useCallback((e) => {
    const {target: {value}} = e;
    setRejectContent(value);
    setIsRejectChanged(true);
  }, [setRejectContent, setIsRejectChanged]);

  const handleSaveAccept = useCallback(async () => {
    if (isAcceptChanged) {
      try {
        setIsSaving(true);

        let input = {
          id: acceptId,
          content: acceptContent,
          description: accept.description,
          templateType: accept.templateType
        };

        await acceptUpdate({
          variables: {input},
          refetchQueries: getTemplateRefetchQueries(acceptId, rejectId)
        });
        setIsAcceptChanged(false);
      } catch (e) {
        console.log(e);
      } finally {
        setIsSaving(false);
      }
    }
  }, [accept, acceptContent, acceptId, acceptUpdate, isAcceptChanged, rejectId, setIsAcceptChanged]);

  const handleSaveReject = useCallback(async () => {
    if (isRejectChanged) {
      try {
        setIsSaving(true);

        let input = {
          id: rejectId,
          content: rejectContent,
          description: reject.description,
          templateType: reject.templateType
        };

        await rejectUpdate({
          variables: {input},
          refetchQueries: getTemplateRefetchQueries(acceptId, rejectId)
        });
        setIsRejectChanged(false);
      } catch (e) {
        console.log(e);
      } finally {
        setIsSaving(false);
      }
    }
  }, [acceptId, isRejectChanged, reject, rejectContent, rejectId, rejectUpdate, setIsRejectChanged]);

  return (
    <Stack direction="column" display={'flex'} width={'100%'} height={'100%'}>
      <Stack display={'flex'} flex={'0 0 auto'} direction={'column'}>
        <Stack direction={'row'} justify={'space-between'} alignContent={'center'}>
          <Stack  direction={'column'} display={'flex'} flexWrap={'wrap'} >
            <TypographyFHG id="notification.title"  className={classes.titleStyle} />
            {getAlert()}
            <Fade in={(accept && Object.keys(accept).length > 0)}>
              <Stack  direction={'column'} display={'flex'} flexWrap={'wrap'} >
                <Stack direction={'row'} justify={'space-between'} alignContent={'center'}>
                  <Form key="acceptForm">
                    <Stack className={classes.formStyle}>
                      <TextFieldFHG
                        key={'notificationContent'}
                        name={'content'}
                        className={classes.elementStyle0}
                        InputLabelProps={{shrink: true}}
                        label={formatMessage(intl, 'notification.content.approve.label')}
                        multiline
                        onChange={handleAcceptChange}
                        required
                        value={acceptContent}
                      />
                      <Stack className={classes.buttonWrapper} direction="row" display={'flex'} alignItems={'flex-start'}>
                        <ButtonFHG
                          className={classes.buttonStyle}
                          disabled={isSaving}
                          labelKey='save.button'
                          onClick={handleSaveAccept}
                          size="small"
                          variant="contained"
                        />
                      </Stack>
                    </Stack>
                  </Form>
                  {getList(APPROVAL_ACCEPT)}
                </Stack>
                <Stack direction={'row'} justify={'space-between'} alignContent={'center'}>
                  <Form key="rejectForm">
                  <Stack className={classes.formStyle}>
                    <TextFieldFHG
                      key={'notificationContent'}
                      name={'content'}
                      className={classes.elementStyle1}
                      InputLabelProps={{shrink: true}}
                      label={formatMessage(intl, 'notification.content.reject.label')}
                      multiline
                      onChange={handleRejectChange}
                      required
                      value={rejectContent}
                    />
                    <Stack className={classes.buttonWrapper} direction="row" display={'flex'} alignItems={'flex-start'}>
                      <ButtonFHG
                        className={classes.buttonStyle}
                        disabled={isSaving}
                        labelKey='save.button'
                        onClick={handleSaveReject}
                        size="small"
                        variant="contained"
                      />
                    </Stack>
                  </Stack>
                </Form>
                  {getList(APPROVAL_REJECT)}
                </Stack>
              </Stack>
            </Fade>
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
}