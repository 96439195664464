import {intersection} from 'lodash';
import castArray from 'lodash/castArray';
import {useMemo} from 'react';
import {useRecoilValue} from 'recoil';
import {userGroupsState} from '../components/security/AuthenticatedUser';

/**
 * Hook to determine user access. If the user has the required groups hasAccess is true.
 * @param requiredGroups The group or list of groups required to give access.
 * @return {{hasAccess: boolean, isLoggedIn: boolean}} hasAccess indicate is the user has a required group and
 *  isLoggedIn indicates if the user is logged in.
 */
export default function useHasAccess(requiredGroups = []) {
   const {groups = [], username} = useRecoilValue(userGroupsState);

   return useMemo(() => {
      const useRequiredGroups = castArray(requiredGroups);

      if (process.env.NODE_ENV !== 'production' && useRequiredGroups.lenth <= 0) {
         console.log('Has Access needs a list of groups.');
      }
      return {
         hasAccess: intersection(useRequiredGroups, groups || [])?.length > 0,
         isLoggedIn: username !== undefined,
         username,
      };
   }, [groups, requiredGroups, username]);
}
