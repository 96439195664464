import React, {useEffect, useState} from 'react';
import '@aws-amplify/ui-react/styles.css';
import {AdapterMoment} from '@mui/x-date-pickers/AdapterMoment';
import {Amplify} from 'aws-amplify';
import AuthenticatedUser from './fhg/components/security/AuthenticatedUser';
import AuthenticatorFHG from './fhg/components/security/Authenticator';
import {authenticatorTheme} from './components/theme/authenticatorTheme';
import awsconfig from './environment/aws-exports';
import awsProductionConfig from './environment/aws-production-exports';
import {CssBaseline} from '@mui/material';
import ErrorBoundary from './components/ErrorBoundary';
import {IntlProvider} from 'react-intl';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import Main from './pages/Main';
import {makeStyles} from 'tss-react/mui';
import {StylesProvider} from '@mui/styles';
import './App.css';

const useStyles = makeStyles({name: 'AppStyles'})({
   frameStyle: {
      height: `100vh`,
      overflow: 'hidden',
      backgroundColor: '#FCFEFE',
      '& > div': {
         height: '100%',
      },
   },
});

const config = process.env.REACT_APP_POOL === 'production' ? awsProductionConfig : awsconfig;
Amplify.configure(config);

/**
 * Load the messages for the locales.
 * CAVEAT: This is required to code splitting to work.
 */
const messageLoader = {
   en: () => import('./messages/en-US'),
};

const formats = {
   number: {
      USD: {
         style: 'currency',
         currency: 'USD',
      },
   },
};

/**
 * App component. Responsible for initializing AWS, GraphQL and Intl (localization). App can be displayed without
 * authorization. Main is displayed when authorized.
 *
 * @return {JSX.Element|null}
 * @constructor
 */
export default function App() {
   const { classes } = useStyles();
   const [messages, setMessages] = useState({});

   useEffect(() => {
      messageLoader.en().then((messages) => {
         setMessages(messages);
      });
   }, []);

   if (Object.keys(messages).length > 0) {
      return (
         <ErrorBoundary>
            <IntlProvider messages={messages} locale={'en'} formats={formats}>
               <StylesProvider>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                     <div className={classes.frameStyle}>
                        <AuthenticatorFHG theme={authenticatorTheme} style={{overflow: 'hidden', width: '100%', height: '100%'}}>
                           {(authState) => (
                              <AuthenticatedUser authState={authState}>
                                 <CssBaseline />
                                <Main/>
                              </AuthenticatedUser>
                           )}
                        </AuthenticatorFHG>
                     </div>
                  </LocalizationProvider>
               </StylesProvider>
            </IntlProvider>
         </ErrorBoundary>
      );
   } else {
      return null;
   }
}
