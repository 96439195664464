import React, {useCallback, useEffect, useMemo, useState} from 'react';
import ButtonFHG from '../fhg/components/ButtonFHG';
import Checkbox from '@mui/material/Checkbox';
import EditDrawer from './EditDrawer';
import {formatCurrency} from '../fhg/utils/DataUtil';
import {formatMessage} from '../fhg/utils/Utils';
import {lighten} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import moment from 'moment/moment';
import Stack from '@mui/material/Stack';
import SearchIcon from "@mui/icons-material/Search";
import TableFHG from '../fhg/components/table/TableFHG';
import TextFieldFHG from './TextFieldFHG';
import TypographyFHG from '../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useLazyQueryFHG from '../fhg/hooks/data/useLazyQueryFHG';
import UserBottleEdit from './UserBottleEdit';
import {USER_BOTTLES_SEARCH_QUERY} from '../data/QueriesGL';
import {APPBAR_SMALL_HEIGHT, DATE_FORMAT_KEYBOARD} from '../Constants';

const useStyles = makeStyles({name: 'UserBottlesSearchStyles'})((theme) => ({
  buttonStyle: {
    margin: theme.spacing(2, 2, 1.5, 2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    fontSize: '0.875rem',
    width: '120px',
    backgroundColor: theme.palette.primary.main
  },
  cellStyle: {
    cursor: 'default',
    fontSize: '0.625rem !important',
  },
  elementStyle: {
    margin: theme.spacing(2, 0, 1.25, 2),
    width: '360px'
  },
  headerStyle: {
    fontSize: '0.625rem !important',
    color: theme.palette.primary.contrastText,
  },
  tableContainerStyle: {
    border: "1px solid rgba(128,128,128,0.4)",
    borderRadius: 2,
    display: 'block',
    margin: theme.spacing(0.5, 0.75, 0, 0),
    width: theme.spacing(60),
    overflowX: "auto"
  },
  rightAlign: {
    display: 'block',
    paddingRight: theme.spacing(6),
    textAlign: 'right'
  },
  title: {
    fontSize: '2.0rem',
    margin: theme.spacing(0, 2, 1, 2),
    width: theme.spacing(60),
  }
}));

export default function UserBottlesSearch() {
  const {classes} = useStyles();
  const intl = useIntl();
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedId, setSelectedId] = useState(undefined);
  const [selectedUserId, setSelectedUserId] = useState(undefined);
  const [willingToSell, setWillingToSell] = useState(false);

  const [getSearchData, {data: searchData}] = useLazyQueryFHG(USER_BOTTLES_SEARCH_QUERY, {variables: {searchText, dts: moment().format("MMDDYYYYhhmmsszzz")}}, 'user.bottles.search.type', true);

  useEffect(() => {
    setRows(searchData?.bottles?.items || []);
  },[searchData]);

  const handleSearchChange = useCallback((e) => {
    setSearchText(e.target.value);
  }, [setSearchText]);

  const handleSearchClick = useCallback(() => {
    getSearchData({variables: {searchText}});
  }, [getSearchData, searchText]);

  const getFieldValue = useCallback((value) => {
    let result = value ? value : '????????????';
    if (value && value.trim().length === 0) {
      result = '????????????';
    }
    return result;
  }, []);

  const userBottleColumns = useMemo(() => {
    return [
      {
        id: 'id',
        Header: 'Id',
        headerAlign: 'left',
        accessor: 'id',
        show: false
      },
      {
        id: 'userId',
        Header: 'User Id',
        headerAlign: 'left',
        accessor: 'userId',
        show: false,
      },
      {
        id: 'user',
        Header: 'User',
        headerAlign: 'left',
        accessor: 'user.username'
      },
      {
        id: 'upc',
        Header: 'UPC',
        headerAlign: 'left',
        accessor: 'genericBottle.barcodeUPC',
        Cell: ({value}) => (
          <span>{getFieldValue(value)}</span>
        )
      },
      {
        id: 'brand',
        Header: 'Brand',
        headerAlign: 'left',
        accessor: 'genericBottle.distillery'
      },
      {
        id: 'label',
        Header: 'Label',
        headerAlign: 'left',
        accessor: 'genericBottle.series'
      },
      {
        id: 'type',
        Header: "Type",
        headerAlign: 'left',
        accessor: 'genericBottle.whiskeyType'
      },
      {
        id: 'size',
        Header: 'Size',
        headerAlign: 'left',
        accessor: 'genericBottle.bottleSize'
      },
      {
        id: 'proof',
        Header: 'Proof',
        headerAlign: 'left',
        accessor: 'genericBottle.proof'
      },
      {
        id: 'status',
        Header: 'State',
        headerAlign: 'left',
        accessor: 'status'
      },
      {
        id: 'price',
        Header: 'Price',
        headerAlign: 'right',
        accessor: 'purchasePriceValue',
        Cell: ({value}) => (
          <span className={classes.rightAlign}>{value ? formatCurrency(value) : '$ 0.00'}</span>
        )
      },
      {
        id: 'bottleRating',
        Header: 'Rating',
        headerAlign: 'center',
        accessor: 'bottleRating'
      },
      {
        id: 'date',
        Header: 'Purchased',
        headerAlign: 'center',
        accessor: 'purchaseDate',
        Cell: ({value}) => (
          <span>{value ? moment(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: 'designation',
        Header: 'Special designation',
        headerAlign: 'left',
        accessor: 'specialDesignation'
      },
      {
        id: 'sell',
        Header: 'Sell?',
        headerAlign: 'left',
        accessor: 'willingToSell',
        Cell: ({value}) => (
          value ? <Checkbox size="small" checked /> : <Checkbox size="small" />
        )
      },
      {
        id: 'valuation',
        Header: 'Valuation',
        headerAlign: 'right',
        accessor: 'valuation',
        Cell: ({value}) => (
          <span className={classes.rightAlign}>{value ? formatCurrency(value) : '$ 0.00'}</span>
        )
      }
    ];
  }, [classes.rightAlign, getFieldValue]);

  const getPageCount = useCallback((total) => {
    let results = 0;
    if (total && total > 0) {
      results = Math.ceil(total / pageSize);
    }
    return results;
  }, [pageSize]);

  const handleFlyoutClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleLinkClick = useCallback((value, user, param3) => {
    setSelectedId(value);
    setSelectedUserId(user);
    if (param3 !== undefined) setWillingToSell(param3);
    setOpenDetailDrawer(true);
  }, [setSelectedId, setSelectedUserId, setOpenDetailDrawer, setWillingToSell]);

  const handlePaginationChange = useCallback((obj) => {
    if (pageSize !== obj.pageSize) {
      setPageSize(obj.pageSize);
      setPageIndex(0);
    } else {
      setPageIndex(obj.pageIndex);
    }
  }, [pageSize, setPageIndex, setPageSize]);

  const handlePageChange = useCallback((pageNumber) => {
    setPageIndex(pageNumber);
  }, [setPageIndex]);

  return(
    <Stack display="flex" direction="column" height="100%" >
      <Stack display="flex" direction="row" justifyContent="flex-start" width="100%" >
        <TypographyFHG id="user.bottles.search.title" className={classes.title} />
        <Stack display="flex" direction="row" justifyContent="flex-start" width="100%" >
          <TextFieldFHG
            key={'searchText1'}
            name={'searchText'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'user.bottles.search.text')}
            onChange={handleSearchChange}
            size={'small'}
            value={searchText}
          />
          <ButtonFHG
            className={classes.buttonStyle}
            labelKey="search.button"
            onClick={handleSearchClick}
            size="small"
            variant="contained"
            startIcon={<SearchIcon />}
          />
        </Stack>
      </Stack>
      <TableFHG name="user-bottle-search-table" columns={userBottleColumns} data={rows}
                classesProp={{headerStyle: classes.headerStyle, cellStyle: classes.cellStyle}}
                emptyTableMessageKey={'user.bottles.noRecord.message'}
                dataCount={rows?.length || 0}
                onPageChange={handlePageChange}
                onPaginationChange={handlePaginationChange}
                pageCount={getPageCount(rows ? rows?.length : 0)}
                pageSize={pageSize}
                pageIndex={pageIndex}
                subTitleKey={'user.bottles.subtitle'}
                linkColumn="upc"
                linkColumnId="id"
                linkUserColumnId="userId"
                linkParam3ColumnId="sell"
                onLinkClick={handleLinkClick}
      />
      <EditDrawer
        ModalProps={{BackdropProps: {style: {height: '100%', marginTop: APPBAR_SMALL_HEIGHT}}}}
        open={openDetailDrawer}
        onClose={handleFlyoutClose}
      >
        <Stack display={"flex"} direction={'column'} overflow={'visible'}>
          <UserBottleEdit id={selectedId} userId={selectedUserId} sell={willingToSell} onClose={handleFlyoutClose}  />
        </Stack>
      </EditDrawer>
    </Stack>
  );
};
