import React from 'react';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeftOutlined';
import {Drawer, IconButton} from '@mui/material';
import {EDIT_DRAWER_WIDTH, MOBILE_DRAWER_ADJUST} from '../Constants';
import {useMediaQuery} from '@mui/material';
import useTheme from '@mui/styles/useTheme';

export default function EditDrawer({open = true, onClose, children, ...styleProps}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const FLYOUT_WIDTH = isMobile ? EDIT_DRAWER_WIDTH-MOBILE_DRAWER_ADJUST : EDIT_DRAWER_WIDTH;

  return (
    <Drawer
      anchor={'right'}
      open={open}
      ModalProps={{disableEscapeKeyDown: true}}
      PaperProps={{
        sx: {width: FLYOUT_WIDTH, paddingRight: '8px'},
      }}
    >
      {open && onClose && (
        <IconButton key='close' aria-label='Close' color='inherit'
                    onClick={onClose} size="large" style={{borderRadius: 0, width: 30}}>
          <ChevronLeftIcon />
        </IconButton>
      )}
      {children}
    </Drawer>
  );
}
