import {Close} from '@mui/icons-material';
import {IconButton} from '@mui/material';
import {InputAdornment} from '@mui/material';
import {DatePicker} from '@mui/x-date-pickers';
import moment from 'moment';
import {useEffect} from 'react';
import {useState} from 'react';
import React, {forwardRef} from 'react';
import TextFieldFHG from '../../../components/TextFieldFHG';

/**
 * DatePicker component that handles clearable and default styles.
 * @param name Name of the component.
 * @param value The value of the component.
 * @param onChange Callback when the value changes.
 * @param variant The variant of the TextField.
 * @param disabled If true, the picker and text field are disabled.
 * @param clearable If true, the value can be cleared by pressing the 'X' button.
 * @param helperText The helper text for the TextField
 * @param FormHelperTextProps The FormHelperTextProps for the TextField
 * @param props The other props are passed to the DatePicker.
 * @returns {JSX.Element}
 * @constructor
 */
const DatePickerFHG = forwardRef(function DatePickerFHG(
   {
      name,
      value,
      onChange,
      variant,
      disabled,
      clearable,
      error,
      helperText,
      FormHelperTextProps,
      format,
      isDateOnly = true,
      ...props
   },
   ref
) {
   const [date, setDate] = useState(value);

   useEffect(() => {
      let useDate;

      if (value && format) {
         if (isDateOnly) {
            useDate = moment.utc(value, format);
         } else {
            useDate = moment(value, format);
         }
      } else {
         useDate = value;
      }
      setDate(useDate);
   }, [format, isDateOnly, value]);

   const handleDateChange = (date) => {
      setDate(date);
      onChange(format ? moment(date).format(format) : date, name);
   };
   const ignoreDateChange = (date) => {
      setDate(date);
   };

   const handleClear = () => {
      setDate(null);
      onChange(null, name);
   };

   let inputProps = {};

   if (clearable) {
      inputProps = {
         endAdornment: (
            <InputAdornment key='clear' position='end' style={{width: '20px'}}>
               <IconButton onMouseDown={handleClear} disabled={disabled} size={'small'} edge={'start'}>
                  <Close />
               </IconButton>
            </InputAdornment>
         ),
      };
   }

   return (
    <DatePicker
       name={name}
       ref={ref}
       onAccept={handleDateChange}
       onChange={ignoreDateChange}
       value={date}
       disabled={disabled}
       {...props}
       renderInput={(params) => (
          <TextFieldFHG
             {...params}
             InputProps={{
                ...(params?.InputProps || {}),
                endAdornment: [
                   inputProps.endAdornment,
                   <div key={'defaultEndAdornment'}>{params?.InputProps?.endAdornment}</div>,
                ],
             }}
             error={error}
             FormHelperTextProps={FormHelperTextProps}
             helperText={helperText}
             variant={variant}
             style={props?.style}
          />
       )}
    />
   );
});

export default DatePickerFHG;
