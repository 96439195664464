import React, {useCallback, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from "@mui/material/AlertTitle";
import ArrowUpIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownIcon from '@mui/icons-material/ArrowDownward';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import DatePickerFHG from '../fhg/components/edit/DatePickerFHG';
import Divider from '@mui/material/Divider';
import {lighten} from '@mui/material';
import {Link} from 'react-router-dom';
import {makeStyles} from 'tss-react/mui';
import MinusIcon from '@mui/icons-material/Remove';
import moment from 'moment';
import round from 'lodash/round';
import Stack from '@mui/material/Stack';
import SuggestBottles from './SuggestBottles';
import TypographyFHG from '../fhg/components/Typography';
import useEditData from '../fhg/components/edit/useEditData';
import {useMediaQuery} from '@mui/material';
import useTheme from '@mui/styles/useTheme';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import useLazyQueryFHG from '../fhg/hooks/data/useLazyQueryFHG';
import {
  METRICS_QUERY,
  WISH_LIST_RPT_QUERY
} from '../data/QueriesGL';
import {
  CARD_BOTTLE_VALUE,
  CARD_SUGGEST, CARD_USERS, CARD_WISH,
  DATE_DB_FORMAT, DATE_FORMAT_KEYBOARD,
  GENERIC_SEARCH_PATH, USER_BOTTLES_SEARCH_PATH, USERS_SEARCH_PATH
} from '../Constants';

const useStyles = makeStyles({name: 'WhiskeyDashboardStyles'})((theme) => ({
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1.25, 2, 2, 2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    fontSize: '0.875rem',
    width: '172px'
  },
  calStyle: {
    margin: theme.spacing(1, 0, 0, 0)
  },
  cardStyle: {
    border: 'none',
    cursor: 'pointer',
    margin: theme.spacing(1, 2, 0, 1),
    minWidth: theme.spacing(24),
    textDecoration: 'none'
  },
  cardRow: {
    margin: theme.spacing(1)
  },
  cardSelectedStyle: {
    borderWidth: 'thin',
    borderStyle: 'solid',
    borderColor: theme.palette.primary.main,
    margin: theme.spacing(1, 2, 0, 1),
    minWidth: theme.spacing(24)
  },
  cellStyle: {
    cursor: 'default',
    fontSize: '0.625rem !important',
  },
  cardHeader: {
    fontSize: '0.75rem',
    marginBottom: theme.spacing(1)
  },
  cardFooter: {
    fontSize: '0.75rem',
    margin: theme.spacing(1)
  },
  alertStyle: {
    fontSize: '0.75rem',
    margin: theme.spacing(1)
  },
  cardFooterChange: {
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1)
  },
  cardFooterChangeU: {
    color: 'green',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1)
  },
  cardFooterChangeD: {
    color: 'red',
    fontSize: '0.75rem',
    marginLeft: theme.spacing(1)
  },
   cursorStyle: {
    cursor: 'pointer',
  },
  divStyle: {backgroundColor: theme.palette.primary.main},
  dividerStyle: {
    margin: theme.spacing(0, 2)
  },
  headerStyle: {
    color: theme.palette.primary.contrastText,
    fontSize: '0.625rem !important'
  },
  title: {
    fontSize: '2.0rem',
    margin: theme.spacing(0, 2, 1, 2)
  },
  '.MuiCardContent-root': {
    padding: theme.spacing(1, 1, 0, 1)
  },
 }));

export default function WhiskeyDashboard(){
  const {classes} = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const inlist1 = moment().utc().format(DATE_DB_FORMAT);
  const inlist2 = moment().utc().subtract(1,'d').format(DATE_DB_FORMAT);
  const metricVariables = {
    "listDailyMetricsInput": {
      date: {
        inlist: [inlist1, inlist2]
      }
    }
  };
  const [alertDisplayed, setAlertDisplayed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [selectedCard, setSelectedCard] = useState(CARD_SUGGEST);
  const [metricsData] = useQueryFHG(METRICS_QUERY, {variables: metricVariables}, 'metrics.type');
  const [getRptStatus, {data: rptStatus}] = useLazyQueryFHG(WISH_LIST_RPT_QUERY, {variables: {dts: moment().format("MMDDYYYYhhmmsszzz")}}, 'wishlist.type', true);

  useMemo(() => {
    if (rptStatus && rptStatus.adminEmailReport && !alertDisplayed) {
      setAlertDisplayed(true);
      setOpenAlert(true);
    }
  }, [alertDisplayed, rptStatus, setAlertDisplayed, setOpenAlert]);

  const [handleChange] = useEditData();

  const calcChange = useCallback((oldVal, newVal) => {
    let result = 0;
    if (oldVal === 0 && newVal > 0) {
      result = 100;
    } else if (oldVal && newVal !== oldVal) {
      result = round(((newVal - oldVal) * 100) / oldVal);
    }

    return result;
  }, []);

  const metrics = useMemo(() => {
    let result = {};
    if (metricsData && metricsData.metrics && metricsData.metrics.items && metricsData.metrics.items.length > 0) {
      const bottleCount = metricsData.metrics.items[0].genericBottleCount;
      const y_bottleCount = metricsData.metrics.items[1].genericBottleCount;
      const userBottleCount = metricsData.metrics.items[0].userBottleCount;
      const y_userBottleCount = metricsData.metrics.items[1].userBottleCount;
      const userBottleActiveCount = metricsData.metrics.items[0].userBottleActiveCount;
      const y_userBottleActiveCount = metricsData.metrics.items[1].userBottleActiveCount;
      const bottleValue = metricsData.metrics.items[0].bottleValue;
      const y_bottleValue = metricsData.metrics.items[1].bottleValue;
      const userCount = metricsData.metrics.items[0].userCount;
      const y_userCount = metricsData.metrics.items[1].userCount;
      const freeUserCount = metricsData.metrics.items[0].userCountFree;
      const y_freeUserCount = metricsData.metrics.items[1].userCountFree;
      const premUserCount = metricsData.metrics.items[0].userCountPremium;
      const y_premUserCount = metricsData.metrics.items[1].userCountPremium;
      const userSuggestBottleCount = metricsData.metrics.items[0].userSuggestBottleCount;
      const y_userSuggestBottleCount = metricsData.metrics.items[1].userSuggestBottleCount;
      const userWishlistCount = metricsData.metrics.items[0].userWishlistBottleCount;
      const y_userWishlistCount = metricsData.metrics.items[1].userWishlistBottleCount;

      result = {
        bottleValue: bottleValue,
        y_bottleValue: y_bottleValue,
        bottleValueChange: calcChange(y_bottleValue, bottleValue),
        genericBottleCount: bottleCount,
        y_genericBottleCount: y_bottleCount,
        genericBottleChange: calcChange(y_bottleCount, bottleCount),
        userBottleCount: userBottleCount,
        y_userBottleCount: y_userBottleCount,
        userBottleChange: calcChange(y_userBottleCount, userBottleCount),
        userBottleActiveCount: userBottleActiveCount,
        y_userBottleActiveCount: y_userBottleActiveCount,
        userBottleActiveCountChange: calcChange(y_userBottleActiveCount, userBottleActiveCount),
        userCount: userCount,
        y_userCount: y_userCount,
        userCountChange: calcChange(y_userCount, userCount),
        freeUserCount: freeUserCount,
        y_freeUserCount: y_freeUserCount,
        freeUserCountChange: calcChange(y_freeUserCount, freeUserCount),
        premUserCount: premUserCount,
        y_premUserCount: y_premUserCount,
        premUserCountChange: calcChange(y_premUserCount, premUserCount),
        userSuggestBottleCount: userSuggestBottleCount,
        y_userSuggestBottleCount: y_userSuggestBottleCount,
        userSuggestBottleCountChange: calcChange(y_userSuggestBottleCount, userSuggestBottleCount),
        userWishlistCount: userWishlistCount,
        y_userWishlistCount: y_userWishlistCount,
        userWishlistCountChange: calcChange(y_userWishlistCount, userWishlistCount),
      };
    }
    return result;
  }, [calcChange, metricsData]);

  const getCardFooterClass = useCallback((value) => {
    return value > 0 ? classes.cardFooterChangeU :
      value < 0 ? classes.cardFooterChangeD : classes.cardFooterChange;
  },[classes.cardFooterChange, classes.cardFooterChangeD, classes.cardFooterChangeU]);

  const formatAsPercent = useCallback((num) => {
    return num === undefined ? '0%' : `${num}%`;
  }, []);

  const formatNumberWithCommas = useCallback((num) => {
    let result = 0;
    if (num) {
      result = num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    }
    return result;
  }, []);

  const getCardFooterValue = useCallback((changeValue) => {
    return (
      <Stack direction={'row'} justifyContent={'flex-start'} style={{textAlign: 'top'}}>
        {changeValue > 0 ? <><ArrowUpIcon fontSize="small" />{formatAsPercent(changeValue)}</> :
          changeValue < 0 ? <><ArrowDownIcon fontSize="small" />{formatAsPercent(changeValue)}</> :
            <><MinusIcon fontSize="small" />{formatAsPercent(changeValue)}</>}
      </Stack>
    );
  },[formatAsPercent]);

  const getTable = useCallback(() => {
    if(selectedCard === CARD_SUGGEST ) {
      return (<SuggestBottles />);
    }

      // case CARD_WISH:
      //   return (
      //     <TableFHG name="whiskey-grid-wish" columns={getColumns()} data={stateData}
      //               allowSearch
      //               classesProp={{headerStyle: classes.headerStyle, cellStyle: classes.cellStyle}}
      //               dataCount={wishList?.length || 0}
      //               emptyTableMessageKey={'wishlist.noRecord.message'}
      //               exportExcel
      //               exportFileName="whiskey-shelf-wish-list"
      //               onPageChange={handlePageChange}
      //               onPaginationChange={handlePaginationChange}
      //               pageCount={getPageCount(wishList ? wishList.length : 0)}
      //               pageSize={pageSize}
      //               pageIndex={pageIndex}
      //               subTitleKey={getSubtitle()}
      //               linkColumn="upc"
      //               linkColumnId="id"
      //               linkUserColumnId="userId"
      //               onLinkClick={handleLinkClick}
      //     />
      //   );
  }, [selectedCard]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = null;
    if (openAlert) {
      result = <Alert severity="success" onClose={handleAlertClose} style={{width:'98%'}}>
        <AlertTitle>Success</AlertTitle>
        <TypographyFHG id="wishlist.rpt" className={classes.alertStyle} />
      </Alert>;
     }
    return result;
  }, [classes.alertStyle, handleAlertClose, openAlert]);

  const handleCardClick = useCallback((cardType) => {
    setSelectedCard(cardType);
  }, [setSelectedCard]);

  const handleWishListReportGen = useCallback(() => {
    setAlertDisplayed(false);
    getRptStatus();
  }, [getRptStatus]);

  return(
    <Stack direction="column" display={'flex'} width={'100%'} height={'100%'}>
      <Stack display={'flex'} flex={'0 0 auto'} direction={'column'}>
        <Stack direction={'row'} justify={'space-between'} alignContent={'center'}>
          <Stack  direction={'row'} display={'flex'} flexWrap={'wrap'} >
            <TypographyFHG id="dashboard.title" className={classes.title} />
            {isMobile ? null : (
              <DatePickerFHG
                className={classes.calStyle}
                name={'mainDate'}
                key={'mainDate'}
                format={DATE_FORMAT_KEYBOARD}
                style={{width: 160}}
                onChange={handleChange}
              />)}
          </Stack>
        </Stack>
        {getAlert()}
        <Stack className={classes.cardRow} display={'flex'} flexWrap={'wrap'} direction="row" justify="flex-start">
          <Link to={GENERIC_SEARCH_PATH} style={{textDecoration: 'none'}} >
            <Card className={classes.cardStyle}>
              <CardContent>
                <Stack className={classes.cardHeader}>
                  <TypographyFHG id="card.total.bottles.title" />
                </Stack>
                <TypographyFHG variant="h5">{formatNumberWithCommas(metrics?.genericBottleCount)}</TypographyFHG>
                <Divider className={classes.divStyle} />
                <Stack className={classes.cardFooter} direction="row" justify="flex-start">
                  <TypographyFHG id="card.user.bottles.footer" values={{count: formatNumberWithCommas(metrics?.y_genericBottleCount || 0)}} />
                  <TypographyFHG className={getCardFooterClass(metrics?.genericBottleChange)}>
                    {getCardFooterValue(metrics?.genericBottleChange)}
                  </TypographyFHG>
                </Stack>
              </CardContent>
            </Card>
          </Link>
          <Link to={USER_BOTTLES_SEARCH_PATH} style={{textDecoration: 'none'}} >
            <Card className={classes.cardStyle}>
              <CardContent>
                <Stack className={classes.cardHeader}>
                  <TypographyFHG id="card.user.bottles.title" />
                </Stack>
                <Stack display={"flex"} direction="row" justify="flex-start">
                  <TypographyFHG variant="h5">{formatNumberWithCommas(metrics?.userBottleCount)}</TypographyFHG>
                  <TypographyFHG className={classes.dividerStyle} variant="h5"> / </TypographyFHG>
                  <TypographyFHG variant="h5">{formatNumberWithCommas(metrics?.userBottleActiveCount)}</TypographyFHG>
                </Stack>
                <Divider className={classes.divStyle} />
                <Stack className={classes.cardFooter} display={"flex"} direction="row" justify="flex-start">
                  <TypographyFHG id="card.user.bottles.footer" values={{count: formatNumberWithCommas(metrics?.y_userBottleActiveCount || 0)}} />
                  <TypographyFHG className={getCardFooterClass(metrics?.userBottleActiveCountChange)}>
                    {getCardFooterValue(metrics?.userBottleActiveCountChange)}
                  </TypographyFHG>
                </Stack>
              </CardContent>
            </Card>
          </Link>
          <Card className={(selectedCard === CARD_SUGGEST) ? classes.cardSelectedStyle : classes.cardStyle}>
            <CardActionArea className={classes.cursorStyle} onClick={() => handleCardClick(CARD_SUGGEST)}>
              <CardContent>
                <Stack className={classes.cardHeader}>
                  <TypographyFHG id="card.suggest.title" />
                </Stack>
                <TypographyFHG variant="h5">{formatNumberWithCommas(metrics?.userSuggestBottleCount)}</TypographyFHG>
                <Divider className={classes.divStyle} />
                <Stack className={classes.cardFooter} display={"flex"} direction="row" justify="flex-start">
                  <TypographyFHG id="card.user.bottles.footer" values={{count: formatNumberWithCommas(metrics?.y_userSuggestBottleCount)}} />
                  <TypographyFHG className={getCardFooterClass(metrics?.userSuggestBottleCountChange)}>
                    {getCardFooterValue(metrics?.userSuggestBottleCountChange)}
                  </TypographyFHG>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
          <Card className={(selectedCard === CARD_WISH) ? classes.cardSelectedStyle : classes.cardStyle}>
            <CardActionArea className={classes.cursorStyle} onClick={handleWishListReportGen}>
              <CardContent>
                <Stack className={classes.cardHeader}>
                  <TypographyFHG id="card.wish.title" />
                </Stack>
                <TypographyFHG variant="h5">{formatNumberWithCommas(metrics?.userWishlistCount)}</TypographyFHG>
                <Divider className={classes.divStyle} />
                <Stack className={classes.cardFooter} display={"flex"} direction="row" justify="flex-start">
                  <TypographyFHG id="card.user.bottles.footer" values={{count: formatNumberWithCommas(metrics?.y_userWishlistCount)}} />
                  <TypographyFHG className={getCardFooterClass(metrics?.userWishlistCountChange)}>
                    {getCardFooterValue(metrics?.userWishlistCountChange)}
                  </TypographyFHG>
                </Stack>
              </CardContent>
            </CardActionArea>
          </Card>
          <Link to={USERS_SEARCH_PATH} style={{textDecoration: 'none'}} >
            <Card className={(selectedCard === CARD_USERS) ? classes.cardSelectedStyle : classes.cardStyle}>
              <CardActionArea className={classes.cursorStyle} onClick={() => handleCardClick(CARD_USERS)}>
                <CardContent>
                  <Stack className={classes.cardHeader}>
                    <TypographyFHG id="card.users.title" />
                  </Stack>
                  <Stack display={"flex"} direction="row" justify="flex-start">
                    <TypographyFHG variant="h5">{formatNumberWithCommas(metrics?.userCount)}</TypographyFHG>
                    <TypographyFHG className={classes.dividerStyle} variant="h5"> / </TypographyFHG>
                    <TypographyFHG variant="h5">{formatNumberWithCommas(metrics?.premUserCount)}</TypographyFHG>
                  </Stack>
                  <Divider className={classes.divStyle} />
                  <Stack className={classes.cardFooter} display={"flex"} direction="row" justify="flex-start">
                    <TypographyFHG id="card.user.bottles.footer" values={{count: formatNumberWithCommas(metrics?.y_userCount || 0)}} />
                    <TypographyFHG className={getCardFooterClass(metrics?.userCountChange)}>
                      {getCardFooterValue(metrics?.userCountChange)}
                    </TypographyFHG>
                  </Stack>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
          <Card className={(selectedCard === CARD_BOTTLE_VALUE) ? classes.cardSelectedStyle : classes.cardStyle}>
            <CardContent>
              <Stack className={classes.cardHeader}>
                <TypographyFHG id="card.bottle.value.title" />
              </Stack>
              <TypographyFHG variant="h5">{formatNumberWithCommas(metrics?.bottleValue)}</TypographyFHG>
              <Divider className={classes.divStyle} />
              <Stack className={classes.cardFooter} display={"flex"} direction="row" justify="flex-start">
                <TypographyFHG id="card.user.bottles.footer" values={{count: formatNumberWithCommas(metrics?.y_bottleValue || 0)}} />
                <TypographyFHG className={getCardFooterClass(metrics?.bottleValueChange)}>
                  {getCardFooterValue(metrics?.bottleValueChange)}
                </TypographyFHG>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Stack>
      <Stack flex={'1 1'} width={'100%'} overflow={isMobile ? 'visible' : 'hidden'}>
        {getTable()}
      </Stack>
    </Stack>
  );
}

