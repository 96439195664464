import React, {useCallback, useMemo, useState} from 'react';
import ButtonFHG from '../fhg/components/ButtonFHG';
import Fade from '@mui/material/Fade';
import find from 'lodash/find';
import Form from '../fhg/components/edit/Form';
import {formatMessage, toNumber} from '../fhg/utils/Utils';
import {FormControl, InputAdornment, InputLabel, lighten, OutlinedInput} from '@mui/material';
import Loading from '../fhg/components/Loading';
import {makeStyles} from 'tss-react/mui';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Stack from '@mui/material/Stack';
import TextFieldFHG from './TextFieldFHG';
import TypographyFHG from '../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useMutationFHG from '../fhg/hooks/data/useMutationFHG';
import ProgressButton from '../fhg/components/ProgressButton';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import {BOTTLE_CREATE, DATA_TYPES_QUERY, getBottleRefetchQueries} from '../data/QueriesGL';
import {DATA_TYPES} from '../Constants';

const useStyles = makeStyles({name: 'GenericBottleAddStyles'})((theme) => ({
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(2, 1, 1, 2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    width: '90px'
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    width: '100%',
    height: '100%'
  },
  elementStyle0: {
    margin: theme.spacing(0.75,0, 1.25, 2),
    width: '360px'
  },
  elementStyle: {
    margin: theme.spacing(0.5, 0, 1.25, 2),
    width: '360px'
  },
  labelStyle: {
    backgroundColor: "white",
    color: "gray",
    fontSize: '0.75rem',
    margin: theme.spacing(0,0, -0.25, 3),
    padding: theme.spacing(0, 0.5, 0, 0.5),
    width: theme.spacing(13.5),
    zIndex: "20",
  },
  priceLabelStyle: {
    backgroundColor: "white",
    color: "gray",
    marginLeft: theme.spacing(2),
    width: theme.spacing(5),
  },
  priceStyle: {
    backgroundColor: "white",
    fontSize: '0.75rem',
    height: theme.spacing(4.5),
    margin: theme.spacing(0.75,0, 1.25, 2),
    width: theme.spacing(45)
  },
  adornStyle: {
    fontSize: '0.825rem',
  },
  selectStyle: {
    margin: theme.spacing(-0.75, 0, 2, 2),
    width: '360px'
  },
  titleStyle: {
    margin: theme.spacing(0,0, 1, 2)
  }
}));

export default function GenericBottleAdd(props) {
  const {classes} = useStyles();
  const intl = useIntl();
  const [defaultValues, setDefaultValues] = useState({});
  const [editValues, setEditValues] = useState({});
  const [isChanged, setIsChanged] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [bottleCreate] = useMutationFHG(BOTTLE_CREATE);
  const [bottleSizeData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.BOTTLE_SIZE}}}}, 'data_type.type');
  const [countryData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.COUNTRY_OF_ORIGIN}}}}, 'data_type.type');
  const [whiskeyTypeData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.WHISKEY_TYPE}}}}, 'data_type.type');

  const getDefaultDataValue = useCallback((combo) => {
    let result = '';
    if (combo === DATA_TYPES.BOTTLE_SIZE) {
      let data = bottleSizeData?.data?.items || [];
      result = find(data, b => b.isDefault) || {name: '750ml'};
    } else if (combo === DATA_TYPES.COUNTRY_OF_ORIGIN) {
      let data = countryData?.data?.items || [];
      result = find(data, c => c.isDefault) || {name: 'United States'};
    } else if (combo === DATA_TYPES.WHISKEY_TYPE) {
      let data = whiskeyTypeData?.data?.items || [];
      result = find(data, w => w.isDefault) || {name: 'bourbon'};
    }

    return result;
  }, [bottleSizeData, countryData, whiskeyTypeData]);

  const bottleSizes = useMemo(() => {
    let result = [];
    if (bottleSizeData && bottleSizeData.data && bottleSizeData.data.items) {
      let data = bottleSizeData.data.items;
      data?.forEach(b => {
        if (b.isValid) {
          result.push(b.name);
        }
      });
      setDefaultValues(editValues => ({...editValues, bottleSize: getDefaultDataValue(DATA_TYPES.BOTTLE_SIZE)?.name}));
    }
    return result;
  }, [bottleSizeData, getDefaultDataValue, setDefaultValues]);
  const countries = useMemo(() => {
    let result = [];
    if (countryData && countryData.data && countryData.data.items) {
      let data = countryData.data.items;
      data?.forEach(b => {
        if (b.isValid) {
          result.push(b.name);
        }
      });
      setDefaultValues(editValues => ({...editValues, countryOfOrigin: getDefaultDataValue(DATA_TYPES.COUNTRY_OF_ORIGIN)?.name}));
    }
    return result;
  }, [countryData, getDefaultDataValue, setDefaultValues]);
  const whiskeyTypes = useMemo(() => {
    let result = [];
    if (whiskeyTypeData && whiskeyTypeData.data && whiskeyTypeData.data.items) {
      let data = whiskeyTypeData.data.items;
      data?.forEach(b => {
        if (b.isValid) {
          result.push(b.name);
        }
      });
      setDefaultValues(editValues => ({...editValues, whiskeyType: getDefaultDataValue(DATA_TYPES.WHISKEY_TYPE)?.name}));
    }
    return result;
  }, [whiskeyTypeData, getDefaultDataValue, setDefaultValues]);

  const handleClose = useCallback(() => {
    props?.onClose();
  }, [props]);

  const handleSubmit = useCallback(async () => {
    if (isChanged) {
      try {
        setIsSaving(true);
        let currentItem = {
          ...defaultValues,
          ...editValues
        };

        let createbottleinput = {
          barcodeUPC: currentItem?.barcodeUPC,
          bottleSize: currentItem?.bottleSize,
          countryOfOrigin: currentItem?.countryOfOrigin,
          description: currentItem?.description,
          distillery: currentItem?.distillery,
          proof: currentItem && currentItem.proof ? toNumber(currentItem.proof, true) : null,
          series: currentItem?.series,
          yearBottled: currentItem && currentItem.yearBottled ? toNumber(currentItem.yearBottled, true) : null,
          whiskeyType: currentItem?.whiskeyType,
          manualBottleRating: currentItem?.manualBottleRating ? toNumber(currentItem.manualBottleRating, true) : null,
          manualBottlePurchasePrice: currentItem?.manualBottlePurchasePrice ? toNumber(currentItem.manualBottlePurchasePrice, true) : null
        };

        await bottleCreate({
          variables: {createbottleinput},
          refetchQueries: getBottleRefetchQueries()
        });
        setIsChanged(false);
        handleClose();
      } catch (e) {
        console.log(e);
      } finally {
        setIsSaving(false);
      }
    } else {
      handleClose();
    }
  }, [bottleCreate, defaultValues, editValues, handleClose, isChanged, setIsChanged]);

  const handleElementChange = useCallback((e) => {
    const {target: {name, value}} = e;
    setEditValues(editValues => ({...editValues, [name]: value}));
    setIsChanged(true);
  }, [setEditValues, setIsChanged]);

  const handleBottleSizeChange = useCallback((event, value) => {
    setEditValues(editValues => ({...editValues, bottleSize: value?.props?.value}));
    setIsChanged(true);
  }, [setEditValues, setIsChanged]);

  const handleCountryChange = useCallback((event, value) => {
    setEditValues(editValues => ({...editValues, countryOfOrigin: value?.props?.value}));
    setIsChanged(true);
  }, [setEditValues, setIsChanged]);

  const handleWhiskeyTypeChange = useCallback((event, value) => {
    setEditValues(editValues => ({...editValues, whiskeyType: value?.props?.value}));
    setIsChanged(true);
  }, [setEditValues, setIsChanged]);

  const isReady = true;

  return (
    <Fade in={isReady} mountOnEnter>
      <Form onSubmit={handleSubmit}>
        <Loading isLoading={!isReady} />
        <Stack className={classes.formStyle}>
          <TypographyFHG id="bottle.add.title"  className={classes.titleStyle} variant="h6" />
          <TextFieldFHG
            key={'barcodeUPC'+defaultValues.id}
            name={'barcodeUPC'}
            autoFocus
            className={classes.elementStyle0}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.upc.label')}
            onChange={handleElementChange}
            required
            value={editValues?.barcodeUPC}
          />
          <TextFieldFHG
            key={'distillery'+defaultValues.id}
            name={'distillery'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.brand.label')}
            onChange={handleElementChange}
            required
            value={editValues?.distillery}
          />
          <TextFieldFHG
            key={'series'+defaultValues.id}
            name={'series'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.label.label')}
            onChange={handleElementChange}
            required
            value={editValues?.series}
          />
          <Stack direction="column" display="flex">
            <TypographyFHG className={classes.labelStyle}>{formatMessage(intl, 'bottle.add.bottleSize.label')}</TypographyFHG>
            <Select
              key={'bottleSize'+defaultValues.id}
              name={'bottleSize'}
              defaultValue={getDefaultDataValue(DATA_TYPES.BOTTLE_SIZE)?.name}
              placeholder={formatMessage(intl, 'bottle.add.bottleSize.label')}
              className={classes.selectStyle}
              onChange={handleBottleSizeChange}
              sx={{height: 34, fontSize: '0.875rem', paddingTop: 0.5}}
            >{bottleSizes?.map(b => {
              return (<MenuItem sx={{fontSize: '0.75rem'}} value={b}>{b}</MenuItem>);
            })}
            </Select>
          </Stack>
          <Stack direction="column" display="flex">
            <TypographyFHG className={classes.labelStyle}>{formatMessage(intl, 'bottle.add.whiskeyType.label')}</TypographyFHG>
            <Select
              key={'whiskeyType'+defaultValues.id}
              name={'whiskeyType'}
              defaultValue={getDefaultDataValue(DATA_TYPES.WHISKEY_TYPE)?.name}
              placeholder={formatMessage(intl, 'bottle.add.whiskeyType.label')}
              className={classes.selectStyle}
              onChange={handleWhiskeyTypeChange}
              sx={{height: 34, fontSize: '0.875rem', paddingTop: 0.5}}
            >{whiskeyTypes?.map(w => {
              return (<MenuItem sx={{fontSize: '0.75rem'}} value={w}>{w}</MenuItem>);
            })}
            </Select>
          </Stack>
          <TextFieldFHG
            key={'proof'+defaultValues.id}
            name={'proof'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.proof.label')}
            onChange={handleElementChange}
            required
            type="number"
            value={editValues?.proof}
          />
          <FormControl className={classes.priceStyle} sx={{ m: 1 }} size="small">
            <InputLabel htmlFor="outlined-adornment-amount" className={classes.priceLabelStyle}>Price</InputLabel>
            <OutlinedInput
              key={'manualBottlePurchasePrice'+editValues.id}
              name={'manualBottlePurchasePrice'}
              error={editValues?.manualBottlePurchasePrice < 0}
              helperText={ editValues?.manualBottlePurchasePrice < 0 ? 'Min. 0' : '' }
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.875rem'}, min: 0}}
              label={formatMessage(intl, 'bottle.add.manualBottlePurchasePrice.label')}
              onChange={handleElementChange}
              size={'small'}
              startAdornment={<InputAdornment position="start"><span className={classes.adornStyle}>$</span></InputAdornment>}
              value={editValues?.manualBottlePurchasePrice}
            />
          </FormControl>
          <TextFieldFHG
            key={'manualBottleRating'+defaultValues.id}
            name={'manualBottleRating'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}, min: 0, max: 100}}
            label={formatMessage(intl, 'bottle.add.manualBottleRating.label')}
            onChange={handleElementChange}
            size={'small'}
            type="number"
            value={editValues?.manualBottleRating}
          />
          <Stack direction="column" display="flex">
            <TypographyFHG className={classes.labelStyle}>{formatMessage(intl, 'bottle.add.country.label')}</TypographyFHG>
            <Select
              key={'countryOfOrigin'+defaultValues.id}
              name={'countryOfOrigin'}
              defaultValue={getDefaultDataValue(DATA_TYPES.COUNTRY_OF_ORIGIN)?.name}
              placeholder={formatMessage(intl, 'bottle.add.country.label')}
              className={classes.selectStyle}
              onChange={handleCountryChange}
              sx={{height: 34, fontSize: '0.875rem', paddingTop: 0.5}}
            >{countries?.map(c => {
              return (<MenuItem sx={{fontSize: '0.875rem'}} value={c}>{c}</MenuItem>);
            })}
            </Select>
          </Stack>
          <TextFieldFHG
            key={'yearBottled'+defaultValues.id}
            name={'yearBottled'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.year.label')}
            onChange={handleElementChange}
            type="number"
            value={editValues?.yearBottled}
          />
          <TextFieldFHG
            key={'description'+defaultValues.id}
            name={'description'}
            className={classes.elementStyle}
            columns={3}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.desc.label')}
            onChange={handleElementChange}
            multiline
            rows="2"
            value={editValues?.description}
          />
          <Stack display={'flex'} direction="row" >
            <ProgressButton
              className={classes.buttonStyle}
              isProgress={isSaving}
              disabled={isSaving}
              labelKey='create.button'
              size="small"
              type={'submit'}
              variant="contained"
            />
            <ButtonFHG
              className={classes.buttonStyle}
              disabled={isSaving}
              labelKey="cancel.button"
              onClick={handleClose}
              size="small"
              variant="contained"
            />
          </Stack>
        </Stack>
      </Form>
    </Fade>
  );
}
