import React, {useState, useRef} from 'react';
import {Fragment} from 'react';
import {useCallback} from 'react';
import useEffect from '../../hooks/useEffect';
import {useMemo} from 'react';
import {formatMessage, toNumber} from '../../utils/Utils';
import {Link} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import map from 'lodash/map';
import {matchSorter} from 'match-sorter';
import Papa from 'papaparse';
import PropTypes from 'prop-types';
import {Stack} from '@mui/material';
import {Table as MuiTable, TableHead} from '@mui/material';
import {TableBody} from '@mui/material';
import {TableContainer} from '@mui/material';
import {TableCell} from '@mui/material';
import {TablePagination} from '@mui/material';
import TablePaginationActions from './TablePaginationActions';
import {TableRow} from '@mui/material';
import TableSearchToolbar from './TableSearchToolbar';
import {TableSortLabel} from '@mui/material';
import TextFieldFHG from '../../../components/TextFieldFHG';
import TypographyFHG from '../Typography';
import {useExportData} from 'react-table-plugins';
import {useGlobalFilter, useSortBy, useTable, usePagination} from 'react-table';
import {useIntl} from 'react-intl';
import useTheme from '@mui/material/styles/useTheme';
import * as XLSX from 'xlsx';
// import JsPDF from 'jspdf';
// import 'jspdf-autotable';

export const PAGE_SIZE_DEFAULT = 50;
const EMPTY_DATA = [];

function fuzzyTextFilterFn(rows, id, filterValue) {
   return matchSorter(rows, filterValue, {keys: [(row) => row.values[id]]});
}

// Let the table remove the filter if the string is empty
fuzzyTextFilterFn.autoRemove = (val) => !val;

// Create an editable cell renderer
const EditableCell = ({
   cell: {value: initialValue},
   row: {index},
   column: {id, isEditable},
   updateMyData, // This is a custom function that we supplied to our table instance
}) => {
   // We need to keep and update the state of the cell normally
   const [value, setValue] = React.useState(initialValue);
   const [showEdit, setShowEdit] = React.useState(false);

   const onChange = (e) => {
      setValue(e.target.value);
   };

   const handleClick = () => {
      setShowEdit(isEditable);
   };

   /**
    * Update the external data when the input is blurred.
    */
   const onBlur = () => {
      updateMyData(index, id, value);
      setShowEdit(false);
   };

   /**
    * If the initialValue is changed external, sync it up with our state.
    */
   useEffect(() => {
      setValue(initialValue);
      setShowEdit(false);
   }, [initialValue]);

   if (showEdit) {
      return <TextFieldFHG withLabel={false} value={value} onChange={onChange} onBlur={onBlur} autoFocus />;
   } else {
      return <div onDoubleClick={handleClick}>{value}</div>;
   }
};

EditableCell.propTypes = {
   cell: PropTypes.shape({
      value: PropTypes.any,
   }),
   row: PropTypes.shape({
      index: PropTypes.number.isRequired,
   }),
   column: PropTypes.shape({
      id: PropTypes.string.isRequired,
   }),
   updateMyData: PropTypes.func,
};

const useStyles = makeStyles({name: 'TableFHGStyles'})((theme) => ({
   headerStyle: {
      whiteSpace: 'nowrap',
      padding: '2px 8px',
      fontSize: 16,
      cursor: 'pointer',
      textAlign: 'center',
   },
   rowStyle: {
      '& tr:nth-of-type(odd):not(.Mui-selected)': {
         backgroundColor: '#fafafa',
      },
      '& tr:nth-of-type(even):not(.Mui-selected)': {
         backgroundColor: 'white',
      },
   },
   cellStyle: {
      whiteSpace: 'nowrap',
      padding: '4px 8px 2px',
      fontSize: 18,
      '&.editable': {
         color: 'black',
      },
      '&:hover.editable': {
         backgroundColor: '#f0f6e9',
         cursor: 'pointer',
      },
      '&:hover:not(.editable)': {
         backgroundColor: '#f0f0f0',
         cursor: 'default',
      },
   },
   cellFooterStyle: {
      whiteSpace: 'nowrap',
      padding: '8px 8px 4px',
      fontSize: 18,
      borderRight: '2px solid rgba(0, 0, 0, 0.12)',
   },
   selected: {
      backgroundColor: theme.palette.action.selected,
   },
   stickyExternal: {
      overflow: 'unset',
      backgroundColor: 'inherit',
   },
   stickyFrame: {
      overflow: 'unset',
      '& table': {
         '& thead > tr': {
            position: 'sticky',
            left: 0,
            top: 0,
         },
         '& tbody > tr, tfoot > tr': {
            position: 'sticky',
            left: 0,
         },
         '& tfoot > tr > td': {
            backgroundColor: 'white !important',
         },
         '& td:first-of-type': {
            position: 'sticky',
            left: 0,
            zIndex: theme.zIndex.modal - 1,
            backgroundColor: 'inherit',
         },
         '& th:first-of-type': {
            position: 'sticky',
            left: 0,
            zIndex: theme.zIndex.modal - 1,
            backgroundColor: 'inherit',
         },
         '& .MuiToolbar-root': {
            backgroundColor: '#FAFAFA',
            width: '100%',
            position: 'sticky',
            top: 0,
            zIndex: 4,
         },
         totalFooter: {
            position: 'sticky',
            right: 10,
         },
         '& .MuiTableCell-stickyHeader': {
            top: 62,
         },
      },
   },
   paginationStyle: {
      '& .MuiTablePagination-spacer': {
         flex: '0 0 auto',
      },
   },
}));

// Set our editable cell renderer as the default Cell renderer
// const defaultColumn = {
//    Cell: EditableCell,
// };

function autofitColumns2(json, header) {
   let objectMaxLength = [];

   for (let j = 0; j < header.length; j++) {
      objectMaxLength[j] = objectMaxLength[j] >= header[j]?.length + 2 ? objectMaxLength[j] : header[j]?.length + 2;
   }
   for (let i = 0; i < json.length; i++) {
      let value = Object.values(json[i]);
      for (let j = 0; j < value.length; j++) {
         let valueLength;

         if (typeof value[j] == 'number') {
            valueLength = 10;
         } else {
            valueLength = (value[j]?.length || 0) + 2; // give space on either side.
         }
         objectMaxLength[j] = objectMaxLength[j] >= valueLength ? objectMaxLength[j] : valueLength;
      }
   }

   return objectMaxLength.map((width) => ({width}));
}

function getExportFileBlob({columns, data, fileType, fileName}, tabName) {
   if (fileType === 'csv') {
      // CSV example
      const headerNames = columns.map((col) => col.exportValue);
      const csvString = Papa.unparse({fields: headerNames, data});
      return new Blob([csvString], {type: 'text/csv'});
   } else if (fileType === 'xlsx') {
      const header = map(columns, 'exportValue');
      const compatibleData = data.map((row) => {
         const obj = {};
         header.forEach((col, index) => {
            obj[col] = row[index];
         });
         return obj;
      });

      let wb = XLSX.utils.book_new();
      let ws1 = XLSX.utils.json_to_sheet(compatibleData, {
         header,
      });
      ws1['!cols'] = autofitColumns2(compatibleData, header, ws1);

      XLSX.utils.book_append_sheet(wb, ws1, tabName || 'Table Data');
      XLSX.writeFile(wb, `${fileName}.xlsx`, {bookType: 'xlsx', type: 'buffer'});

      // Returning false as downloading of file is already taken care of
      return false;
   }
   //PDF example
   if (fileType === 'pdf') {
      // const headerNames = columns.map((column) => column.exportValue);
      // const doc = new JsPDF();
      // doc.autoTable({
      //    head: [headerNames],
      //    body: data,
      //    margin: {top: 20},
      //    styles: {
      //       minCellHeight: 9,
      //       halign: 'left',
      //       valign: 'center',
      //       fontSize: 11,
      //    },
      // });
      // doc.save(`${fileName}.pdf`);
      //
      // return false;
   }

   // Other formats goes here
   return false;
}

/**
 * The table component that handles searching (filtering) and selection.
 *
 * Reviewed: 4/14/20
 *
 * @param titleKey The message key for the title.
 * @param title The title for the table. Used as the default if a titleKey is specified.
 * @param columns The columns for the table.
 * @param data The data for the table.
 * @param updateMyData  Callback when a cell is edited.
 * @param autoResetPage Indicates that the page should be automatically reset.
 * @param onSelect Callback when an item is selected.
 * @param selectId The current selection item ID.
 * @param searchFilter The current search filter for external search.
 * @param allowSearch Indicates if a search should be shown for the table.
 * @param exportCSV Indicates if the table can be exported to CSV. Defaults to false.
 * @param exportPDF Indicates if the table can be exported to PDF. Defaults to false.
 * @param exportExcel Indicates if the table can be exported to Excel. Defaults to false.
 * @param exportFileName The name of the file to be exported.
 * @param exportTabName The name of the tab in the file to be exported.
 * @param stickyHeader Indicates if the table should have a sticky header.
 * @param classesProp Style classes to override the default table classes.
 * @param onDoubleClick Callback when a row is doubled-clicked.
 * @param globalFilterParam The filter string for the table.
 * @param emptyTableMessageKey The message key for the empty table message.
 * @param isLoading Indicates if the data for the table is being loaded.
 * @param onPaginationChange Callback for a pagination event.
 * @param controlledPageSize The page size for the table pagination.
 * @param controlledPageIndex The current index of pages for the table data.
 * @param controlledPageCount The total number of pages of table data.
 * @param dataCount The total number of rows in the table.
 * @param children The children shown in the search bar.
 * @return {*}
 * @constructor
 */
export default function TableFHG({
   titleKey,
   title,
   columns,
   data = EMPTY_DATA,
   updateMyData,
   autoResetPage,
   onSelect,
   // selectId,
   searchFilter,
   allowSearch,
   exportCSV,
   exportPDF,
   exportExcel,
   exportFileName,
   exportTabName,
   stickyHeader = true,
   classes: classesProp,
   onDoubleClick,
   globalFilter: globalFilterParam,
   emptyTableMessageKey,
   isLoading,
   onPaginationChange,
   pageSize: controlledPageSize,
   pageIndex: controlledPageIndex,
   pageCount: controlledPageCount,
   dataCount,
   children,
   subTitleKey,
   linkColumn,
   linkColumnId,
   linkUserColumnId,
   linkParam3ColumnId,
   onLinkClick
}) {
   // Use the state and functions returned from useTable to build your UI
   // noinspection JSUnusedGlobalSymbols
   const {
      getTableProps,
      headerGroups,
      prepareRow,
      rows,
      page,
      gotoPage,
      setPageSize,
      preGlobalFilteredRows,
      setGlobalFilter,
      state: {globalFilter, pageIndex, pageSize},
      exportData,
   } = useTable(
      {
         columns,
         data,
         initialState: {pageIndex: controlledPageIndex, pageSize: controlledPageSize}, // Pass our hoisted table state
         // manualPagination: true, // Tell the usePagination
         // hook that we'll handle our own data fetching
         // This means we'll also have to provide our own
         // pageCount.
         pageCount: controlledPageCount,
         pageIndex: controlledPageIndex,

         // defaultColumn,
         getExportFileBlob: (options) => getExportFileBlob(options, exportTabName),
         getExportFileName: () => exportFileName,
         globalFilter: globalFilterParam,
         autoResetPage,
         // updateMyData isn't part of the API, but
         // anything we put into these options will
         // automatically be available on the instance.
         // That way we can call this function from our
         // cell renderer!
         updateMyData,
      },
      useGlobalFilter,
      useSortBy,
      useExportData,
      usePagination
   );
   const intl = useIntl();
   const theme = useTheme();
   const [selectedIndex, setSelectedIndex] = useState(-1);
   const selectRef = useRef();
   const { classes } = {...useStyles(), ...(classesProp || {})};

   /**
    * Handles keydown events for arrow up and arrow down. Moves the selection for the rows.
    */
   useEffect(() => {
      function handleKeyDown(event) {
         let newIndex;
         switch (event.code) {
            case 'ArrowDown':
               newIndex = (selectedIndex + 1) % rows.length;
               break;
            case 'ArrowUp':
               newIndex = (selectedIndex - 1 + rows.length) % rows.length;
               break;
            default:
            //Do nothing
         }
         if (rows && rows.length > newIndex && rows[newIndex].original) {
            onSelect && onSelect(rows[newIndex].original, newIndex, event);
         }
      }

      document.addEventListener('keydown', handleKeyDown, false);

      // Cleanup the listener when this component is removed.
      return () => {
         document.removeEventListener('keydown', handleKeyDown, false);
      };
   }, [rows, selectedIndex, onSelect]);

   /**
    * Set the global filter from the search filter when the search filter changes.
    */
   useEffect(() => {
      if (searchFilter !== undefined) {
         setGlobalFilter(searchFilter);
      }
   }, [searchFilter, setGlobalFilter]);

   // Listen for changes in pagination and use the state to fetch our new data
   useEffect(() => {
      onPaginationChange?.({pageIndex, pageSize});
   }, [onPaginationChange, pageIndex, pageSize]);

   /**
    * Select the row when the selectId changes.
    */
   // useEffect(() => {
   //    if (!!selectId) {
   //       const selectedIndex = rows.findIndex((row) => {
   //          return row.original.id === selectId;
   //       });
   //       setSelectedIndex(selectedIndex);
   //       defer(() => {
   //          if (selectRef.current) {
   //             let element = ReactDOM.findDOMNode(selectRef.current);
   //             element.scrollIntoViewIfNeeded && element.scrollIntoViewIfNeeded();
   //          }
   //       });
   //    } else {
   //       // setSelectedIndex(-1);
   //    }
   // }, [rows, selectId]);
   //

   const handleChangePage = (event, newPage) => {
      gotoPage(newPage);
   };

   const handleChangeRowsPerPage = (event) => {
      setPageSize(Number(event.target.value));
   };

   /**
    * Select the row on click.
    * @param row The row clicked to be selected.
    * @return {function(...[*]=)}
    */
   const handleRowClick = useCallback(
      (row) => (event) => {
         setSelectedIndex(row.index);
         onSelect && onSelect(row.original, row.index, event);
      },
      [onSelect]
   );

   /**
    * Select the row on click.
    * @param row The row clicked to be selected.
    * @return {function(...[*]=)}
    */
   const handleRowDoubleClick = useCallback(
      (row) => (event) => {
         onDoubleClick?.(row.original, row.index, event);
      },
      [onDoubleClick]
   );
   const handleLinkClick = useCallback((value, user, param3) => {
      if (onLinkClick) {
         onLinkClick(value, user, param3);
      }
   }, [onLinkClick]);

   const toolbar = useMemo(() => {
      let subtitle = `${formatMessage(intl, subTitleKey)} (${toNumber(dataCount)})`
      return (
         <TableSearchToolbar
            titleKey={titleKey}
            title={title}
            preGlobalFilteredRows={preGlobalFilteredRows}
            setGlobalFilter={setGlobalFilter}
            globalFilter={globalFilter}
            exportCSV={exportCSV}
            exportPDF={exportPDF}
            exportExcel={exportExcel}
            exportData={exportData}
            subtitle={subtitle}
         >
            {children}
         </TableSearchToolbar>
      );
   }, [
      children,
      exportCSV,
      exportData,
      exportExcel,
      exportPDF,
      globalFilter,
      preGlobalFilteredRows,
      setGlobalFilter,
      title,
      titleKey,
      dataCount,
      intl,
      subTitleKey
   ]);

   const tableHeader = useMemo(() => {
      return (
        <TableHead>
           {headerGroups.map((headerGroup) => (
             <TableRow {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <Fragment key={'column ' + index}>
                     {column.show !== false && (
                       <TableCell
                         {...(column.id === 'selection'
                           ? column.getHeaderProps({className: classes.headerStyle})
                           : column.getHeaderProps(
                             column.getSortByToggleProps({className: classes.headerStyle})
                           ))}
                         style={{
                            backgroundColor: '#FFD580',
                            whiteSpace: 'nowrap',
                            padding: '2px 8px',
                            fontSize: 14,
                            cursor: 'pointer',
                            fontWeight: 'bold',
                            textAlign: 'left'
                         }}
                       >
                          {column.render('Header')}
                          {column.id !== 'selection' ? (
                            <TableSortLabel
                              active={column.isSorted}
                              // react-table has an unsorted state which is not treated here
                              direction={column.isSortedDesc ? 'desc' : 'asc'}
                            />
                          ) : null}
                       </TableCell>
                     )}
                  </Fragment>
                ))}
             </TableRow>
           ))}
        </TableHead>
      );
   }, [classes.headerStyle, headerGroups]);

   return (
      <Stack
         name={'TableFHG Root Grid'}
         direction={'column'}
         // height={'100%'}
         width={'100%'}
         wrap={'nowrap'}
         overflow={'hidden'}
      >
         {(allowSearch && toolbar)}
         <TableContainer style={{flex: '1 1'}}>
            <MuiTable {...getTableProps()} stickyHeader={stickyHeader}>
               {tableHeader}
               <TableBody className={classes.rowStyle}>
                  {page.map((row, i) => {
                     prepareRow(row);
                     return (
                        <TableRow
                           {...row.getRowProps()}
                           onClick={handleRowClick(row)}
                           onDoubleClick={onDoubleClick ? handleRowDoubleClick(row) : undefined}
                           hover
                           selected={i === selectedIndex}
                           ref={i === selectedIndex ? selectRef : undefined}
                        >
                           {row.cells.map((cell) => {
                              if (cell?.column?.show === false) {
                                 return null;
                              } else {
                                 return cell.column.id === linkColumn ?
                                   (<TableCell {...cell.getCellProps()} className={classes.cellStyle}
                                               style={{'& .MuiTableCellRoot': {padding: 0}, whiteSpace: 'nowrap', fontSize: 14, cursor: 'pointer'}}>
                                      {<Link className={classes.cursorStyle} underline="always" variant="inherit" onClick={() => handleLinkClick(row.values[linkColumnId], row.values[linkUserColumnId], row.values[linkParam3ColumnId])}>{cell.render('Cell')}</Link>}
                                   </TableCell>) :
                                   (<TableCell {...cell.getCellProps()} className={classes.cellStyle}
                                               style={{'& .MuiTableCellRoot': {padding: 0}, whiteSpace: 'nowrap', fontSize: 14}}>
                                      {cell.render('Cell')}
                                   </TableCell>);
                              }
                           })}
                        </TableRow>
                     );
                  })}
               </TableBody>
            </MuiTable>
            {rows?.length <= 0 && !isLoading && emptyTableMessageKey && (
               <Stack display={"flex"} justify={'center'} style={{margin: theme.spacing(2)}}>
                  <TypographyFHG
                     variant='body2'
                     id={emptyTableMessageKey}
                     color={'textPrimary'}
                     style={{marginLeft: 'auto', marginRight: 'auto'}}
                  />
               </Stack>
            )}
         </TableContainer>
         {pageSize && dataCount > 25 && (
            <table key={'pagination table'}>
               <tbody>
                  <tr>
                     <TablePagination
                        className={classes.paginationStyle}
                        style={{flex: '0 0 auto'}}
                        rowsPerPageOptions={[25, 50, 100, 250, 500]}
                        count={dataCount}
                        rowsPerPage={pageSize}
                        page={pageIndex}
                        SelectProps={{
                           inputProps: {'aria-label': 'rows per page'},
                           native: true,
                        }}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                        onPageChange={handleChangePage}
                        variant="outlined"
                     />
                  </tr>
               </tbody>
            </table>
         )}
      </Stack>
   );
}
