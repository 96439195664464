import PropTypes from 'prop-types';
import useMessage from '../utils/useMessage';
import usePrompt from './usePrompt';

useMessage.propTypes = {
   messageKey: PropTypes.string, // The id of the localized message.
   defaultMessage: PropTypes.string, // The default message if not localized.
   values: PropTypes.array, // Values to insert in the localized message.
};

export default function usePromptFHG({when, messageKey = 'leavePage', message: messageProp, values}) {
   const message = useMessage(messageKey, messageProp, values);

   return usePrompt(message, when);
}
