// Dates displayed to the user or to match the DB format.
export const DATE_FORMAT_KEYBOARD = 'MM/DD/YYYY';
export const DATETIME_FORMAT_KEYBOARD = 'MM/DD/YYYY hh:mm a';
export const DATE_FORMAT_OUPUT = 'YYYY-MM-DDTHH:mm:ss';
export const MONTH_FORMAT = 'MM/YYYY';
// export const MONTH_ONLY_FORMAT = 'MMM';
// export const YEAR_FORMAT = 'YYYY';
// export const DATE_TIME_FORMAT = 'M/D/YYYY hh:mm a';
export const DATE_DB_FORMAT = 'YYYY-MM-DD';
// export const CURRENCY_FORMAT = '$#,###,###,##0.';
// export const CURRENCY_FULL_FORMAT = '$#,###,###,##0.00';
export const POSITION_DATE = 'MMM-YYYY';

export const ERROR_COLOR = '#AA0B06';
export const WARNING_COLOR = '#F5CD19';
export const SUCCESS_COLOR = '#5C9E52';

export const colors = [
   '#EDBA2E',
   '#336699',
   '#A3C7E4',
   '#007373',
   '#000000',
   '#2170B8',
   '#DC2A30CB',
   '#6A8E9A',
   '#008080',
   '#B521B8',
   '#CFB047',
   '#939393',
   '#3B8AD2',
   '#707070',
];

// export const COLLAPSE_TIMEOUT = 500;
export const UNDO_DURATION = 4000;

export const ENDPOINT = 'https://api.test.ws2.whiskeyshelf.app/graphql/v1';
export const ENDPOINT_PROD = 'https://api.ws2.whiskeyshelf.app/graphql/v1';

export const APPBAR_SMALL_HEIGHT = 0;
export const EDIT_DRAWER_WIDTH = 400;
export const MOBILE_DRAWER_ADJUST = 10;
export const ADMIN_DRAWER = 125;
export const FLYOUT_EL_WIDTH = 350;

export const LOGO = '/whiskey-logo.jpg';

export const SCROLL_DELTA = 50;
export const HORIZONTAL_DIRECTION = 'row';
export const VERTICAL_DIRECTION = 'column';

// Route list
export const DEFAULT_PATH = '/';
export const ADMIN_PATH = 'admin';
export const WHISKEY_PATH = 'whiskey';
export const WHISKEY_FULL_PATH = `${ADMIN_PATH}/${WHISKEY_PATH}`;
export const SEARCH_PATH = '/search';
export const NOTIFICATION_PATH = '/notification';
export const SIGN_OUT_PATH = 'signout';
export const ADMIN_GROUP = 'ws-dashboard-admin';

export const GENERIC_SEARCH_PATH = `${ADMIN_PATH}/generic-bottles`;
export const USER_BOTTLES_SEARCH_PATH = `${ADMIN_PATH}/user-bottles`;
export const USERS_SEARCH_PATH = `${ADMIN_PATH}/users`;

// Card types
export const CARD_SHELF = 'shelf';
export const CARD_USER_BOTTLES = 'user-bottles';
export const CARD_USERS = 'users;'
export const CARD_SUGGEST = 'suggest';
export const CARD_WISH = 'wish';
export const CARD_BOTTLE_VALUE = 'value';
export const CARD_USER_BOTTLE = 'user-bottle;'

export const SUB_FREE = "FREE";
export const SUB_PREM = "PREMIUM";
export const ACTION_ADD = "add";
export const ACTION_EDIT = "edit";
export const ACTION_SUGGEST = "suggest";
export const ACTION_USER = "user";
export const ACTION_WISH = "wish";
export const ACTION_USER_BOTTLE = 'user-bottle';
export const USER_BOTTLE_STATUS = [
   {key: 'NEW', value: 'NEW'},
   {key: 'OPENED_NECK', value: 'OPENED NECK'},
   {key: 'OPENED_SHOULDERS', value: 'OPENED SHOULDERS'},
   {key: 'OPENED_BODY', value: 'OPENED BODY'},
   {key: 'EMPTY', value: 'EMPTY'},
   {key: 'TRADED', value: 'TRADED'},
   {key: 'GIFTED', value: 'GIFTED'}
];

export const DATA_TYPES = {
   "BOTTLE_SIZE": "BOTTLE_SIZE",
   "BOTTLE_STATUS": "BOTTLE_STATUS",
   "COUNTRY_OF_ORIGIN": "COUNTRY_OF_ORIGIN",
   "TERRITORIES": "TERRITORIES",
   "WHISKEY_TYPE": "WHISKEY_TYPE",
};

export const APPROVAL_ACCEPT = 'ACCEPT';
export const APPROVAL_REJECT = 'REJECT';

export const USER_BOTTLE_TEMPLATE_APPROVAL = 'USER_BOTTLE_SUGGEST_APPROVAL';
export const USER_BOTTLE_TEMPLATE_REJECT = 'USER_BOTTLE_SUGGEST_REJECT';

export const NO_BARCODE = 'No UPC Code';
export const NO_BARCODE_LIST = ['000000000000'];
