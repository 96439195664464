// eslint-disable-next-line
import {CircularProgress} from '@mui/material';
// eslint-disable-next-line no-unused-vars
import {Button, ButtonProps} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import PropTypes from 'prop-types';
import React from 'react';
import Typography from './Typography';

const useStyles = makeStyles({name: 'ProgressButtonStyles'})((theme) => ({
   spinnerMargin: {
      marginLeft: theme.spacing(0.5),
      color: 'white',
   },
   darkSpinnerMargin: {
      marginLeft: theme.spacing(0.5),
      color: theme.palette.primary.main,
   },
}));

/**
 * Button Component to show progress.
 *
 * Reviewed:
 */
ProgressButton.propTypes = {
   isProgress: PropTypes.bool.isRequired, //Indicates if the progress should be showing.
   labelKey: PropTypes.string, // Localization key for the button label.
   typographyProps: PropTypes.any, // The properties for the typography component.
   ...Button.propTypes,
};

/**
 *
 * @param isProgress
 * @param labelKey
 * @param isSpinnerDark Indicates if the spinner should be dark.
 * @param children
 * @param typographyProps
 * @param buttonProperties {ButtonProps}
 * @return {JSX.Element}
 * @constructor
 */
export default function ProgressButton({
   isProgress = false,
   labelKey,
   isSpinnerLight = false,
   children,
   typographyProps,
   ...buttonProperties
}) {
   const { classes } = useStyles();

   return (
      <Button {...buttonProperties}>
         {labelKey && <Typography variant={'inherit'} id={labelKey} {...typographyProps} />}
         {children}
         {isProgress && (
            <CircularProgress
               className={
                  buttonProperties?.classes?.spinnerMargin ||
                  (!isSpinnerLight ? classes.darkSpinnerMargin : classes.spinnerMargin)
               }
               size={15}
               thickness={2.5}
            />
         )}
      </Button>
   );
}
