import React, {useCallback, useMemo, useState} from 'react';
import ButtonFHG from '../fhg/components/ButtonFHG';
import Checkbox from '@mui/material/Checkbox';
import {formatCurrency} from '../fhg/utils/DataUtil';
import {lighten} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import moment from 'moment';
import Stack from '@mui/material/Stack';
import TableFHG from '../fhg/components/table/TableFHG';
import TypographyFHG from '../fhg/components/Typography';
import {useMediaQuery} from '@mui/material';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import useTheme from '@mui/styles/useTheme';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {BOTTLE_USER_SEARCH_QUERY} from '../data/QueriesGL';
import {DATE_FORMAT_KEYBOARD} from '../Constants';

const useStyles = makeStyles({name: 'UserSearchStyles'})((theme) => ({
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(1.25, 2, 2, 2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    fontSize: '0.875rem',
    width: '50px'
  },
  cellStyle: {
    cursor: 'default',
    fontSize: '0.625rem !important',
  },
  headerStyle: {
    color: theme.palette.primary.contrastText,
    fontSize: '0.625rem !important'
  },
  '& .MuiTableCell-root': {
    padding: theme.spacing(0.25, 0)
  },
  tableStyle: {
    marginTop: theme.spacing(1)
  },
  title: {
    fontSize: '2.0rem',
    margin: theme.spacing(0, 2, 1, 2)
  }
}));

export default function UserSearch() {
  const {classes} = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const userId = searchParams.get('id');
  const userBottleVariables = {
    "listUserBottlesInput": {
      "userId": {eq: userId}
    }
  };
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [userBottleData] = useQueryFHG(BOTTLE_USER_SEARCH_QUERY, {variables: userBottleVariables}, 'user.bottles.type');
  const userBottleList = useMemo(() => userBottleData && userBottleData.bottles ? userBottleData.bottles?.items : [], [userBottleData]);
  const userBottleColumns = useMemo(() => {
    return [
      {
        id: 'id',
        Header: 'Id',
        headerAlign: 'left',
        accessor: 'id',
        show: false
      },
      {
        id: 'user',
        Header: 'User',
        headerAlign: 'left',
        accessor: 'user.username'
      },
      {
        id: 'upc',
        Header: 'UPC',
        headerAlign: 'left',
        accessor: 'genericBottle.barcodeUPC'
      },
      {
        id: 'brand',
        Header: 'Brand',
        headerAlign: 'left',
        accessor: 'genericBottle.distillery'
      },
      {
        id: 'label',
        Header: 'Label',
        headerAlign: 'left',
        accessor: 'genericBottle.series'
      },
      {
        id: 'type',
        Header: "Type",
        headerAlign: 'left',
        accessor: 'genericBottle.whiskeyType'
      },
      {
        id: 'size',
        Header: 'Size',
        headerAlign: 'left',
        accessor: 'genericBottle.bottleSize'
      },
      {
        id: 'proof',
        Header: 'Proof',
        headerAlign: 'left',
        accessor: 'genericBottle.proof'
      },
      {
        id: 'status',
        Header: 'State',
        headerAlign: 'left',
        accessor: 'status'
      },
      {
        id: 'price',
        Header: 'Price',
        headerAlign: 'right',
        accessor: 'purchasePriceValue',
        Cell: ({value}) => (
          <span className={classes.rightAlign}>{value ? formatCurrency(value) : '$ 0.00'}</span>
        )
      },
      {
        id: 'bottleRating',
        Header: 'Rating',
        headerAlign: 'center',
        accessor: 'bottleRating'
      },
      {
        id: 'date',
        Header: 'Purchased',
        headerAlign: 'center',
        accessor: 'purchaseDate',
        Cell: ({value}) => (
          <span>{value ? moment(value).format(DATE_FORMAT_KEYBOARD) : ''}</span>
        )
      },
      {
        id: 'designation',
        Header: 'Special designation',
        headerAlign: 'left',
        accessor: 'specialDesignation'
      },
      {
        id: 'sell',
        Header: 'Sell?',
        headerAlign: 'left',
        accessor: 'willingToSell',
        Cell: ({value}) => (
          value ? <Checkbox size="small" checked /> : <Checkbox size="small" />
        )
      },
      {
        id: 'valuation',
        Header: 'Valuation',
        headerAlign: 'right',
        accessor: 'valuation',
        Cell: ({value}) => (
          <span className={classes.rightAlign}>{value ? formatCurrency(value) : '$ 0.00'}</span>
        )
      }
    ];
  }, [classes]);

  const getPageCount = useCallback((array) => {
    let results = 0;
    if (array && array.length > 0) {
      results = Math.ceil(array.length / pageSize);
    }
    return results;
  }, [pageSize]);

  const handlePaginationChange = useCallback((obj) => {
    setPageIndex(obj.pageIndex);
    setPageSize(obj.pageSize);
  }, [setPageIndex, setPageSize]);

  const handlePageChange = useCallback((pageNumber) => {
    setPageIndex(pageNumber);
  }, [setPageIndex]);

  const handleBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  return (
    <Stack flex={'1 1'} width={'100%'} overflow={isMobile ? 'visible' : 'hidden'}>
      <Stack display="flex" direction="row" justifyContent="flex-start" width="100%" >
        <TypographyFHG id="user.search.title" className={classes.title} />
        <ButtonFHG
          className={classes.buttonStyle}
          labelKey="back.button"
          onClick={handleBack}
          size="small"
          variant="contained"
        />
      </Stack>
      <TableFHG name="whiskey-grid-user-bottle" columns={userBottleColumns} data={userBottleList}
                allowSearch
                classesProp={{headerStyle: classes.headerStyle, cellStyle: classes.cellStyle}}
                dataCount={userBottleList?.length || 0}
                emptyTableMessageKey={'user.bottles.noRecord.message'}
                exportExcel
                exportFileName="whiskey-shelf-user-bottles"
                onPageChange={handlePageChange}
                onPaginationChange={handlePaginationChange}
                pageCount={getPageCount(userBottleList)}
                pageSize={pageSize}
                pageIndex={pageIndex}
                subTitleKey={'user.bottles.subtitle'}
      />
    </Stack>
);
}