import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from "@mui/material/AlertTitle";
import AddIcon from '@mui/icons-material/Add';
import ButtonFHG from '../fhg/components/ButtonFHG';
import DownloadIcon from '@mui/icons-material/Download';
import EditDrawer from './EditDrawer';
import {formatMessage} from '../fhg/utils/Utils';
import GenericBottleAdd from './GenericBottleAdd';
import GenericBottleEdit from './GenericBottleEdit';
import {lighten} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import moment from 'moment/moment';
import Stack from '@mui/material/Stack';
import SearchIcon from "@mui/icons-material/Search";
import TableFHG from '../fhg/components/table/TableFHG';
import TextFieldFHG from './TextFieldFHG';
import TypographyFHG from '../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useLazyQueryFHG from '../fhg/hooks/data/useLazyQueryFHG';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import {BOTTLE_SEARCH_QUERY, GENERIC_BOTTLE_RPT_QUERY} from '../data/QueriesGL';
import {ACTION_ADD, ACTION_EDIT, APPBAR_SMALL_HEIGHT} from '../Constants';

const useStyles = makeStyles({name: 'GenericSearchStyles'})((theme) => ({
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(2, 2, 1.5, 2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    fontSize: '0.875rem',
    width: '120px'
  },
  buttonStyle2: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(2, 2, 1.5, 2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    fontSize: '0.875rem',
    width: '150px'
  },
  cellStyle: {
    cursor: 'default',
    fontSize: '0.625rem !important',
  },
  elementStyle: {
    margin: theme.spacing(2, 0, 1.25, 2),
    width: '360px'
  },
  headerStyle: {
    color: theme.palette.primary.contrastText,
    fontSize: '0.625rem !important'
  },
  tableContainerStyle: {
    border: "1px solid rgba(128,128,128,0.4)",
    borderRadius: 2,
    display: 'block',
    margin: theme.spacing(0.5, 0.75, 0, 0),
    width: theme.spacing(60),
    overflowX: "auto"
  },
  title: {
    fontSize: '2.0rem',
    margin: theme.spacing(0, 2, 1, 2),
    width: theme.spacing(60),
  }
}));

export default function GenericSearch() {
  const {classes} = useStyles();
  const intl = useIntl();

  const [alertDisplayed, setAlertDisplayed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [drawerAction, setDrawerAction] = useState(ACTION_EDIT);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedId, setSelectedId] = useState(undefined);

  const [getSearchData, {data: searchData}] = useLazyQueryFHG(BOTTLE_SEARCH_QUERY, {variables: {searchText, dts: moment().format("MMDDYYYYhhmmsszzz")}}, 'search.bottles.type', true);
  const [getRptStatus, {data: rptStatus}] = useLazyQueryFHG(GENERIC_BOTTLE_RPT_QUERY, {variables: {dts: moment().format("MMDDYYYYhhmmsszzz")}}, 'generic.rpt.button', true);

  useMemo(() => {
    if (rptStatus && rptStatus.adminEmailReport && !alertDisplayed) {
      setAlertDisplayed(true);
      setOpenAlert(true);
    }
  }, [alertDisplayed, rptStatus, setAlertDisplayed, setOpenAlert]);

  useEffect(() => {
    setRows(searchData?.listBottles?.items || []);
  },[searchData]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = null;
    if (openAlert) {
      result = <Alert severity="success" onClose={handleAlertClose} style={{width:'98%'}}>
        <AlertTitle>Success</AlertTitle>
        <TypographyFHG id="wishlist.rpt" className={classes.alertStyle} />
      </Alert>;
     }
    return result;
  }, [classes.alertStyle, handleAlertClose, openAlert]);


  const handleAddBottle = useCallback(() => {
    setDrawerAction(ACTION_ADD);
    setOpenDetailDrawer(true);
  }, [setDrawerAction, setOpenDetailDrawer]);

  const handleSearchChange = useCallback((e) => {
    setSearchText(e.target.value);
  }, [setSearchText]);

  const handleSearchClick = useCallback(() => {
    getSearchData({variables: {searchText}});
  }, [getSearchData, searchText]);

  const handleGenericReportGen = useCallback(() => {
    setAlertDisplayed(false);
    getRptStatus();
  }, [getRptStatus]);

  const getFieldValue = useCallback((value) => {
    let result = value ? value : '????????????';
    if (value && value.trim().length === 0) {
      result = '????????????';
    }
    return result;
  }, []);

  const handleFlyoutClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const handleLinkClick = useCallback((id) => {
    setSelectedId(id);
    setDrawerAction(ACTION_EDIT);
    setOpenDetailDrawer(true);
  }, []);

  const getFlyoutDetail = useCallback(() => {
    switch (drawerAction) {
    case ACTION_ADD:
      return (<GenericBottleAdd onClose={handleFlyoutClose} />);
    default:
      return (<GenericBottleEdit id={selectedId} onClose={handleFlyoutClose} />);
    }
  }, [drawerAction, handleFlyoutClose, selectedId]);

const columns = useMemo(() => {
    return [
      {
        id: 'id',
        Header: 'Id',
        headerAlign: 'left',
        accessor: 'id',
        show: false,
      },
      {
        id: 'upc',
        Header: 'UPC',
        headerAlign: 'left',
        accessor: 'barcodeUPC',
        Cell: ({row}) => (
          <span>{getFieldValue(row.original.barcodeUPC)}</span>
        )
      },
      {
        id: 'brand',
        Header: 'Brand',
        headerAlign: 'left',
        accessor: 'distillery',
      },
      {
        id: 'label',
        Header: 'Label',
        headerAlign: 'left',
        accessor: 'series',
      },
      {
        id: 'type',
        Header: "Type",
        headerAlign: 'left',
        accessor: 'whiskeyType',
      },
      {
        id: 'size',
        Header: 'Size',
        headerAlign: 'left',
        accessor: 'bottleSize',
      },
      {
        id: 'proof',
        Header: 'Proof',
        headerAlign: 'left',
        accessor: 'proof',
      },
      {
        id: 'manualBottlePurchasePrice',
        Header: 'Price',
        headerAlign: 'left',
        accessor: 'manualBottlePurchasePrice',
      },
      {
        id: 'calculatedBottlePurchasePrice',
        Header: 'CPrice',
        headerAlign: 'left',
        accessor: 'calculatedBottlePurchasePrice',
      },
      {
        id: 'manualBottleRating',
        Header: 'Rate',
        headerAlign: 'left',
        accessor: 'manualBottleRating',
      },
      {
        id: 'calculatedBottleRating',
        Header: 'CRate',
        headerAlign: 'left',
        accessor: 'calculatedBottleRating',
      },
      {
        id: 'countryOfOrigin',
        Header: 'Country',
        headerAlign: 'left',
        accessor: 'countryOfOrigin',
      }
    ];
  }, [getFieldValue]);

  const getPageCount = useCallback((total) => {
    let results = 0;
    if (total && total > 0) {
      results = Math.ceil(total / pageSize);
    }
    return results;
  }, [pageSize]);

  const handlePaginationChange = useCallback((obj) => {
    if (pageSize !== obj.pageSize) {
      setPageSize(obj.pageSize);
      setPageIndex(0);
    } else {
      setPageIndex(obj.pageIndex);
    }
  }, [pageSize, setPageIndex, setPageSize]);

  const handlePageChange = useCallback((pageNumber) => {
    setPageIndex(pageNumber);
  }, [setPageIndex]);

  return(
    <Stack display="flex" direction="column" height="100%" >
      {getAlert()}
      <Stack display="flex" direction="row" justifyContent="flex-start" width="100%" >
        <TypographyFHG id="generic.search.title" className={classes.title} />
        <Stack display="flex" direction="row" justifyContent="flex-start" width="100%" >
          <TextFieldFHG
            key={'searchText1'}
            name={'searchText'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'generic.search.text')}
            onChange={handleSearchChange}
            size={'small'}
            value={searchText}
          />
          <ButtonFHG
            className={classes.buttonStyle}
            labelKey="search.button"
            onClick={handleSearchClick}
            size="small"
            variant="contained"
            startIcon={<SearchIcon />}
          />
          <ButtonFHG
            className={classes.buttonStyle}
            labelKey="generic.rpt.button"
            onClick={handleGenericReportGen}
            size="small"
            variant="contained"
            startIcon={<DownloadIcon />}
          />

          <ButtonFHG className={classes.buttonStyle2} labelKey="btn.add.bottle" startIcon={<AddIcon />} onClick={handleAddBottle} size="small" variant="contained" />
        </Stack>
      </Stack>
      <TableFHG name="generic-search-table" columns={columns} data={rows}
                classesProp={{headerStyle: classes.headerStyle, cellStyle: classes.cellStyle}}
                dataCount={rows?.length || 0}
                emptyTableMessageKey={'bottles.noRecord.message'}
                exportExcel
                exportFileName="whiskey-shelf-bottles"
                onPageChange={handlePageChange}
                onPaginationChange={handlePaginationChange}
                pageCount={getPageCount(rows ? rows?.length : 0)}
                pageSize={pageSize}
                pageIndex={pageIndex}
                subTitleKey={'generic.subtitle'}
                linkColumn="upc"
                linkColumnId="id"
                onLinkClick={handleLinkClick}
      />
      <EditDrawer
        ModalProps={{BackdropProps: {style: {height: '100%', marginTop: APPBAR_SMALL_HEIGHT}}}}
        open={openDetailDrawer}
        onClose={handleFlyoutClose}
      >
        <Stack display={"flex"} direction={'column'} overflow={'visible'}>
          {getFlyoutDetail()}
        </Stack>
      </EditDrawer>
    </Stack>
  );
};