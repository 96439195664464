import {AmplifyProvider} from '@aws-amplify/ui-react';
import {Authenticator} from '@aws-amplify/ui-react';
import {I18n} from 'aws-amplify';
import {Image} from '@aws-amplify/ui-react';
import {Text} from '@aws-amplify/ui-react';
import {useTheme} from '@aws-amplify/ui-react';
import {View} from '@aws-amplify/ui-react';
import './Authenticator.css';
import {LOGO} from '../../../Constants';

I18n.putVocabulariesForLanguage('en', {
   Username: 'Enter your username', // Username label
   Password: 'Enter your password', // Password label
});

// noinspection JSUnusedGlobalSymbols
const defaultComponents = {
   Header() {
      const {tokens} = useTheme();

      return (
         <View
            textAlign='center'
            style={{marginLeft: 'auto', marginRight: 'auto'}}
            padding={tokens.space.large}
            className={'amplify-view'}
            width={'100%'}
            height={'100%'}
         >
            <Image
               alt='logo'
               src={LOGO}
               width={{small: '140px', large: '260px'}}
               // height={{small: '140px', large: '200px'}}
            />
         </View>
      );
   },

   Footer() {
      const {tokens} = useTheme();

      return (
         <View textAlign='center' padding={tokens.space.large}>
            <Text color={`${tokens.colors.neutral['80']}`}>&copy; All Rights Reserved</Text>
         </View>
      );
   },
};

/**
 * Authenticator for the app. The children won't be displayed until the user has logged in.
 *
 * @param theme The theme for the AWS authentication.
 * @param components The components for the AWS authentication.
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
export default function AuthenticatorFHG({theme, components = defaultComponents, children}) {
   return (
      <AmplifyProvider theme={theme}>
         <Authenticator components={components}>{children}</Authenticator>
      </AmplifyProvider>
   );
}
