// import makeStyles from '@material-ui/core/styles/makeStyles';
import {TextField} from '@mui/material';
import isEqual from 'lodash/isEqual';
import {useMemo} from 'react';
import React from 'react';
// import PropTypes from 'prop-types';
import useMessage from '../../hooks/useMessage';

import PropTypes from 'prop-types';
import {IMaskInput} from 'react-imask';

export const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
   const {mask, onChange, ...other} = props;

   function handleAccept(value) {
      if (!isEqual(value, props.value)) {
         onChange?.({target: {name: props.name, value}});
      }
   }

   return (
      <IMaskInput
         {...other}
         mask={mask}
         definitions={{
            '#': /[1-9]/,
         }}
         inputRef={ref}
         onAccept={handleAccept}
         overwrite
      />
   );
});

TextMaskCustom.propTypes = {
   name: PropTypes.string.isRequired,
   onChange: PropTypes.func.isRequired,
};
// const useStyles = makeStyles(theme => ({}), {name: 'PhoneNumberFieldFHGStyles'});

PhoneNumberFieldFHG.propTypes = {};

export default function PhoneNumberFieldFHG({
   name,
   label,
   labelKey,
   variant,
   labelTemplate,
   placeholderKey,
   defaultValue,
   value,
   editData,
   onChange,
   ...phoneFieldProps
}) {
   const useLabelKey = useMemo(() => labelKey || labelTemplate?.format({name}), [name, labelTemplate, labelKey]);
   const currentLabel = useMessage(useLabelKey, label);
   const currentPlaceholder = useMessage(placeholderKey) || undefined;

   return (
      <TextField
         {...phoneFieldProps}
         key={name}
         // ref={ref}
         name={name}
         label={currentLabel}
         placeholder={currentPlaceholder}
         defaultValue={defaultValue}
         value={value}
         onChange={onChange}
         inputProps={{mask: '(000) 000-0000'}}
         InputProps={{
            inputComponent: TextMaskCustom,
            style: {fontSize: '0.825rem'}
         }}
      />
   );
}
