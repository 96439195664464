import {Backdrop, CircularProgress} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import PropTypes from 'prop-types';
import React from 'react';

const useStyles = makeStyles({name: 'loadingStyles'})(() => ({
   backdropStyle: {
      zIndex: 4000,
      color: '#fff',
      opacity: '0.2 !important',
   },
   progressStyle: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      zIndex: 5000,
   },
}));

/**
 * Component to show the error messages and loading spinner.
 *
 * Note:
 *    Message is the default property in values.
 */
function Loading({isLoading = true, hasBackdrop = false, classes: classesProp = {}, ...progressProps}) {
   const { classes } = {...useStyles(), ...classesProp};

   if (isLoading) {
      if (hasBackdrop) {
         return (
            <>
               <CircularProgress className={classes.progressStyle} />
               <Backdrop className={classes.backdropStyle} open={true} />
            </>
         );
      } else {
         return <CircularProgress className={classes.progressStyle} {...progressProps} />;
      }
   } else {
      return null;
   }
}

Loading.propTypes = {
   isLoading: PropTypes.bool, // Indicates if the data is still loading.
   hasBackdrop: PropTypes.bool, // Indicates if the backdrop should display.
};

export default Loading;
