import gql from 'graphql-tag';
import moment from 'moment/moment';
import {CREATE_UPDATE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {DELETE_ACTION} from '../fhg/hooks/data/useMutationFHG';
import {DATE_DB_FORMAT} from '../Constants';

export const BOTTLE_ALL_QUERY = gql`
   query listBottles {
      listBottles(limit: 100) {
         items {
            id
            barcodeUPC
            bottleSize
            countryOfOrigin
            description
            distillery
            proof
            series
            yearBottled
            whiskeyType
            manualBottleRating
            calculatedBottleRating
            manualBottlePurchasePrice
            calculatedBottlePurchasePrice
         }
      }
   }
`;

export const BOTTLE_SEARCH_QUERY = gql`
   query listBottles($searchText: String) {
      listBottles(limit: 500, nextToken: "", filter: {allFields: {
         contains: $searchText
      }}) {
         nextToken
         items {
            id
            barcodeUPC
            bottleSize
            countryOfOrigin
            description
            distillery
            proof
            series
            yearBottled
            whiskeyType
            manualBottleRating
            calculatedBottleRating
            manualBottlePurchasePrice
            calculatedBottlePurchasePrice
         }
      }
   }
`;

export const BOTTLE_BY_ID_QUERY = gql`
   query getBottleById($id: ID!) {
      bottle1: getBottle(id: $id) {
         id
         barcodeUPC
         bottleSize
         countryOfOrigin
         description
         distillery
         proof
         series
         yearBottled
         whiskeyType
         manualBottleRating
         calculatedBottleRating
         manualBottlePurchasePrice
         calculatedBottlePurchasePrice
      }
   }
`;

export const BOTTLE_CREATE = {
   mutation: gql`
      mutation createBottle($createbottleinput: CreateBottleInput!) {
         bottle: createBottle(input: $createbottleinput) {
            id
            barcodeUPC
            bottleSize
            countryOfOrigin
            description
            distillery
            proof
            series
            yearBottled
            whiskeyType
            manualBottleRating
            calculatedBottleRating
            manualBottlePurchasePrice
            calculatedBottlePurchasePrice
        }
      }
   `,
   typeKey: 'bottle.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const BOTTLE_UPDATE = {
   mutation: gql`
      mutation updateBottle($updatebottleinput: UpdateBottleInput!) {
         bottle: updateBottle(input: $updatebottleinput) {
            id
            barcodeUPC
            bottleSize
            countryOfOrigin
            description
            distillery
            proof
            series
            yearBottled
            whiskeyType
            manualBottleRating
            calculatedBottleRating
            manualBottlePurchasePrice
            calculatedBottlePurchasePrice
         }
      }
   `,
   typeKey: 'bottle.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const BOTTLE_DELETE = {
   mutation: gql`
      mutation deleteBottle($input: DeleteBottleInput!) {
         deleteBottle(input: $input) {
            id
            barcodeUPC
            bottleSize
            countryOfOrigin
            description
            distillery
            proof
            series
            yearBottled
            whiskeyType
         }
      }
   `,
   typeKey: 'bottle.type',
   actionKey: DELETE_ACTION,
};

export const getBottleRefetchQueries = () => {
   const inlist1 = moment().utc().format(DATE_DB_FORMAT);
   const inlist2 = moment().utc().subtract(1,'d').format(DATE_DB_FORMAT);
   const metricVariables = {
      "listDailyMetricsInput": {
         date: {
            inlist: [inlist1, inlist2]
         }
      }
   };

   return [
      {query: BOTTLE_ALL_QUERY, variables: {}, queryPath: 'listBottles'},
      {query: METRICS_QUERY, variables: metricVariables, queryPath: 'metrics'}
   ];
};

export const BOTTLE_USER_SEARCH_QUERY = gql`
   query listUserBottles($listUserBottlesInput: TableUserBottleFilterInput!) {
      bottles: listUserBottles(filter: $listUserBottlesInput, limit: 10000) {
         items {
            id
            userId
            genericBottleId
            genericBottle {
               barcodeUPC
               bottleSize
               countryOfOrigin
               description
               distillery
               id
               proof
               series
               yearBottled
               whiskeyType
            }
            specialDesignation
            storageLocation
            purchaseStore
            purchasePriceValue
            purchasePriceCurrency
            purchaseDate
            status
            willingToSell
            user {
               email
               username
            }
            valuation
            bottleRating
         }
      }
   }
`;

export const BOTTLE_USER_ALL_QUERY = gql`
   query listUserBottles($limit: Int) {
      bottles: listUserBottles(includeAll: false, limit: $limit, nextPage: "") {
         nextPage
         items {
            id
            userId
            genericBottleId
            genericBottle {
               barcodeUPC
               bottleSize
               countryOfOrigin
               description
               distillery
               id
               proof
               series
               yearBottled
               whiskeyType
            }
            specialDesignation
            storageLocation
            purchaseStore
            purchasePriceValue
            purchasePriceCurrency
            purchaseDate
            status
            willingToSell
            user {
               email
               username
            }
            valuation
            bottleRating
         }
      }
   }
`;

export const USER_BOTTLE_NEXT_QUERY = gql`
   query listBottles($limit: Int, $nextPage: String) {
      bottles: listUserBottles(limit: $limit, nextPage: $nextPage) {
         nextPage
         items {
            id
            userId
            genericBottleId
            genericBottle {
               barcodeUPC
               bottleSize
               countryOfOrigin
               description
               distillery
               id
               proof
               series
               yearBottled
               whiskeyType
            }
            specialDesignation
            storageLocation
            purchaseStore
            purchasePriceValue
            purchasePriceCurrency
            purchaseDate
            status
            willingToSell
            user {
               email
               username
            }
            valuation
            bottleRating
         }
      }
   }
`;

export const USER_BOTTLES_SEARCH_QUERY = gql`
   query listUserBottles($searchText: String) {
      bottles: listUserBottles(limit: 500, filter: {allFields: {
         contains: $searchText
      }}) {
         items {
            id
            userId
            genericBottleId
            genericBottle {
               barcodeUPC
               bottleSize
               countryOfOrigin
               description
               distillery
               id
               proof
               series
               yearBottled
               whiskeyType
            }
            specialDesignation
            storageLocation
            purchaseStore
            purchasePriceValue
            purchasePriceCurrency
            purchaseDate
            status
            willingToSell
            user {
               email
               username
            }
            valuation
            bottleRating
         }
      }
   }
`;

export const USER_BOTTLE_BY_ID_QUERY = gql`
   query getUserBottleById($id: ID!, $userId: ID!) {
      data: getUserBottle(userBottleId: $id, userId: $userId) {
         id
         userId
         genericBottleId
         genericBottle {
            barcodeUPC
            bottleSize
            countryOfOrigin
            description
            distillery
            id
            proof
            series
            yearBottled
            whiskeyType
         }
         specialDesignation
         storageLocation
         purchaseStore
         purchasePriceValue
         purchasePriceCurrency
         purchaseDate
         status
         willingToSell
         user {
            email
            username
         }
         valuation
         bottleRating
      }
   }
`;

export const USER_BOTTLE_UPDATE = {
   mutation: gql`
      mutation updateUserBottle($updateInput: UpdateUserBottleInput!) {
         bottle: updateUserBottle(input: $updateInput) {
            id
            userId
            genericBottleId
            genericBottle {
               barcodeUPC
               bottleSize
               countryOfOrigin
               description
               distillery
               id
               proof
               series
               yearBottled
               whiskeyType
            }
            specialDesignation
            storageLocation
            purchaseStore
            purchasePriceValue
            purchasePriceCurrency
            purchaseDate
            status
            willingToSell
            user {
               email
               username
            }
            valuation
         }
      }
   `,
   typeKey: 'user.bottle.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const getUserBottleCacheQueries = () => {
   return [{query: BOTTLE_USER_ALL_QUERY, variables: {}, queryPath: 'bottles'}];
};

export const getUserBottleRefetchQueries = () => {
   const inlist1 = moment().utc().format(DATE_DB_FORMAT);
   const inlist2 = moment().utc().subtract(1,'d').format(DATE_DB_FORMAT);
   const metricVariables = {
      "listDailyMetricsInput": {
         date: {
            inlist: [inlist1, inlist2]
         }
      }
   };

   return [
      {query: BOTTLE_USER_ALL_QUERY, variables: {}, queryPath: 'listBottles'},
      {query: METRICS_QUERY, variables: metricVariables, queryPath: 'metrics'}
   ];
};

export const DATA_TYPES_QUERY = gql`
   query listDataTypes($filter: TableDataTypeFilterInput!) {
      data: listDataTypes(filter: $filter) {
         items {
            id
            name
            category
            isDefault
            isValid
         }
         nextToken
      }
   }
`;

export const NOTIFICATION_LIST_QUERY = gql`
   query listNotificationTemplates {
      templates: listNotificationTemplates {
         items {
            id
            templateType
            description
            content
         }
      }
   }
`;

export const NOTIFICATION_MESSAGE_QUERY = gql`
   query getNotificationTemplate($filter: TableNotificationTemplateFilterInput!) {
      template: getNotificationTemplate(filter: $filter) {
         id
         templateType
         description
         content
         placeholders
      }
   }
`;

export const NOTIFICATION_TEMPLATE_UPDATE = {
   mutation: gql`
      mutation updateNotificationTemplate($input: UpdateNotificationTemplateInput!) {
         updateNotificationTemplate(input: $input) {
            id
            templateType
            description
            content
         }
      }
   `,
   typeKey: 'notification.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const getTemplateRefetchQueries = (acceptId, rejectId) => {
   return [
      {query: NOTIFICATION_LIST_QUERY, variables: {filter: {id: {"eq": acceptId}}}, queryPath: 'templates'},
      {query: NOTIFICATION_LIST_QUERY, variables: {filter: {id: {"eq": rejectId}}}, queryPath: 'templates'}
   ];
};

export const METRICS_QUERY = gql`
   query listDailyMetrics($listDailyMetricsInput: TableDailyMetricsFilterInput!) {
      metrics: listDailyMetrics(filter: $listDailyMetricsInput) {
         items {
            date
            userCount
            userCountFree
            userCountPremium
            userBottleCount
            userBottleActiveCount
            bottleValue
            genericBottleCount
            userSuggestBottleCount
            userWishlistBottleCount
         }
      }
   }
`;

// SUGGESTIONS
export const SUGGEST_ALL_QUERY = gql`
   query listUserSuggestBottles {
      bottles: listUserSuggestBottles(includeAll: true, limit: 10000) {
         nextToken
         items {
            id
            userId
            user {
               id
               email
               username
            }
            whiskeyType
            distillery
            series
            yearBottled
            barcodeUPC
            batch
            bottleSize
            countryOfOrigin
            description
            proof
         }
      }
   }
`;

export const SUGGEST_BY_ID_QUERY = gql`
   query getSuggestById($id: ID!, $userId: ID!) {
      bottle1: getUserSuggestBottle(userSuggestBottleId: $id, userId: $userId) {
         id
         userId
         user {
            id
            email
            username
         }
         whiskeyType
         distillery
         series
         yearBottled
         barcodeUPC
         batch
         bottleSize
         countryOfOrigin
         description
         proof
      }
   }
`;

export const SUGGEST_APPROVE = {
   mutation: gql`
      mutation approveUserSuggestBottle($input: ApproveUserSuggestBottleInput!) {
         approvedBottle: approveUserSuggestBottle(input: $input) {
            suggestBottle {
               id
               userId
               distillery
            }
            genericBottle {
               id
               distillery
               whiskeyType
               series
               yearBottled
               barcodeUPC
               batch
               bottleSize
               countryOfOrigin
               description
               proof
            }
            approval
         }
      }
   `,
   typeKey: 'suggest.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const SUGGEST_UPDATE = {
   mutation: gql`
      mutation updateUserSuggestBottle($input: UpdateUserSuggestBottleInput!) {
         updateUserSuggestBottle(input: $input) {
            id
            userId
            user {
               id
               email
               username
            }
            whiskeyType
            distillery
            series
            yearBottled
            barcodeUPC
            batch
            bottleSize
            countryOfOrigin
            description
            proof
         }
      }
   `,
   typeKey: 'suggest.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const SUGGEST_DELETE = {
   mutation: gql`
      mutation deleteUserSuggestBottle($input: DeleteUserSuggestBottleInput!) {
         deleteUserSuggestBottle(input: $input) {
            id
            userId
            whiskeyType
            distillery
            series
            yearBottled
            barcodeUPC
            batch
            bottleSize
            countryOfOrigin
            description
            proof
         }
      }
   `,
   typeKey: 'suggest.type',
   actionKey: DELETE_ACTION,
};

export const getSuggestRefetchQueries = () => {
   const inlist1 = moment().utc().format(DATE_DB_FORMAT);
   const inlist2 = moment().utc().subtract(1,'d').format(DATE_DB_FORMAT);
   const metricVariables = {
      "listDailyMetricsInput": {
         date: {
            inlist: [inlist1, inlist2]
         }
      }
   };

   return [
      {query: SUGGEST_ALL_QUERY, variables: {}, queryPath: 'bottles'},
      {query: METRICS_QUERY, variables: metricVariables, queryPath: 'metrics'}
   ];
};

// WISH LIST
export const WISH_ALL_QUERY = gql`
   query listUserWishListBottles($filter: TableUserWishListBottleFilterInput) {
      bottles: listUserWishListBottles(includeAll: true, nextToken: "", filter: $filter) {
         nextToken
         items {
            id
            genericBottle {
               id
               barcodeUPC
               batch
               bottleSize
               countryOfOrigin
               description
               distillery
               proof
               series
               whiskeyType
               yearBottled
            }
            user {
               id
               email
               username
            }
         }
      }
   }
`;

export const WISH_BY_ID_QUERY = gql`
   query getUserWishListBottle($id: ID!, $userId: ID!) {
      bottle1: getUserWishListBottle(userWishListBottleId: $id, userId: $userId) {
         id
         userId
         genericBottle {
            barcodeUPC
            bottleSize
            countryOfOrigin
            description
            distillery
            id
            proof
            series
            yearBottled
            whiskeyType
         }
         user {
            id
            email
            username
         }
      }
   }
`;

export const WISH_LIST_RPT_QUERY = gql`
   query adminEmailReport {
      adminEmailReport: adminEmailReport(reportType: "WISHLIST_BOTTLES") {
         success
      }
   }
`;

export const GENERIC_BOTTLE_RPT_QUERY = gql`
   query adminEmailReport {
      adminEmailReport: adminEmailReport(reportType: "GENERIC_BOTTLES") {
         success
      }
   }
`;

export const USER_RPT_QUERY = gql`
   query adminEmailReport {
      adminEmailReport: adminEmailReport(reportType: "USERS") {
         success
      }
   }
`;

// USERS
export const USER_ANALYTICS_QUERY = gql`
   query getUserAnalytics($filter: TableUserAnalyticsFilterInput!) {
      analytics: getUserAnalytics(filter: $filter) {
         userId
         deviceType
         appVersion
         lastAccessed
      }
   }
`;

export const USER_PROFILES_QUERY = gql`
   query listUserProfiles {
      users: listUserProfiles(includeAll: true) {
         items {
            id
            username
            givenName
            familyName
            subscriptionType
            subscriptionStart
            email
            phone
            countryId
            countryName
            city
            state
            zipcode
            profilePictureUrl
         }
      }
   }
`;

export const USERS_SEARCH_QUERY = gql`
   query listUsers($searchText: String) {
      users: listUserProfiles(limit: 500, filter: {allFields: {
         contains: $searchText
      }}) {
         items {
            id
            username
            givenName
            familyName
            subscriptionType
            subscriptionStart
            email
            phone
            countryId
            countryName
            city
            state
            zipcode
            profilePictureUrl
         }
      }
   }
`;

// UserProfile
export const USER_BY_ID_QUERY = gql`
   query getUserById($id: ID!) {
      profile: getUserProfile(id: $id) {
         id
         username
         givenName
         familyName
         subscriptionType
         subscriptionStart
         email
         phone
         createdDate
         countryId
         countryName
         city
         state
         zipcode
         profilePictureUrl
      }
   }
`;

export const USER_UPDATE = {
   mutation: gql`
      mutation updateUserProfile($updateUserProfileInput: UpdateUserProfileInput!) {
         profile: updateUserProfile(input: $updateUserProfileInput) {
            id
            username
            givenName
            familyName
            subscriptionType
            subscriptionStart
            email
            phone
            createdDate
            countryId
            city
            state
            zipcode
         }
      }
   `,
   typeKey: 'user.type',
   actionKey: CREATE_UPDATE_ACTION,
};

export const USER_DELETE = {
   mutation: gql`
      mutation deleteUserProfile($input: DeleteUserProfileInput!) {
         deleteUserProfile(input: $input) {
            id
            username
            subscriptionType
            subscriptionStart
            email
            phone
            createdDate
            countryId
            countryName
            city
            state
            zipcode
         }
      }
   `,
   typeKey: 'user.type',
   actionKey: DELETE_ACTION,
};

export const getUserCacheQueries = () => {
   return [{query: USER_PROFILES_QUERY, variables: {}, queryPath: 'users'}];
};

export const getUserRefetchQueries = () => {
   const inlist1 = moment().utc().format(DATE_DB_FORMAT);
   const inlist2 = moment().utc().subtract(1,'d').format(DATE_DB_FORMAT);
   const metricVariables = {
      "listDailyMetricsInput": {
         date: {
            inlist: [inlist1, inlist2]
         }
      }
   };

   return [
      {query: USER_PROFILES_QUERY, variables: {}, queryPath: 'users'},
      {query: METRICS_QUERY, variables: metricVariables, queryPath: 'metrics'}
   ];
};
