import React, {useCallback, useEffect, useMemo, useState} from 'react';
import assign from 'lodash/assign';
import ConfirmButton from './ConfirmButton';
import DeleteIcon from '@mui/icons-material/Delete';
import EditDrawer from './EditDrawer';
import {makeStyles} from 'tss-react/mui';
import orderBy from 'lodash/orderBy';
import Stack from '@mui/material/Stack';
import SuggestBottleAdd from './SuggestBottleAdd';
import TableFHG from '../fhg/components/table/TableFHG';
import {lighten} from '@mui/material';
import useMutationFHG from '../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import {getSuggestRefetchQueries, SUGGEST_DELETE, SUGGEST_ALL_QUERY} from '../data/QueriesGL';
import {APPBAR_SMALL_HEIGHT, NO_BARCODE, NO_BARCODE_LIST} from '../Constants';

const useStyles = makeStyles({name: 'SuggestBottlesStyles'})((theme) => ({
  buttonDeleteStyle: {
    backgroundColor: "inherit",
    cursor: "pointer",
    margin: theme.spacing(0.25, 0),
    width: "32px"
  },
  deleteColorStyle: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.light, 0.3),
    }
  },
  cellStyle: {
    cursor: 'default',
    fontSize: '0.625rem !important',
  },
  headerStyle: {
    color: theme.palette.primary.contrastText,
    fontSize: '0.625rem !important'
  }
}));

export default function SuggestBottles() {
  const {classes} = useStyles();
  const [bottleId, setBottleId] = useState(undefined);
  const [deleteBottle, setDeleteBottle] = useState(false);
  const [bottleDeleted, setBottleDeleted] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [selectedId, setSelectedId] = useState(undefined);
  const [selectedUserId, setSelectedUserId] = useState(undefined);

  const [suggestData] = useQueryFHG(SUGGEST_ALL_QUERY, {variables: {}}, 'suggest.type');
  const [bottleDelete, {data: deleteData, error: deleteError}] = useMutationFHG(SUGGEST_DELETE);
  const suggestList = useMemo(() => {
    let data = suggestData && suggestData.bottles ? orderBy(suggestData.bottles?.items, [row => row.user.username.toLowerCase()], ['asc']) : [];
    return data.map(b => {
      let barcodeUPC = NO_BARCODE_LIST.includes(b.barcodeUPC) ? NO_BARCODE : b.barcodeUPC;
      return assign({}, b, {barcodeUPC});
    });
  }, [suggestData]);


  useEffect(() => {
    if (deleteData && deleteError === undefined) {
      setBottleDeleted(true);
    }
  }, [deleteData, deleteError, setBottleDeleted]);

  useEffect(() => {
    async function deleteSuggestBottle() {
      const input = {id: bottleId, userId: selectedUserId};
      return await bottleDelete({
        variables: {input},
        refetchQueries: getSuggestRefetchQueries()
      });
    }
    if (deleteBottle && !bottleDeleted) {
      deleteSuggestBottle().then(r => {});
    }
  }, [bottleId, bottleDelete, deleteBottle, bottleDeleted, selectedUserId]);

  const handleFlyoutClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const getPageCount = useCallback((total) => {
    let results = 0;
    if (total && total > 0) {
      results = Math.ceil(total / pageSize);
    }
    return results;
  }, [pageSize]);

  const handleBottleDeleteClick = useCallback((id, userId) => {
    if (id) {
      setDeleteBottle(true);
      setBottleDeleted(false);
      setBottleId(id);
      setSelectedUserId(userId);
    }
  }, [setBottleId, setDeleteBottle, setBottleDeleted]);

  const handleLinkClick = useCallback((value, user) => {
    setSelectedId(value);
    setSelectedUserId(user);
    setOpenDetailDrawer(true);
  }, [setSelectedId, setSelectedUserId, setOpenDetailDrawer]);

  const suggestColumns = useMemo(() => {
    return [
      {
        id: 'id',
        Header: 'Id',
        headerAlign: 'left',
        accessor: 'id',
        show: false,
      },
      {
        id: 'userId',
        Header: 'User Id',
        headerAlign: 'left',
        accessor: 'user.id',
        show: false,
      },
      {
        id: 'user',
        Header: 'User',
        headerAlign: 'left',
        accessor: 'user.username'
      },
      {
        id: 'upc',
        Header: 'UPC',
        headerAlign: 'left',
        accessor: 'barcodeUPC',
      },
      {
        id: 'brand',
        Header: 'Brand',
        headerAlign: 'left',
        accessor: 'distillery',
      },
      {
        id: 'label',
        Header: 'Label',
        headerAlign: 'left',
        accessor: 'series',
      },
      {
        id: 'type',
        Header: "Type",
        headerAlign: 'left',
        accessor: 'whiskeyType',
      },
      {
        id: 'size',
        Header: 'Size',
        headerAlign: 'left',
        accessor: 'bottleSize',
      },
      {
        id: 'proof',
        Header: 'Proof',
        headerAlign: 'left',
        accessor: 'proof',
      },
      {
        id: 'delete',
        Header: 'Delete',
        headerAlign: 'left',
        accessor: '',
        Cell: ({row}) => (
          <ConfirmButton
            id={'suggest.delete.button'}
            buttonLabelKey=""
            confirmButtonLabelKey="delete.button"
            // messageKey={''}
            buttonTypographyProps={{variant:"body1"}}
            className={classes.buttonDeleteStyle}
            style={{cursor: 'pointer'}}
            onConfirm={() => handleBottleDeleteClick(row.values?.id, row.values?.userId)}
            values={{
              type: 'suggest bottle record',
              name: row.values?.upc
            }}
            size='small'
            submitStyle={classes.deleteColorStyle}
            startIcon={<DeleteIcon color="action" style={{width: 132}} />}
          />
        )
      }
    ];
  }, [classes.buttonDeleteStyle, classes.deleteColorStyle, handleBottleDeleteClick]);

  const handlePaginationChange = useCallback((obj) => {
    if (pageSize !== obj.pageSize) {
      setPageSize(obj.pageSize);
      setPageIndex(0);
    } else {
      setPageIndex(obj.pageIndex);
    }
  }, [pageSize, setPageIndex, setPageSize]);

  const handlePageChange = useCallback((pageNumber) => {
    setPageIndex(pageNumber);
  }, [setPageIndex]);

  return (
    <>
      <TableFHG name="whiskey-grid-suggest" columns={suggestColumns} data={suggestList}
                allowSearch
                classesProp={{headerStyle: classes.headerStyle, cellStyle: classes.cellStyle}}
                dataCount={suggestList?.length || 0}
                emptyTableMessageKey={'suggest.noRecord.message'}
                exportExcel
                exportFileName="whiskey-shelf-suggestions"
                onPageChange={handlePageChange}
                onPaginationChange={handlePaginationChange}
                pageCount={getPageCount(suggestList ? suggestList.length : 0)}
                pageSize={pageSize}
                pageIndex={pageIndex}
                subTitleKey={'suggest.subtitle'}
                linkColumn="upc"
                linkColumnId="id"
                linkUserColumnId="userId"
                onLinkClick={handleLinkClick}
      />
      <EditDrawer
        ModalProps={{BackdropProps: {style: {height: '100%', marginTop: APPBAR_SMALL_HEIGHT}}}}
        open={openDetailDrawer}
        onClose={handleFlyoutClose}
      >
        <Stack display={"flex"} direction={'column'} overflow={'visible'}>
          <SuggestBottleAdd id={selectedId} userId={selectedUserId} onClose={handleFlyoutClose}
                            titleKey={'suggest.add.title'}/>
        </Stack>
      </EditDrawer>
    </>
  );
}