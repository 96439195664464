import { clone } from "lodash";
import { toNumber } from "lodash";
import { delay } from "lodash";
import { isString } from "lodash";
import get from "lodash/get";
import isArray from "lodash/isArray";
import isObjectLike from "lodash/isObjectLike";
import pick from "lodash/pick";
import { useState } from "react";
import { useRef } from "react";
import { useCallback } from "react";
import { v4 as uuid } from "uuid";
import { hasValue as hasValueUtil } from "../../utils/Utils";
import { editChange } from "../../utils/Utils";

const REQUIRED_DEFAULTS = ['id'];
const EMPTY_OBJECT = {};

export default function useEditData(defaultValuesProp, requiredEditValues = REQUIRED_DEFAULTS, onChange) {
   const isChangedRef = useRef(false);
   const [defaultValues, setDefaultValuesProp] = useState(defaultValuesProp || {});
   const required = !isArray(requiredEditValues) ? {id: uuid(), ...requiredEditValues} : {id: uuid()};
   const editValuesRef = useRef(required);
   const currentValuesRef = useRef({...(defaultValuesProp || EMPTY_OBJECT), ...required});

   const setDefaultValues = useCallback((defaultValuesProp = EMPTY_OBJECT) => {
      let useDefaultValues;

      if (defaultValuesProp?.id) {
         useDefaultValues = defaultValuesProp;
      } else {
         useDefaultValues = {id: uuid(), ...defaultValuesProp};
      }

      setDefaultValuesProp(useDefaultValues);

      if (useDefaultValues) {
         let requiredObject;

         if (isObjectLike(requiredEditValues)) {
            if (isArray(requiredEditValues)) {
               requiredObject = pick(useDefaultValues, ['id', ...requiredEditValues]);
            } else {
               requiredObject = requiredEditValues;
            }
         } else {
            requiredObject = {};
         }
         editValuesRef.current = {...requiredObject, ...editValuesRef.current};
         currentValuesRef.current = {...useDefaultValues, ...requiredObject, ...editValuesRef.current};
      }
   }, [requiredEditValues]);

   const handleChangeWithName =
      (name, valueKey = 'id') =>
      (event, value, reason) => {
         if (reason === 'selectOption') {
            if (isString(value)) {
               handleChange(event, value, reason, {[name]: value}, name);
            } else {
               handleChange(event, value[valueKey], reason, undefined, name);
            }
         } else {
            handleChange(event, value, reason, undefined, name);
         }
      };

   /**
    * Handle onChange events for the inputs.
    *
    * NOTE:
    * Input components MUST have their name set to be set in the editValues.
    *
    * @param event The event that changed the input.
    * @param value The value if the component is an Autocomplete
    * @param reason The reason of the value change if Autocomplete
    * @param newValue The value from the component.
    * @param name the name of the component.
    */
   const handleChange = (event, value, reason, newValue, name) => {
      let useValue = newValue || editChange(event, value, reason, true, newValue, name);

      editValuesRef.current = {...editValuesRef.current, ...useValue};
      currentValuesRef.current = {...currentValuesRef.current, ...useValue};

      if (onChange) {
         let requiredObject;

         if (isArray(requiredEditValues)) {
            requiredObject = pick(defaultValues, ['id', ...requiredEditValues]);
         } else {
            requiredObject = {...defaultValues};
         }
         //1) Changed now and required, 2) All changed, 3) All changed and defaults for unchanged.
         onChange?.({...requiredObject, ...useValue}, editValuesRef.current, currentValuesRef.current);
      }

      if (reason !== 'reset') {
         isChangedRef.current = true;
      }
      return useValue;
   };

   const handleDateChange = (value, name) => {
      handleChange(undefined, undefined, undefined, {[name]: value});
   };

   const handleArrayChange = (event) => {
      const index = toNumber(get(event, 'target.dataset.index'));
      const {componentName, newValue} = editChange(event, undefined, undefined, false);

      const editValuesLocal = clone(editValuesRef.current);

      if (editValuesLocal[componentName]?.length === undefined) {
         editValuesLocal[componentName] = clone(defaultValues?.[componentName] || []);
      }

      editValuesLocal[componentName][index] = newValue;

      editValuesRef.current = editValuesLocal;
      currentValuesRef.current = {...defaultValues, ...editValuesLocal};
      isChangedRef.current = true;
   };

   const resetValues = useCallback(
      (defaultValuesLocal = {}) => {
         let requiredObject;
         const useDefaultValues = {id: uuid(), ...defaultValuesLocal};

         if (isArray(requiredEditValues)) {
            requiredObject = pick(useDefaultValues, ['id', ...requiredEditValues]);
         } else {
            requiredObject = requiredEditValues;
         }
         editValuesRef.current = {...requiredObject};
         setDefaultValues(useDefaultValues);
         currentValuesRef.current = {...requiredObject, ...useDefaultValues};

         isChangedRef.current = false;
      },
      [requiredEditValues, setDefaultValues]
   );

   const handleSelectChange = (value, name) => {
      editValuesRef.current = {...editValuesRef.current, [name]: value};
      currentValuesRef.current = {...currentValuesRef.current, [name]: value};
      isChangedRef.current = true;
   };

   /**
    * Get the current value for the named property. If the value has been edited, it will return the edited value even
    * if it is null, and it will return the default value if not edited. If there is no default value, the default
    * value from the parameter is used.
    *
    * @Param path The path to the property
    * @Param defaultValue The default value to use if there isn't an edit or default value already.
    *
    * @type {function(*, *=): *}
    */
   const getValue = useCallback(
      (path, defaultValue = '') => {
         const editValue = get(editValuesRef.current, path);
        return editValue !== undefined ? editValue : get(defaultValues, path) || defaultValue;
      },
      [defaultValues]
   );

   /**
    * Get the current value for the named property. If the value has been edited, it will return the edited value even
    * if it is null, and it will return the default value if not edited. If there is no default value, the default
    * value from the parameter is used.
    *
    * @Param path The path to the property
    * @Param defaultValue The default value to use if there isn't an edit or default value already.
    *
    * @type {function(*, *=): *}
    */
   const setValue = useCallback(
      (path, value, isChanged = false) => {
         editValuesRef.current = {...editValuesRef.current, [path]: value};
         currentValuesRef.current = {...currentValuesRef.current, [path]: value};

         isChangedRef.current = isChanged;

         if (onChange && isChanged) {
            let requiredObject;

            if (isArray(requiredEditValues)) {
               requiredObject = pick(defaultValues, ['id', ...requiredEditValues]);
            } else {
               requiredObject = {...defaultValues};
            }
            //1) Changed now and required, 2) All changed, 3) All changed and defaults for unchanged.
            onChange?.({...requiredObject, [path]: value}, editValuesRef.current, currentValuesRef.current);
         }
      },
      [defaultValues, onChange, requiredEditValues]
   );

   const resetValue = useCallback(
      (path) => {
         setValue(path, get(defaultValues, path));
      },
      [defaultValues, setValue]
   );

   /**
    * Indicates if there is a value set for the property. If the default value is deleted, false will be returned even
    * though there is a defaultValue.
    *
    * @Param name The name of the property
    * @type {function(*=, *=): boolean}
    */
   const hasValue = useCallback(
      (name) => {
         return hasValueUtil(getValue(name));
      },
      [getValue]
   );

   const handleInputChange = (name) => (event, value, reason) => {
      if (reason === 'reset') {
         delay(() => {
            setValue(name, undefined);
         });
      } else {
         handleChange(event, value, reason, undefined, name);
      }
   };

   const setIsChanged = (newIsChanged) => {
      isChangedRef.current = newIsChanged;
   };

   const setEditValues = useCallback(
      (editValues) => {
         editValuesRef.current = clone(editValues);
         currentValuesRef.current = {...defaultValues, ...editValues};
      },
      [defaultValues]
   );

   const getEditValues = useCallback(() => {
      return editValuesRef.current;
   }, []);

   return [
      editValuesRef.current,
      handleChange,
      {
         handleSelectChange,
         handleChangeWithName,
         handleDateChange,
         isChanged: isChangedRef.current,
         setIsChanged,
         setEditValues,
         defaultValues,
         setDefaultValues,
         currentValues: currentValuesRef.current,
         resetValues,
         resetValue,
         getValue,
         getEditValues,
         setValue,
         hasValue,
         handleInputChange,
         handleArrayChange,
      },
   ];
}
