import {sum} from 'lodash';
import isArray from 'lodash/isArray';
import isNil from 'lodash/isNil';
import _toNumber from 'lodash/toNumber';
import get from 'lodash/get';
import isObjectLike from 'lodash/isObjectLike';

/**
 * Format the message for localization. The default message has the id appended in non-production versions.
 *
 * @param intl             // Intl for localization.
 * @param id               // Message ID from localization file.
 * @param defaultMessage   // Default message to use if id cannot be found in localization file.
 * @param values           // Values to insert in the localized message.
 * @return {string}        // Localized message.
 */
export function formatMessage(intl, id, defaultMessage, values) {
   const newDefaultMessage = process.env.NODE_ENV === 'production' ? defaultMessage : `${defaultMessage} (${id})`;

   if (id) {
      return intl ? intl.formatMessage({id, defaultMessage: newDefaultMessage}, values) : newDefaultMessage;
   } else {
      return '';
   }
}

export function removeOne(array, index) {
   if (index >= 0 && array && array.length) {
      let len = array.length;
      if (!len) {
         return;
      }
      len -= 1;
      while (index < len) {
         array[index] = array[index + 1];
         index++;
      }
      array.length--;
   }
   return array;
}

/**
 * Determines if the item has a value (i.e. not undefined, not null, nor empty string).
 *
 * @param item The item to check.
 * @return {boolean} True if the item is not undefined, not null, and not the empty string.
 */
export function hasValue(item) {
   return (
      !isNil(item) &&
      item !== '' &&
      (!isArray(item) || item.length > 0) &&
      (!isObjectLike(item) || Object.keys(item).length > 0)
   );
}
export async function fetchData(url = '', method = 'GET', data) {
   const response = await fetch(url, {
      method,
      headers: {
         // 'x-api-key': 'AIzaSyA35411U4UavTPdKVW-HTbY8HWvdny_QSA',
         'Content-Type': 'application/json',
      },
      body: method !== 'GET' && data ? JSON.stringify(data) : undefined,
   });
   return response.json(); // parses JSON response into native JavaScript objects
}

export const editChange = (event, value, reason, isComponent = true, newValue, name) => {
   let nextValue;
   let componentName = name;

   if (newValue === undefined) {
      if (value && (reason === 'blur' || reason === 'create-option' || reason === 'selectOption')) {
         nextValue = typeof value === 'string' ? value : value.id;
         componentName =
            name ||
            get(event, 'target.parentElement.dataset.optionname') ||
            get(event, 'target.firstElementChild.dataset.optionname') ||
            event.target.name;
      } else if (value && reason === 'date-picker') {
         nextValue = value;
         componentName = event.target.name;
      } else {
         if (event && event.target) {
            switch (event.target.type) {
               case 'number':
                  nextValue = isNaN(event.target.valueAsNumber) ? null : event.target.valueAsNumber;
                  break;
               case 'checkbox':
                  nextValue = event.target.checked;
                  break;
               case 'date-range':
                  nextValue = event.target.date;
                  break;
               case 'react-select':
                  nextValue = event.target.value;
                  break;
               case 'react-number-format':
                  nextValue = event.target.valueAsNumber || toNumber(event.target.value);
                  break;

               default:
                  const type = get(event, 'target.dataset.type');
                  if (type === 'number') {
                     nextValue = event.target.valueAsNumber || toNumber(event.target.value);
                     if (isNaN(nextValue)) {
                        nextValue = '';
                     }
                  } else if (type === 'boolean') {
                     nextValue = event.target.value === 'true';
                  } else {
                     nextValue = event.target.value;
                  }
                  break;
            }
            componentName = event.target.name;
         } else {
            console.log('event.target is null');
         }
      }
   }

   if (newValue) {
      return isComponent ? newValue : {componentName, newValue: newValue[name]};
   } else if (isComponent) {
      return {[componentName]: nextValue};
   }
   return {componentName, newValue: nextValue};
};

export function toNumber(value, isAllowBlank = true, blankDefault = null) {
   if (value === null || value === undefined || value === 'null' || (isAllowBlank && value === '')) {
      return blankDefault;
   } else {
      return _toNumber(value);
   }
}

export function resultOf(item, argument, defaultItem) {
   let result = typeof item === 'function' ? item(argument) : item;

   return result || defaultItem;
}

export function numberFromText(text) {
   // numberFromText("AA");
   const charCodes = text
      .split(' ') // => ["A", "A"]
      .map((char) => char.charCodeAt(0)); // => [65, 65]
   return parseInt(sum(charCodes), 10);
}
