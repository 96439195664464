import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import AlertTitle from "@mui/material/AlertTitle";
import ButtonFHG from '../fhg/components/ButtonFHG';
import ConfirmButton from './ConfirmButton';
import {createSearchParams, useNavigate} from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import DownloadIcon from '@mui/icons-material/Download';
import EditDrawer from './EditDrawer';
import {formatMessage} from '../fhg/utils/Utils';
import {IconButton, lighten} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import moment from 'moment/moment';
import orderBy from 'lodash/orderBy';
import PremiumIcon from '@mui/icons-material/WorkspacePremiumOutlined';
import SearchIcon from '@mui/icons-material/Search';
import SearchBottlesIcon from '@mui/icons-material/Liquor';
import Stack from '@mui/material/Stack';
import TableFHG from '../fhg/components/table/TableFHG';
import TextFieldFHG from './TextFieldFHG';
import TypographyFHG from '../fhg/components/Typography';
import UserEdit from './UserEdit';
import {useIntl} from 'react-intl';
import useLazyQueryFHG from "../fhg/hooks/data/useLazyQueryFHG";
import useMutationFHG from "../fhg/hooks/data/useMutationFHG";
import {USERS_SEARCH_QUERY, USER_DELETE, USER_RPT_QUERY, getUserRefetchQueries} from '../data/QueriesGL';
import {APPBAR_SMALL_HEIGHT, SEARCH_PATH, SUB_PREM} from '../Constants';

const useStyles = makeStyles({name: 'UsersSearchStyles'})((theme) => ({
  buttonDeleteStyle: {
    backgroundColor: "inherit",
    cursor: "pointer",
    margin: theme.spacing(0.25, 0),
    width: "32px"
  },
  deleteColorStyle: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    '&:hover': {
      backgroundColor: lighten(theme.palette.error.light, 0.3),
    }
  },
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(2, 2, 1.5, 2),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    fontSize: '0.875rem',
    width: '120px'
  },
  cellStyle: {
    cursor: 'default',
    fontSize: '0.625rem !important',
  },
  elementStyle: {
    margin: theme.spacing(2, 0, 1.25, 2),
    width: '360px'
  },
  headerStyle: {
    color: theme.palette.primary.contrastText,
    fontSize: '0.625rem !important'
  },
  readOnlyFullStyle: {
    border: 'darkgray thin solid',
    borderRadius: '4px',
    fontSize: '0.875rem',
    height: theme.spacing(4.5),
    margin: theme.spacing(-1.5, 0, 1.5, 1),
    padding: theme.spacing(1, 0, 0, 1),
    width: theme.spacing(44)
  },
  tableContainerStyle: {
    border: "1px solid rgba(128,128,128,0.4)",
    borderRadius: 2,
    display: 'block',
    margin: theme.spacing(0.5, 0.75, 0, 0),
    width: theme.spacing(60),
    overflowX: "auto"
  },
  title: {
    fontSize: '2.0rem',
    margin: theme.spacing(0, 2, 1, 2),
    width: theme.spacing(60),
  }
}));

export default function UsersSearch() {
  const {classes} = useStyles();
  const intl = useIntl();
  let navigate = useNavigate();

  const [alertDisplayed, setAlertDisplayed] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const [openDetailDrawer, setOpenDetailDrawer] = useState(false);
  const [pageIndex, setPageIndex] = useState(0);
  const [pageSize, setPageSize] = useState(25);
  const [rows, setRows] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [selectedId, setSelectedId] = useState(undefined);
  const [deleteUser, setDeleteUser] = useState(false);
  const [userDeleted, setUserDeleted] = useState(false);
  const [userToDeleteId, setUserToDeleteId] = useState(undefined);
  const [userDelete, {data: userDeleteData, error: userDeleteError}] = useMutationFHG(USER_DELETE);
  const [getSearchData, {data: searchData}] = useLazyQueryFHG(USERS_SEARCH_QUERY, {variables: {searchText, dts: moment().format("MMDDYYYYhhmmsszzz")}}, 'search.bottles.type', true);
  const [getRptStatus, {data: rptStatus}] = useLazyQueryFHG(USER_RPT_QUERY, {variables: {dts: moment().format("MMDDYYYYhhmmsszzz")}}, 'wishlist.type', true);

  useMemo(() => {
    if (rptStatus && rptStatus.adminEmailReport && !alertDisplayed) {
      setAlertDisplayed(true);
      setOpenAlert(true);
    }
  }, [alertDisplayed, rptStatus, setAlertDisplayed, setOpenAlert]);

  useMemo(() => {
    if (searchData && searchData.users && searchData.users.items) {
      const result = orderBy(searchData.users.items, [row => row.email.toLowerCase()], ['asc']);
      setRows(result);
    }
  },[searchData]);
  useEffect(() => {
    if (userDeleteData && userDeleteError === undefined) {
      setUserDeleted(true);
    }
  }, [userDeleteData, userDeleteError, setUserDeleted]);

  useEffect(() => {
    async function deleteUserProfile() {
      const input = {id: userToDeleteId};
      return await userDelete({
        variables: {input},
        refetchQueries: getUserRefetchQueries()
      });
    }
    if (deleteUser && !userDeleted) {
      deleteUserProfile().then(r => {});
    }
  }, [userToDeleteId, userDelete, deleteUser, userDeleted]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = null;
    if (openAlert) {
      result = <Alert severity="success" onClose={handleAlertClose} style={{width:'98%'}}>
        <AlertTitle>Success</AlertTitle>
        <TypographyFHG id="wishlist.rpt" className={classes.alertStyle} />
      </Alert>;
     }
    return result;
  }, [classes.alertStyle, handleAlertClose, openAlert]);

  const handleUserDeleteClick = useCallback((id) => {
    setDeleteUser(true);
    setUserDeleted(false);
    setUserToDeleteId(id);
  }, [setDeleteUser, setUserDeleted, setUserToDeleteId]);

  const handleSearch = useCallback((row) => {
    navigate({
      pathname: SEARCH_PATH,
      search: createSearchParams({
        id: row.values.id
      }).toString()
    });
  }, [navigate]);

  const handleSearchChange = useCallback((e) => {
    setSearchText(e.target.value);
  }, [setSearchText]);

  const handleSearchClick = useCallback(() => {
    getSearchData({variables: {searchText}});
  }, [getSearchData, searchText]);

  const handleUserReportGen = useCallback(() => {
    setAlertDisplayed(false);
    getRptStatus();
  }, [getRptStatus]);

  const handleFlyoutClose = useCallback(() => {
    setOpenDetailDrawer(false);
  }, [setOpenDetailDrawer]);

  const getPageCount = useCallback((total) => {
    let results = 0;
    if (total && total > 0) {
      results = Math.ceil(total / pageSize);
    }
    return results;
  }, [pageSize]);

  const handleLinkClick = useCallback((value) => {
    setSelectedId(value);
    setOpenDetailDrawer(true);
  }, [setSelectedId, setOpenDetailDrawer]);

  const usersColumns = useMemo(() => {
    return ([
      {
        id: 'id',
        Header: 'Id',
        headerAlign: 'left',
        accessor: 'id',
        show: false
      },
      {
        id: 'username',
        Header: 'User Name',
        headerAlign: 'left',
        accessor: 'username'
      },
      {
        id: 'email',
        Header: 'Email',
        headerAlign: 'left',
        accessor: 'email'
      },
      {
        id: 'search',
        Header: 'Search',
        headerAlign: 'left',
        accessor: '',
        Cell: ({row}) => (
          <IconButton onClick={() => handleSearch(row)} size={'small'} edge={'start'}>
            <SearchBottlesIcon />
          </IconButton>
        )
      },
      {
        id: 'givenName',
        Header: 'First Name',
        headerAlign: 'left',
        accessor: 'givenName'
      },
      {
        id: 'familyName',
        Header: 'Last Name',
        headerAlign: 'left',
        accessor: 'familyName'
      },
      {
        id: 'subscriptionStart',
        Header: 'Subscription Start',
        headerAlign: 'left',
        accessor: 'subscriptionStart',
        Cell: ({value}) => (
          <span>{value ? moment(value).format('L') : ''}</span>
        )
      },
      {
        id: 'country',
        Header: 'Country',
        headerAlign: 'left',
        accessor: 'countryName'
      },
      {
        id: 'city',
        Header: 'City',
        headerAlign: 'left',
        accessor: 'city'
      },
      {
        id: 'state',
        Header: 'State',
        headerAlign: 'left',
        accessor: 'state'
      },
      {
        id: 'subscriptionType',
        Header: 'Premium',
        headerAlign: 'left',
        accessor: 'subscriptionType',
        Cell: ({value}) => (
          <span style={{paddingLeft: '12px'}}>{value && value === SUB_PREM ? <PremiumIcon color="primary" /> : ''}</span>
        )
      },
      {
        id: 'delete',
        Header: 'Delete',
        headerAlign: 'left',
        accessor: '',
        Cell: ({row}) => (
          <ConfirmButton
            id={'user.delete.button'}
            buttonLabelKey=""
            confirmButtonLabelKey="delete.button"
            buttonTypographyProps={{variant:"body1"}}
            className={classes.buttonDeleteStyle}
            style={{cursor: 'pointer'}}
            onConfirm={() => handleUserDeleteClick(row.values?.id)}
            values={{
              type: 'user record and all associated bottles for',
              name: row.values?.email
            }}
            size='small'
            submitStyle={classes.deleteColorStyle}
            startIcon={<DeleteIcon color="action" style={{width: 132}} />}
          />
        )
      }
    ]);
  }, [classes.buttonDeleteStyle, classes.deleteColorStyle, handleSearch, handleUserDeleteClick]);

  const handlePaginationChange = useCallback((obj) => {
    if (pageSize !== obj.pageSize) {
      setPageSize(obj.pageSize);
      setPageIndex(0);
    } else {
      setPageIndex(obj.pageIndex);
    }
  }, [pageSize, setPageIndex, setPageSize]);

  const handlePageChange = useCallback((pageNumber) => {
    setPageIndex(pageNumber);
  }, [setPageIndex]);

  return (
    <Stack display="flex" direction="column" height="100%" >
      {getAlert()}
      <Stack display="flex" direction="row" justifyContent="flex-start" width="100%" >
        <TypographyFHG id="users.search.title" className={classes.title} />
        <Stack display="flex" direction="row" justifyContent="flex-start" width="100%" >
          <TextFieldFHG
            key={'searchText1'}
            name={'searchText'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'users.search.text')}
            onChange={handleSearchChange}
            size={'small'}
            value={searchText}
          />
          <ButtonFHG
            className={classes.buttonStyle}
            labelKey="search.button"
            onClick={handleSearchClick}
            size="small"
            variant="contained"
            startIcon={<SearchIcon />}
          />

           <ButtonFHG
            className={classes.buttonStyle}
            labelKey="wishlist.rpt.button"
            onClick={handleUserReportGen}
            size="small"
            variant="contained"
            startIcon={<DownloadIcon />}
          />
        </Stack>
      </Stack>
      <TableFHG name="whiskey-grid-users" columns={usersColumns} data={rows}
                classesProp={{headerStyle: classes.headerStyle, cellStyle: classes.cellStyle}}
                dataCount={rows?.length || 0}
                emptyTableMessageKey={'users.noRecord.message'}
                exportExcel
                exportFileName="whiskey-shelf-users"
                onPageChange={handlePageChange}
                onPaginationChange={handlePaginationChange}
                pageCount={getPageCount(rows ? rows.length : 0)}
                pageSize={pageSize}
                pageIndex={pageIndex}
                subTitleKey="users.subtitle"
                linkColumn="email"
                linkColumnId="id"
                onLinkClick={handleLinkClick}
      />
      <EditDrawer
        ModalProps={{BackdropProps: {style: {height: '100%', marginTop: APPBAR_SMALL_HEIGHT}}}}
        open={openDetailDrawer}
        onClose={handleFlyoutClose}
      >
        <Stack display={"flex"} direction={'column'} overflow={'visible'}>
          <UserEdit id={selectedId} onClose={handleFlyoutClose} />
        </Stack>
      </EditDrawer>
    </Stack>
  );
};