import {Search} from '@mui/icons-material';
import {Close} from '@mui/icons-material';
import {Box} from '@mui/material';
import {TextField} from '@mui/material';
import {alpha} from '@mui/material';
import {InputAdornment} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import PropTypes from 'prop-types';
import React, {useState} from 'react';
import {useIntl} from 'react-intl';
import {formatMessage} from '../../utils/Utils';

const useStyles = makeStyles({name: 'searchFilterStyles'})((theme) => ({
   searchStyle: {
      position: 'relative',
      borderRadius: theme.shape.borderRadius,
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      '&:hover': {
         backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      maxWidth: 'fit-content',
      [theme.breakpoints.up('sm')]: {
         marginLeft: theme.spacing(0),
         marginRight: 'auto',
         width: 'auto',
      },
   },
   inputRoot: {
      width: '100%',
   },
   '::placeholder': {
      color: '#1796a4 !important',
   },
   textFieldStyle: {
      marginTop: 0,
      marginBottom: 0,
      marginRight: 'auto',
      '& input': {
         transition: theme.transitions.create('width'),
         width: '95%',
         '&:focus': {
            width: '100%',
         },
         // padding: 8,
      },
   },
   inputAdornmentStyle: {
      height: 'unset !important',
   },
   iconStyle: {
      cursor: 'pointer',
   },
}));

/**
 * The search header for a TableFHG.
 *
 * Reviewed: 3/26/20
 *
 * @param globalFilter The current global filter for the table.
 * @param setGlobalFilter The callback when the global filter changes.
 * @param placeholder The placeholder text for the search.
 * @return {*}
 * @constructor
 */
export default function SearchFilter({globalFilter, setGlobalFilter, placeholder}) {
   const { classes } = useStyles();
   const [showClose, setShowClose] = useState(false);
   const intl = useIntl();

   const handleChange = (e) => {
      setGlobalFilter(e.target.value || undefined) // Set undefined to remove the filter entirely
   };

   /**
    * Handle the cancel of the search.
    */
   const handleCancelSearch = () => {
      setGlobalFilter(undefined);
   };

   return (
      <Box className={classes.searchStyle}>
         <TextField
            name={'search'}
            margin={'normal'}
            value={globalFilter || ''}
            variant={'standard'}
            className={classes.textFieldStyle}
            onChange={handleChange}
            placeholder={placeholder || formatMessage(intl, 'search.placeholder', 'Search…')}
            classes={{
               root: classes.inputRoot,
            }}
            InputProps={{
               'aria-label': 'Search',
               style: {paddingRight: 0},
               className: classes.textFieldStyle,
               onFocus: () => setShowClose(true),
               onBlur: () => setShowClose(false),
               endAdornment: (
                  <InputAdornment position='end' className={classes.inputAdornmentStyle}>
                     {showClose ? (
                        <Close className={classes.iconStyle} onMouseDown={handleCancelSearch} />
                     ) : (
                        <Search className={classes.iconStyle} />
                     )}
                  </InputAdornment>
               ),
            }}
         />
      </Box>
   );
}

SearchFilter.propTypes = {
   globalFilter: PropTypes.string,
   setGlobalFilter: PropTypes.func.isRequired,
   placeholder: PropTypes.string,
};
