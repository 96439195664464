import { InMemoryCache } from '@apollo/client';
import { HttpLink } from '@apollo/client';
import { ApolloLink } from '@apollo/client';
import { ApolloClient } from '@apollo/client';
import { ApolloProvider } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { RetryLink } from '@apollo/client/link/retry';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { Auth } from '@aws-amplify/auth';
import QueueLink from 'apollo-link-queue';
import { lazy } from 'react';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { ENDPOINT, ENDPOINT_PROD } from './Constants';
import Loading from './fhg/components/Loading';
import SuspenseLx from './fhg/components/SuspenseLx';
import reportWebVitals from './reportWebVitals';

const RecoilRoot = lazy(() => import('./packageExports/RecoilRoot'));
const BrowserRouter = lazy(() => import('./packageExports/BrowserRouter'));
const ThemeProvider = lazy(() => import('./components/ThemeProvider'));

const getAccessToken = () => {
  return Auth.currentSession()
    .then((session) => {
      return session.idToken.jwtToken;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
};

const authLink = setContext(async (_, {headers}) => {
  let token = await getAccessToken();

  return {
    headers: {
      ...headers,
      Authorization: 'Bearer ' + token || '',
    },
  };
});

const queueLink = new QueueLink();
window.addEventListener('offline', () => queueLink.close());
window.addEventListener('online', () => queueLink.open());

const endpoint = process.env.REACT_APP_POOL === 'production' ? ENDPOINT_PROD : ENDPOINT;
const client = new ApolloClient({
  link: ApolloLink.from([new RetryLink({attempts: {max: 1}}), queueLink, authLink, new HttpLink({uri: endpoint})]),
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          deal_AllWhere: {
            ...offsetLimitPagination(['dealSearch', 'bookFormat']),
            //Cache holds the entire list of Return from cache the .
            read(existing, {args: {offset = 0, limit = existing?.length}}) {
              return existing && existing.slice(offset, offset + limit);
            },
          },
        },
      },
    },
  }),
});

// Add the format command for adding parameters to strings. For Example:
//    'This is a test: {testName}'.format({testName: 'Test Hello World'})
if (!String.prototype.format) {
  // eslint-disable-next-line
  String.prototype.format = function (values) {
    return this.replace(/{(\w+)}/g, function (match, key) {
      return typeof values[key] !== 'undefined' ? values[key] : match;
    });
  };
}

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <SuspenseLx fallback={<Loading isLoading />}>
      <RecoilRoot>
        <ApolloProvider client={client}>
          <BrowserRouter>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </BrowserRouter>
        </ApolloProvider>
      </RecoilRoot>
    </SuspenseLx>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
