import React, {lazy} from 'react';
import {Routes, Route} from 'react-router-dom';
import NoMatch from '../components/NoMatch';
import {
  ADMIN_PATH,
  GENERIC_SEARCH_PATH,
  SIGN_OUT_PATH,
  SEARCH_PATH,
  NOTIFICATION_PATH,
  USER_BOTTLES_SEARCH_PATH,
  USERS_SEARCH_PATH,
  WHISKEY_PATH
} from '../Constants';
import AdminOutlet from '../fhg/components/security/AdminOutlet';
import GenericSearch from '../components/GenericSearch';
import NotificationTemplate from '../components/NotificationTemplate';
import UserBottlesSearch from '../components/UserBottlesSearch';
import UserSearch from '../components/UserSearch';
import UsersSearch from '../components/UsersSearch';
import WhiskeyDashboard from '../components/WhiskeyDashboard';

const ErrorStateSnackbar = lazy(() => import('../fhg/components/ErrorStateSnackbar'));
const Dashboard = lazy(() => import('./admin/Dashboard'));
const ProgressIndicator = lazy(() => import('../fhg/components/ProgressIndicator'));
const SignOut = lazy(() => import('../fhg/pages/SignOut'));

/**
 * Main component accessible only if the user has been authenticated. Contains the routing for the application.
 *
 * Reviewed:
 */
export default function Main() {
   return (
     <>
       <ProgressIndicator isGlobal={true} />
       <ErrorStateSnackbar />
         <Routes>
           <Route path={'/'} element={<Dashboard />}>
             <Route index element={<WhiskeyDashboard />} />
             <Route path={ADMIN_PATH} element={<AdminOutlet />}>
             <Route path={WHISKEY_PATH} element={<WhiskeyDashboard />} /></Route>
             <Route path={SEARCH_PATH} element={<UserSearch />} />
             <Route path={NOTIFICATION_PATH} element={<NotificationTemplate />} />
             <Route path={GENERIC_SEARCH_PATH} element={<GenericSearch />} />
             <Route path={USER_BOTTLES_SEARCH_PATH} element={<UserBottlesSearch />} />
             <Route path={USERS_SEARCH_PATH} element={<UsersSearch />} />
             <Route path={SIGN_OUT_PATH} element={<SignOut />} />
             <Route path='*' element={<NoMatch />} />
           </Route>
         </Routes>
     </>
   );
}
