import React, {useCallback, useEffect, useMemo, useState} from 'react';
import Alert from '@mui/material/Alert';
import assign from 'lodash/assign';
import {Autocomplete, FormControl, InputAdornment, InputLabel, lighten, OutlinedInput} from '@mui/material';
import ButtonFHG from '../fhg/components/ButtonFHG';
import Fade from '@mui/material/Fade';
import find from 'lodash/find';
import Form from '../fhg/components/edit/Form';
import {formatMessage, toNumber} from '../fhg/utils/Utils';
import Loading from '../fhg/components/Loading';
import {makeStyles} from 'tss-react/mui';
import ProgressButton from '../fhg/components/ProgressButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import TextFieldFHG from './TextFieldFHG';
import TypographyFHG from '../fhg/components/Typography';
import {useIntl} from 'react-intl';
import useMutationFHG from '../fhg/hooks/data/useMutationFHG';
import useQueryFHG from '../fhg/hooks/data/useQueryFHG';
import {
  BOTTLE_BY_ID_QUERY,
  BOTTLE_UPDATE,
  DATA_TYPES_QUERY,
  getBottleRefetchQueries,
} from '../data/QueriesGL';
import {DATA_TYPES, FLYOUT_EL_WIDTH} from '../Constants';

const useStyles = makeStyles({name: 'GenericBottleEditStyles'})((theme) => ({
  buttonStyle: {
    backgroundColor: theme.palette.primary.main,
    margin: theme.spacing(2, 1, 1, 1),
    '&:hover': {
      backgroundColor: lighten(theme.palette.primary.main, 0.15),
    },
    width: '100px'
  },
  buttonWrapper: {
    marginLeft: theme.spacing(1),
  },
  formStyle: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'scroll',
    width: '100%',
    height: '100%'
  },
  elementStyle: {
    margin: theme.spacing(0,0, 1.25, 2),
    width: FLYOUT_EL_WIDTH
  },
  labelStyle: {
    backgroundColor: "white",
    color: "gray",
    fontSize: '0.75rem',
    margin: theme.spacing(-0.25,0, 0, 2.5),
    padding: theme.spacing(0, 0.5, 0.325, 0.5),
    width: theme.spacing(10),
    zIndex: "20",
  },
  priceLabelStyle: {
    backgroundColor: "white",
    color: "gray",
    marginLeft: theme.spacing(2),
    width: theme.spacing(5),
  },
  priceStyle: {
    backgroundColor: "white",
    fontSize: '0.75rem',
    height: theme.spacing(4.5),
    margin: theme.spacing(0.75,0, 1.25, 2),
    width: theme.spacing(21)
  },
  adornStyle: {
    fontSize: '0.825rem',
  },
  '& .MuiInputAdornment-root .MuiInputAdornment-outlined': {
    fontSize: '0.75rem',
  },
  readOnlyStyle: {
    border: 'darkgray thin solid',
    borderRadius: '4px',
    fontSize: '0.875rem',
    height: theme.spacing(4.5),
    margin: theme.spacing(-1.5, 0, 0, 1),
    padding: theme.spacing(1, 0, 0, 1),
    width: theme.spacing(20.5)
  },
  readOnlyFullStyle: {
    border: 'darkgray thin solid',
    borderRadius: '4px',
    fontSize: '0.875rem',
    height: theme.spacing(4.5),
    margin: theme.spacing(-1.5, 0, 1.5, 1),
    padding: theme.spacing(1, 0, 0, 1),
    width: theme.spacing(44)
  },
  ratingStyle: {
    fontSize: '0.75rem',
    height: theme.spacing(4.5),
    margin: theme.spacing(0.75,0, 1.25, 2),
    width: theme.spacing(21)
  },
  selectStyle: {
    margin: theme.spacing(0.5, 0, 2, 2),
    width: FLYOUT_EL_WIDTH
  },
  titleStyle: {
    margin: theme.spacing(0,0, 2, 2)
  }
}));

export default function GenericBottleEdit(props) {
  const {classes} = useStyles();
  const intl = useIntl();
  const bottleId = props?.id;
  const [bottleUpdated, setBottleUpdated] = useState(false);
  const [bottleSize, setBottleSize] = useState('');
  const [country, setCountry] = useState('');
  const [whiskeyType, setWhiskeyType] = useState('');
  const [isChanged, setIsChanged] = useState(false);
  const [isDefaultSet, setIsDefaultSet] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [messageText, setMessageText] = useState('');
  const [openAlert, setOpenAlert] = useState(false);
  const [bottleValues, setbottleValues] = useState({});
  const [bottleUpdate, {data: updateData, error: updateError}] = useMutationFHG(BOTTLE_UPDATE);
  const [bottleData] = useQueryFHG(BOTTLE_BY_ID_QUERY, {variables: {id: bottleId}}, 'bottle.type');
  const [bottleSizeData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.BOTTLE_SIZE}}}}, 'data_type.type');
  const [countryData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.COUNTRY_OF_ORIGIN}}}}, 'data_type.type');
  const [whiskeyTypeData] = useQueryFHG(DATA_TYPES_QUERY, {variables: {filter: {category: {"eq": DATA_TYPES.WHISKEY_TYPE}}}}, 'data_type.type');

  useMemo(() => {
    if (!isDefaultSet && bottleData && bottleData.bottle1 && Object.keys(bottleData.bottle1).length > 0) {
      let defaultItem = {
        id: bottleId,
        barcodeUPC: bottleData.bottle1.barcodeUPC,
        bottleSize: bottleData.bottle1.bottleSize,
        countryOfOrigin: bottleData.bottle1?.countryOfOrigin || 'United States',
        description: bottleData.bottle1.description,
        distillery: bottleData.bottle1.distillery,
        proof: bottleData.bottle1.proof,
        series: bottleData.bottle1.series,
        yearBottled: bottleData.bottle1.yearBottled,
        whiskeyType: bottleData.bottle1.whiskeyType,
        calculatedBottleRating: bottleData.bottle1.calculatedBottleRating,
        manualBottleRating: bottleData.bottle1.manualBottleRating,
        calculatedBottlePurchasePrice: bottleData.bottle1.calculatedBottlePurchasePrice,
        manualBottlePurchasePrice: bottleData.bottle1.manualBottlePurchasePrice
      }
      setBottleSize(bottleData.bottle1.bottleSize);
      setCountry(bottleData.bottle1?.countryOfOrigin);
      setWhiskeyType(bottleData.bottle1.whiskeyType);
      setbottleValues(assign({}, defaultItem));
      setIsDefaultSet(true);
    }
  }, [bottleId, bottleData, isDefaultSet, setBottleSize, setbottleValues, setIsDefaultSet]);

  const bottleSizes = useMemo(() => {
    let result = [];
    if (bottleSizeData && bottleSizeData.data && bottleSizeData.data.items) {
      let data = bottleSizeData.data.items;
      data?.forEach(x => {
        if (x.isValid) {
          result.push({label: x.name, value: x.name});
        }
      });
    }
    return result;
  }, [bottleSizeData]);
  const countries = useMemo(() => {
    let result = [];
    if (countryData && countryData.data && countryData.data.items) {
      let data = countryData.data.items;
      data?.forEach(x => {
        if (x.isValid) {
          result.push({label: x.name, value: x.name});
        }
      });
    }
    return result;
  }, [countryData]);
  const whiskeyTypes = useMemo(() => {
    let result = [];
    if (whiskeyTypeData && whiskeyTypeData.data && whiskeyTypeData.data.items) {
      let data = whiskeyTypeData.data.items;
      data?.forEach(x => {
        if (x.isValid) {
          result.push({label: x.name, value: x.name});
        }
      });
    }
    return result;
  }, [whiskeyTypeData]);

  const handleAlertClose = useCallback(() => {
    setOpenAlert(false);
  }, [setOpenAlert]);

  const getAlert = useCallback(() => {
    let result = undefined;
    if (openAlert) {
      result = <Alert severity="error" onClose={handleAlertClose}><TypographyFHG>{messageText}</TypographyFHG></Alert>;
    }
    return result;
  }, [messageText, openAlert, handleAlertClose]);

  const handleClose = useCallback(() => {
    props?.onClose();
  }, [props]);

  useEffect(() => {
    if (updateError && updateError.message) {
      setMessageText(updateError.message);
      setOpenAlert(true);
    }
  }, [updateError]);

  useEffect(() => {
    if (updateData && updateError === undefined) {
      setBottleUpdated(true);
    }
  }, [updateData, updateError, setBottleUpdated]);

  useEffect(() => {
    if (bottleUpdated) {
      handleClose();
    }
  }, [bottleUpdated, handleClose]);

  const getBottleSize = useCallback(() => {
    let result = '';
    if (bottleSizes?.length > 0) {
      result = find(bottleSizes, x => x.value === bottleSize);
    }
    return result;
  }, [bottleSize, bottleSizes]);

  const getCountry = useCallback(() => {
    let result = '';
    if (countries?.length > 0) {
      result = find(countries, x => x.value === country);
    }
    return result;
  }, [country, countries]);

  const getWhiskeyType = useCallback(() => {
    let result = '';
    if (whiskeyTypes?.length > 0) {
      result = find(whiskeyTypes, x => x.value === whiskeyType);
    }
    return result;
  }, [whiskeyType, whiskeyTypes]);

  const handleSubmit = useCallback(async () => {
    try {
      if (!isChanged) {
        return
      }
      setIsSaving(true);
      let currentItem = {
        ...bottleValues
      };

      let updatebottleinput = {
        id: bottleId,
        barcodeUPC: currentItem?.barcodeUPC,
        bottleSize: bottleSize,
        countryOfOrigin: country,
        description: currentItem?.description,
        distillery: currentItem?.distillery,
        proof: currentItem && currentItem.proof ? toNumber(currentItem.proof, true) : null,
        series: currentItem?.series,
        yearBottled: currentItem && currentItem.yearBottled ? toNumber(currentItem.yearBottled, true) : null,
        whiskeyType: whiskeyType,
        manualBottleRating: currentItem && currentItem.manualBottleRating ? toNumber(currentItem.manualBottleRating, true) : null,
        manualBottlePurchasePrice: currentItem && currentItem.manualBottlePurchasePrice ? toNumber(currentItem.manualBottlePurchasePrice, true) : null,
      };

      await bottleUpdate({
        variables: {updatebottleinput},
        refetchQueries: getBottleRefetchQueries()
      });
      setIsChanged(false);
    } catch (e) {
      console.log('Error', e?.message);
    } finally {
      setIsSaving(false);
    }
  }, [bottleId, bottleSize, country, whiskeyType, bottleUpdate, setIsChanged, bottleValues, isChanged, setIsSaving]);

  const handleChange = useCallback((e) => {
    const { target: { name, value } } = e;
    setbottleValues(bottleValues => ({...bottleValues, [name]: value}));
    setIsChanged(true);
  }, [setbottleValues, setIsChanged]);

  const handleBottleSizeChange = useCallback((event, value) => {
    setBottleSize(value?.value);
    setIsChanged(true);
  }, [setBottleSize, setIsChanged]);

  const handleCountryChange = useCallback((event, value) => {
    setCountry(value?.value);
    setIsChanged(true);
  }, [setCountry, setIsChanged]);

  const handleWhiskeyTypeChange = useCallback((event, value) => {
    setWhiskeyType(value?.value);
    setIsChanged(true);
  }, [setWhiskeyType, setIsChanged]);

  const isReady = true;

  return (
    <Fade in={isReady} mountOnEnter>
      <Form onSubmit={handleSubmit}>
        <Loading isLoading={!isReady} />
        <Stack className={classes.formStyle}>
          <TypographyFHG id="bottle.edit.title"  className={classes.titleStyle} variant="h6" />
          {getAlert()}
          <Stack direction="column" display="flex" style={{margin: '0 0 0 8px'}}>
            <TypographyFHG className={classes.labelStyle} id="generic.bottles.id.label" />
            <TypographyFHG className={classes.readOnlyFullStyle}>{bottleValues?.id}</TypographyFHG>
          </Stack>
          <TextFieldFHG
            key={'barcodeUPC'+bottleValues.id}
            name={'barcodeUPC'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.upc.label')}
            onChange={handleChange}
            required
            size={'small'}
            value={bottleValues?.barcodeUPC}
          />
          <TextFieldFHG
            key={'distillery'+bottleValues.id}
            name={'distillery'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.brand.label')}
            onChange={handleChange}
            required
            size={'small'}
            value={bottleValues?.distillery}
          />
          <TextFieldFHG
            key={'series'+bottleValues.id}
            name={'series'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.label.label')}
            onChange={handleChange}
            required
            size={'small'}
            value={bottleValues?.series}
          />
          <Autocomplete
            key={'bottleSize1'+bottleValues.id}
            className={classes.selectStyle}
            fullWidth
            getOptionLabel={(option) => option?.label || ''}
            options={bottleSizes}
            renderOption={(props, option) => {
              const {label} = option;
              return (<span {...props} style={{fontSize: '0.875rem'}}>{label}</span>);
            }}
            onChange={handleBottleSizeChange}
            renderInput={(params) => (
              <TextField{...params}
                        InputProps={{...params.InputProps, style: { fontSize: '0.875rem'}}}
                        label={formatMessage(intl, 'bottle.add.bottleSize.label')}
                        required
                        variant="outlined"/>
            )}
            size="small"
            value={getBottleSize()}
          />
          <Autocomplete
            key={'whiskeyType1'+bottleValues.id}
            className={classes.selectStyle}
            fullWidth
            getOptionLabel={(option) => option?.label || ''}
            options={whiskeyTypes}
            renderOption={(props, option) => {
              const {label} = option;
              return (<span {...props} style={{fontSize: '0.875rem'}}>{label}</span>);
            }}
            onChange={handleWhiskeyTypeChange}
            renderInput={(params) => (
              <TextField{...params}
                        InputProps={{...params.InputProps, style: { fontSize: '0.875rem'}}}
                        label={formatMessage(intl, 'bottle.add.type.label')}
                        required
                        variant="outlined"/>
            )}
            size="small"
            value={getWhiskeyType()}
          />
          <TextFieldFHG
            key={'proof'+bottleValues.id}
            name={'proof'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.proof.label')}
            onChange={handleChange}
            size={'small'}
            type="number"
            required
            value={bottleValues?.proof}
          />
          <Stack display={"flex"} direction="row" justify="flex-start">
            <FormControl className={classes.priceStyle} fullWidth sx={{ m: 1 }} size="small">
              <InputLabel htmlFor="outlined-adornment-amount" className={classes.priceLabelStyle}>Price</InputLabel>
              <OutlinedInput
                key={'manualBottlePurchasePrice'+bottleValues.id}
                name={'manualBottlePurchasePrice'}
                error={bottleValues?.manualBottlePurchasePrice < 0}
                helperText={ bottleValues?.manualBottlePurchasePrice < 0 ? 'Min. 0' : '' }
                InputLabelProps={{shrink: true}}
                inputProps={{style: {fontSize: '0.875rem'}, min: 0}}
                label={formatMessage(intl, 'bottle.add.manualBottlePurchasePrice.label')}
                onChange={handleChange}
                size={'small'}
                startAdornment={<InputAdornment position="start"><span className={classes.adornStyle}>$</span></InputAdornment>}
                value={bottleValues?.manualBottlePurchasePrice}
              />
            </FormControl>
            <Stack direction="column" display="flex" style={{margin: '0 0 0 8px'}}>
              <TypographyFHG className={classes.labelStyle} id="bottle.add.calculatedBottlePurchasePrice.label" />
              <TypographyFHG className={classes.readOnlyStyle}>{bottleValues?.calculatedBottlePurchasePrice}</TypographyFHG>
            </Stack>
          </Stack>
          <Stack display={"flex"} direction="row" justify="flex-start">
            <TextFieldFHG
              key={'manualBottleRating'+bottleValues.id}
              name={'manualBottleRating'}
              className={classes.ratingStyle}
              InputLabelProps={{shrink: true}}
              inputProps={{style: {fontSize: '0.875rem'}, min: 0, max: 100}}
              label={formatMessage(intl, 'bottle.add.manualBottleRating.label')}
              onChange={handleChange}
              size={'small'}
              type="number"
              value={bottleValues?.manualBottleRating}
            />
            <Stack direction="column" display="flex" style={{margin: '0 0 0 8px'}}>
              <TypographyFHG className={classes.labelStyle} id="bottle.add.calculatedBottleRating.label" />
              <TypographyFHG className={classes.readOnlyStyle}>{bottleValues?.calculatedBottleRating}</TypographyFHG>
            </Stack>
          </Stack>
          <Autocomplete
            key={'countryOfOrigin'+bottleValues.id}
            className={classes.selectStyle}
            fullWidth
            defaultValue={country || ''}
            getOptionLabel={(option) => option?.label || ''}
            options={countries}
            onChange={handleCountryChange}
            renderOption={(props, option) => {
              const {label} = option;
              return (<span {...props} style={{fontSize: '0.875rem'}}>{label}</span>);
            }}
            renderInput={(params) => (
              <TextField{...params}
                        InputProps={{...params.InputProps, style: { fontSize: '0.875rem'}}}
                        label={formatMessage(intl, 'bottle.add.country.label')}
                        required
                        variant="outlined"/>
            )}
            size="small"
            value={getCountry()}
          />
          <TextFieldFHG
            key={'yearBottled'+bottleValues.id}
            name={'yearBottled'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            label={formatMessage(intl, 'bottle.add.year.label')}
            onChange={handleChange}
            size={'small'}
            type="number"
            value={bottleValues?.yearBottled}
          />
          <TextFieldFHG
            key={'description'+bottleValues.id}
            name={'description'}
            className={classes.elementStyle}
            InputLabelProps={{shrink: true}}
            inputProps={{style: {fontSize: '0.875rem'}}}
            columns={3}
            label={formatMessage(intl, 'bottle.add.desc.label')}
            onChange={handleChange}
            multiline
            rows="2"
            size={'small'}
            value={bottleValues?.description}
          />
          <Stack className={classes.buttonWrapper} direction="row" display={'flex'} alignItems={'flex-start'}>
            <ProgressButton
              className={classes.buttonStyle}
              isProgress={isSaving}
              disabled={isSaving}
              labelKey='save.button'
              type={'submit'}
              variant="contained"
            />
            <ButtonFHG
              className={classes.buttonStyle}
              disabled={isSaving}
              labelKey="cancel.button"
              onClick={handleClose}
              variant="contained"
            />
          </Stack>
        </Stack>
      </Form>
    </Fade>
  );
}
