import React from 'react';
import PropTypes from 'prop-types';
import FileDownloadIcon from '@mui/icons-material/FileDownloadOutlined';
import {IconButton} from '@mui/material';
import {lighten} from '@mui/material';
import {makeStyles} from 'tss-react/mui';
import SearchFilter from './SearchFilter';
import Stack from '@mui/material/Stack';
import {Toolbar} from '@mui/material';
import TypographyFHG from '../Typography';
import Divider from "@mui/material/Divider";

const useStyles = makeStyles({name: 'TableSearchToolbarStyles'})((theme) => ({
   root: {
      paddingLeft: theme.spacing(1),
      // paddingTop: theme.spacing(1),
      paddingRight: theme.spacing(1),
      cursor: 'default',
      maxHeight: 56,
   },
   highlight:
      theme.palette.type === 'light'
         ? {
              color: theme.palette.secondary.main,
              backgroundColor: lighten(theme.palette.secondary.light, 0.85),
           }
         : {
              color: theme.palette.text.primary,
              backgroundColor: theme.palette.secondary.dark,
           },
   title: {
      flex: '1 1 100%',
   },
   subtitle: {
      fontWeight: 'bold',
      margin: theme.spacing(1, 4, 0, 0)
   },
   buttonStyle: {
      filter: 'invert(43%) sepia(12%) saturate(0%) hue-rotate(223deg) brightness(95%) contrast(103%)',
   },
   downloadButtonStyle: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
   }
}));

/**
 * Component to show the search (filter) for the table.
 *
 * Reviewed: 4/14/20
 *
 * @param (optional)numSelected The number of items selected.
 * @param (optional) titleKey The message key for the table.
 * @param setGlobalFilter The global filter callback to change the filtered rows in the table.
 * @param globalFilter The current global filter.
 * @param (optional)children The children to be placed after the search.
 * @return {*}
 * @constructor
 */
export default function TableSearchToolbar({
   numSelected = 0,
   allowSearch,
   titleKey,
   title,
   setGlobalFilter,
   globalFilter,
   exportCSV,
   exportPDF,
   exportExcel,
   exportData,
   children,
   subtitle
}) {
   const {classes} = useStyles();

   return (
      <Toolbar className={`${classes.root} ${numSelected > 0 ? classes.highlight : undefined}`}>
         {(titleKey || title) && (
            <TypographyFHG className={classes.title} variant='h5' id={titleKey}>
               {title}
            </TypographyFHG>
         )}
         <Stack display={"flex"} direction="row" flexWrap={'wrap'} justify="flex-start">
            <TypographyFHG variant={'subtitle1'} color={'textSecondary'} className={classes.subtitle}>{subtitle}</TypographyFHG>
            {children}
            {allowSearch && (<SearchFilter globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />)}
            {exportCSV && (
              <IconButton
                onClick={() => {
                   exportData('csv', false);
                }}
              >
                 <img
                   alt={'Export to CSV'}
                   className={classes.buttonStyle}
                   height={24}
                   width={24}
                   src={'/images/export-csv.png'}
                 />
              </IconButton>
            )}
            {exportPDF && (
              <IconButton
                onClick={() => {
                   exportData('pdf', false);
                }}
              >
                 <img
                   alt={'Export to PDF'}
                   className={classes.buttonStyle}
                   height={24}
                   width={24}
                   src={'/images/export-pdf.png'}
                 />
              </IconButton>
            )}
            {exportExcel && (
              <Stack display={"flex"} direction="row" justify="flex-start">
                 <Divider orientation="vertical" />
                 <IconButton className={classes.downloadButtonStyle}
                             onClick={() => {
                                exportData('xlsx', false);
                             }}
                 >
                    <FileDownloadIcon />
                 </IconButton>
              </Stack>
            )}
         </Stack>
      </Toolbar>
   );
}

TableSearchToolbar.propTypes = {
   setGlobalFilter: PropTypes.func.isRequired,
   globalFilter: PropTypes.string,
   titleKey: PropTypes.string,
   numSelected: PropTypes.number,
};
