import {Box} from '@mui/system';
import React from 'react';
import {Link} from 'react-router-dom';

/**
 * When there is no route that matches display this message and give the link to return to the home page.
 * @return {JSX.Element}
 * @constructor
 */
export default function NoMatch() {
   return (
      <Box sx={{m: 1}}>
         <h2>Nothing to see here!</h2>
         <p>
            <Link to='/'>Go to the home page</Link>
         </p>
      </Box>
   );
}
