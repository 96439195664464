import {ADMIN_GROUP} from '../../../Constants';
import PrivilegeOutlet from './PrivilegeOutlet';

/**
 * An outlet component that only displays the outlet if the user has the ADMIN_GROUP.
 * @return {JSX.Element|null} Outlet if the user is in Admin group, message if logged in and null if not logged in.
 * @constructor
 */
export default function AdminOutlet() {
   return <PrivilegeOutlet requiredGroups={ADMIN_GROUP} />;
}
